import { defaultCurrency } from "src/constants/currency";

export function fetchSymbolFromCurrency(_currency?: string) {
  const currency = _currency || defaultCurrency;
  try {
    const symbol = Intl.NumberFormat("en", {
      style: "currency",
      currency: currency,
    })
      .formatToParts(1)
      .find((part) => (part.type = "currency"))?.value;
    return symbol || "$";
  } catch (error) {
    return "$";
  }
}
