import React, { useState, useEffect } from 'react'
import { Wrapper } from './PurchasesCacChart.styles'
import Chart from 'react-apexcharts';
import { PurchasesCacChartProps } from './types';

const DUMMY_PURCHASE_DATA = ["10", "10", "10", "10"]
const DUMMY_CAC_DATA = ["15", "15", "15", "15"]
const DUMMY_DATES = ["2023-02-18T07:19:27.628Z", "2023-02-19T07:19:27.628Z", "2023-02-20T07:19:27.628Z", "2023-02-21T07:19:27.628Z"]

const PurchasesCacChart = ({ chartData, loading }: PurchasesCacChartProps) => {
    const [campaignChartData, setCampaignChartData] = useState<any>("");
    const [campaignChartOptions, setCampaignChartOptions] = useState<any>("");

    useEffect(() => {
        let cac: any[] = [];
        let results: any[] = [];
        const dateStart: any[] = [];


        chartData?.map((item: any) => {
            dateStart.push(new Date(item?.date_start).toISOString());
            results.push(parseInt(item?.purchases));
            cac.push(parseFloat(item?.cost_per_purchase));
        });
        cac = cac.reverse();
        results = results.reverse();

        setCampaignChartData([
            {
                name: '2021',
                data: chartData ? results : DUMMY_PURCHASE_DATA,
            },
            {
                name: '2022',
                data: chartData ? cac : DUMMY_CAC_DATA,
            },
        ]);



        setCampaignChartOptions({
            chart: {
                type: 'area',
                fontFamily: 'Montserrat, sans-serif',
                toolbar: {
                    show: false,
                },
            },
            grid: { show: false },
            markers: {
                size: 4,
                colors: undefined,
                strokeColors: '#fff',
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 1,
                discrete: [],
                shape: 'circle',
                radius: 2,
                offsetX: 0,
                offsetY: 0,
                onClick: undefined,
                onDblClick: undefined,
                showNullDataPoints: true,
                hover: {
                    size: 4,
                    sizeOffset: 3,
                },
            },
            colors: ['#150DD1', '#ef8f72'],
            dataLabels: {
                enabled: false,
                style: {
                    opacity: 0,
                    fontSize: 0,
                    color: '#fff',
                },
                background: {
                    borderRadius: 100,
                },
            },
            stroke: {
                show: true,
                curve: 'smooth',
                lineCap: 'butt',
                colors: undefined,
                width: 2,
                dashArray: 0,
            },
            xaxis: {
                type: 'datetime',
                categories: chartData ? dateStart.sort((a, b) => +new Date(b) - +new Date(a)) : DUMMY_DATES,
                labels: {
                    style: {
                        colors: '#FFFFFF99',
                    },
                },
            },
            yaxis: [
                {
                    title: {
                        text: 'ROAS',
                        style: {
                            color: '#FFF',
                        },
                    },
                    labels: {
                        formatter: (val: any) => parseFloat(val).toFixed(1),
                        style: {
                            colors: '#FFFFFF99',
                        },
                    },
                },
                // {
                //     opposite: true,
                //     title: {
                //         text: 'CAC',
                //         style: {
                //             color: '#FFF',
                //         },
                //     },
                //     labels: {
                //         formatter: (val: any) => (val >= 1000 ? `${val / 1000}K` : val),
                //         style: {
                //             colors: '#FFFFFF99',
                //         },
                //     },
                // },
            ],
            legend: {
                labels: {
                    colors: '#fff',
                },
            },
            tooltip: {
                x: {
                    format: 'dd/MM/yy',
                },
                y: {
                    formatter: (val: any) => parseFloat(val).toFixed(1),
                },
            },
        });
    }, [chartData])

    if (!campaignChartData || !campaignChartOptions) {
        return null;
    }
    return (
        <Wrapper>
            <Chart
                options={campaignChartOptions}
                series={campaignChartData}
                type="area"
                // width={740}
                height={window.innerWidth <= 900 ? 250 : 400}
            />
        </Wrapper>
    )
};

export default PurchasesCacChart;