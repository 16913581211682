import React, { useState } from "react";
import {
  FbAdPageContainer,
  SelectorFormController,
  SelectorOption,
} from "./FBAdPage.styles";
import { InputLabel, Select } from "@mui/material";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import {
  fetchUserProfile,
  saveFbDetails,
  setActiveAdAccount,
  setIsAdAccountChanging,
} from "src/slices/user/userSlice";
import {
  clearPrevAfter,
  setComparison,
} from "src/slices/allcampaignInsight/allCamapignInsightSlice";
import { useSnackbar } from "notistack";

type Props = {
  setShowChangeAccountDetailsModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const FBAdPage = ({ setShowChangeAccountDetailsModal }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const allAdAccount = useAppSelector((state) => state.user.userAdAccounts);
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();

  const [selectedAdAccount, setSelectedAdAccount] = useState(
    user.currentAdAccount.ad_account_id ? user.currentAdAccount : ""
  );
  const handleChange = (e: any) => {
    const selected = allAdAccount.filter(
      (ad) => ad.ad_account_id === e.target.value
    );
    setSelectedAdAccount({ ...selected[0] });
  };

  const handleSaveChangeAccountDetails = () => {
    if (
      selectedAdAccount?.ad_account_name === "" ||
      selectedAdAccount?.ad_account_name === "-" ||
      selectedAdAccount?.ad_account_name === null ||
      !selectedAdAccount?.ad_account_name
    ) {
      enqueueSnackbar("Ad Account Name is required", {
        variant: "error",
      });
      return;
    } else if (
      selectedAdAccount?.ad_account_id === "" ||
      selectedAdAccount?.ad_account_id === "-" ||
      selectedAdAccount?.ad_account_id === null ||
      !selectedAdAccount?.ad_account_id
    ) {
      enqueueSnackbar("Ad Account Id is required", {
        variant: "error",
      });
      return;
    } else {
      dispatch(setIsAdAccountChanging(true));
      dispatch(clearPrevAfter());
      dispatch(setComparison(false));
      dispatch(
        saveFbDetails({
          account_status: selectedAdAccount.account_status,
          ad_account_id: selectedAdAccount?.ad_account_id,
          ad_account_name: selectedAdAccount?.ad_account_name,
          app_fb_user_id: user?.user.data?.app_fb_user_id,
          currency: selectedAdAccount.currency,
          currency_offset: selectedAdAccount.currency_offset,
          disable_reason: selectedAdAccount.disable_reason,
          disable_reason_display_string:
            selectedAdAccount.disable_reason_display_string,
          euid: String(user?.user.data?.euid),
          min_daily_budget: selectedAdAccount.min_daily_budget,
          timezone_id: selectedAdAccount.timezone_id,
          timezone_offset_hours_utc:
            selectedAdAccount.timezone_offset_hours_utc,
          timezone_name: selectedAdAccount.timezone_name,
        })
      )
        .unwrap()
        .then((res) => {
          dispatch(setActiveAdAccount(selectedAdAccount));
          dispatch(fetchUserProfile({ email: user?.user.data?.email }));
          dispatch(setIsAdAccountChanging(false));
          dispatch(clearPrevAfter());
          setShowChangeAccountDetailsModal(false);
          enqueueSnackbar("Account Details successfully updated!", {
            variant: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          enqueueSnackbar("Something went wrong", {
            variant: "error",
          });
        });
    }
  };

  return (
    <FbAdPageContainer>
      <SelectorFormController fullWidth>
        <InputLabel id="demo-simple-select-label">Your ad account</InputLabel>
        <Select
          //   labelStyle={{ color: "#ff0000" }}
          sx={{
            color: "white",
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(15, 110, 255, 1)",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(15, 110, 255, 1)",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(15, 110, 255, 1)",
            },
            ".MuiSvgIcon-root ": {
              fill: "white !important",
            },
          }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedAdAccount.ad_account_id}
          label="Your ad account"
          onChange={handleChange}
        >
          {allAdAccount.length > 0 &&
            allAdAccount.map((adAccount) => {
              return (
                <SelectorOption value={adAccount.ad_account_id}>
                  <div>
                    <span>{adAccount.ad_account_name.slice(0, 1)}</span>
                    <div>
                      <h5>{adAccount.ad_account_name}</h5>
                      <p>Ad account ID: {adAccount.ad_account_id}</p>
                    </div>
                  </div>
                </SelectorOption>
              );
            })}
        </Select>
      </SelectorFormController>

      {/* <FbPageContainer>
        <FormControl>
          <FbPageContainerHeading id="demo-radio-buttons-group-label">
            Select the page in which you want to run ads
          </FbPageContainerHeading>
          <RadioContainer>
            <Radio
              //   checked={selectedValue === "a"}
              onChange={handleChange}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <img src="" alt="" />
            <p>happy</p>
          </RadioContainer>
        </FormControl>
      </FbPageContainer> */}

      <button type="submit" onClick={handleSaveChangeAccountDetails}>
        Done
      </button>
    </FbAdPageContainer>
  );
};

export default FBAdPage;
