import { TableCell, TableHead } from "@mui/material";
import styled from "styled-components";

export const Wrapper = styled.div`
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
  padding: 2rem;
  color: #fff;
  width: 100%;
`;

export const Title = styled.div`
  border-bottom: 1px solid #efefef4d;
  padding-bottom: 1rem;
  margin-bottom: 2rem;

  h3 {
    display: flex;
    align-items: flex-end;
    p {
      margin-left: 0.5rem;
      color: #ffffff99;
      font-size: 0.9rem;
      font-weight: 500;
    }
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const CustomTableHead = styled(TableHead)``;

type CustomTableCellProps = {
  headCell?: boolean;
  firstCell?: boolean;
};

export const CustomTableCell = styled(TableCell)<CustomTableCellProps>`
  color: #fff !important;

  padding: ${(props) => (!props.headCell ? "1rem !important" : "0 !important")};
  text-align: ${(props) =>
    props.firstCell ? "left !important" : "center !important"};
  width: max-content !important;
  border: none !important;

  span {
    color: #ffffff99;
  }
`;

export const CustomTableBody = styled.div`
  margin-top: 1rem;
`;

type TableHeadLabelProps = {
  headLeftCornerCell?: boolean;
  headRightCornerCell?: boolean;
  headCell?: boolean;
};

export const TableHeadLabel = styled.div<TableHeadLabelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin: auto;
  background-color: rgba(27, 125, 255, 0.1);
  width: 100%;
  padding: 1rem;
  height: 3rem;
  /* margin-bottom: 1rem; */
  color: #ffffff99;
  text-align: center;
  border-radius: ${(props) => props.headLeftCornerCell && " 0.8rem 0 0 0.8rem"};
  border-radius: ${(props) => props.headRightCornerCell && "0 0.8rem 0.8rem 0"};
  cursor: ${(props) => props.headCell && "pointer"};

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;
