import { ChangeEvent, useState } from "react";
import { Container, Stack } from "@mui/material";

import { SearchInput } from "../../components/Input";
import { PageHeader, Background } from "../../components/Layout";
import InvoicesDataTable from "src/components/Table/Invoice/DataTable";
import { useGetBankTransferDetailsQuery } from "src/api/invoice";
import { useAppSelector } from "src/hooks/useAppSelector";

export default function Issue() {
  const user = useAppSelector((state) => state.user.user);

  const [query, setQuery] = useState("");

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  const { data, isLoading } = useGetBankTransferDetailsQuery({
    ad_account: user?.data?.fb_ad_account,
    euid: user?.data?.euid,
  });

  return (
    <Container>
      <Background elevation={0}>
        <Stack
          flexWrap="wrap"
          gap={2}
          direction="row"
          alignItems="center"
          mb={4}
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center" gap={3}>
            <PageHeader
              title="All Transaction"
              subtitle="Download Previous invoices"
            />
          </Stack>
          <Stack direction="row" gap={2} alignItems="center" flexWrap="wrap">
            <Stack direction="row" alignItems="center">
              <SearchInput
                size="medium"
                value={query}
                onChange={handleQueryChange}
                maxWidth={300}
                placeholder="Enter business name"
              />
            </Stack>
          </Stack>
        </Stack>
        <InvoicesDataTable {...{ data: data, isLoading: isLoading, query }} />
      </Background>
    </Container>
  );
}
