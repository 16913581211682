import { Skeleton, Button } from "@mui/material";
import React, { useEffect, useMemo, useRef } from "react";
import AdCard from "src/components/adCard/AdCard";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  clearLiveAdCopies,
  getAllAdCopies,
} from "src/slices/liveAdCopies/liveAdCopiesSlice";
import {
  AdsFlex,
  NoAdCopiesFound,
  TitleFlex,
  Track,
  Wrapper,
} from "./LiveAdCopies.styles";

const LiveAdCopies = () => {
  const user = useAppSelector((state) => state.user.user);
  const currentAdAccount = useAppSelector(
    (state) => state.user.currentAdAccount
  );
  const { data: adCopies, loading } = useAppSelector(
    (state) => state.liveAdCopies
  );
  const dispatch = useAppDispatch();
  // const trackRef = useRef<any>(null)

  useEffect(() => {
    console.log(adCopies, "LIVE AD COPIES");
  }, [adCopies]);

  // useEffect(() => {
  //     console.log(trackRef)
  //     if (trackRef?.current) {

  //         trackRef.current.addEventListener("wheel", (e: WheelEvent) => {
  //             e.preventDefault();
  //             if (e.deltaY > 0) trackRef.current.scrollLeft += 50;
  //             else trackRef.current.scrollLeft -= 50;
  //         })
  //     }

  // }, [trackRef, loading])

  useEffect(() => {
    if (user?.data?.euid && user?.data?.fb_ad_account) {
      dispatch(clearLiveAdCopies());
      dispatch(
        getAllAdCopies({
          adAccountId: user?.data?.fb_ad_account,
          after: "",
          before: "",
          euid: user?.data?.euid,
        })
      );
    }
  }, [user?.data?.euid, user?.data?.fb_ad_account]);

  const get_more_ads = () => {
    dispatch(
      getAllAdCopies({
        adAccountId: user?.data?.fb_ad_account,
        after: adCopies?.paging?.after,
        before: "",
        euid: user?.data?.euid,
      })
    );
  };

  return (
    <Wrapper
      initial={{ y: 60, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -60, opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.5, delay: 0.1 }}
    >
      <TitleFlex>
        {/* <h3>Running ads {!loading && adCopies?.ads?.length ? `(${adCopies?.ads?.length})` : "(0)"}</h3> */}
        <h2>Running ads</h2>
      </TitleFlex>

      <AdsFlex>
        {loading && (
          <Track>
            <Skeleton variant="rounded" width="22rem" height="38rem" />
            <Skeleton variant="rounded" width="22rem" height="38rem" />
            <Skeleton variant="rounded" width="22rem" height="38rem" />
            <Skeleton variant="rounded" width="22rem" height="38rem" />
            <Skeleton variant="rounded" width="22rem" height="38rem" />
          </Track>
        )}

        {!loading && !adCopies && !adCopies.ads && (
          <NoAdCopiesFound>No Ad copies found.</NoAdCopiesFound>
        )}

        {!loading && (
          <Track>
            {adCopies.ads?.map((item: any, i: number) => {
              return (
                <AdCard
                  key={i}
                  ad_image_url={item?.ad_image_url}
                  caption={item?.caption}
                  call_action_type={item?.call_action_type}
                  page_name={item?.page_name}
                  call_action_link={item?.call_action_link}
                  page_image={item?.page_img}
                  call_action_message={item?.call_action_message}
                  ad_account_name={currentAdAccount?.ad_account_name}
                  ad_id={item?.ad_id}
                />
              );
            })}

            {adCopies?.paging?.after && (
              <Button onClick={() => get_more_ads()}>Load more</Button>
            )}
          </Track>
        )}
      </AdsFlex>
    </Wrapper>
  );
};

export default LiveAdCopies;
