import { FormControl, FormLabel, MenuItem } from "@mui/material";
import styled from "styled-components";

export const FbAdPageContainer = styled.div`
  > button {
    height: 55px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: linear-gradient(
      to left,
      rgba(0, 82, 241, 1),
      rgba(0, 117, 255, 1)
    );
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: white;
    outline: none;
    border: none;
    margin-top: 2rem;
    cursor: pointer;
  }
`;

export const SelectorFormController = styled(FormControl)`
  margin-top: 2rem !important;
`;

export const SelectorOption = styled(MenuItem)`
  > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    > span {
      height: 38px;
      width: 38px;
      border-radius: 13px;
      background-color: rgba(5, 168, 107, 1);
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > div {
      > h5 {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(255, 255, 255, 1);
      }
      > p {
        font-size: 11px;
        font-weight: 400;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
`;

export const FbPageContainer = styled.div`
  border: 1px solid rgba(15, 110, 255, 1);
  background: linear-gradient(
    to right,
    rgba(0, 37, 143, 1),
    rgba(0, 25, 99, 1)
  );
  height: 179px;
  width: 100%;
  border-radius: 12px;
  margin-top: 1.5rem;
  padding: 1.5rem 2rem;
  overflow-y: auto;
`;

export const FbPageContainerHeading = styled(FormLabel)`
  margin: 0.5rem 0;
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  > img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
  }
`;
