import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import UNIVERSAL from "../../config/config";

type InitialState = {
  user: any;
  activeUser: any;
  userAdAccounts: any[];
  fb: any;
  loading: boolean;
  fbadaccountLoading: boolean;
  error: any;
  currentAdAccount: any;
  forgotPassword: {
    code: string;
    loading: boolean;
  };
  isAdAccountChanging: boolean;
};

type SaveFbDetailData = {
  account_status: number;
  ad_account_id: string;
  ad_account_name: string;
  app_fb_user_id: string;
  currency: string;
  currency_offset: number;
  disable_reason: number;
  disable_reason_display_string: string;
  euid: string;
  min_daily_budget: number;
  timezone_id: number;
  timezone_offset_hours_utc: number;
  timezone_name: string;
};

type SaveUserAccount = {
  fbAccessToken: string;
  euid: string;
  pageId: string;
  fbId: string;
  pageToken: string;
  page_name: string;
  page_profile_picture_url: string;
  user_name: string;
  user_profile_picture_url: string;
};

type ReAuthenticate = {
  fbAccessToken: string;
  euid: string;
  fbId: string;
};

type fetchAdAccountsProps = {
  appFbUserId: string;
  euid: string;
};

const initialState: InitialState = {
  user: "",
  activeUser: "",
  userAdAccounts: [],
  fb: "",
  loading: true,
  fbadaccountLoading: true,
  error: "",
  currentAdAccount: "",
  forgotPassword: {
    code: "",
    loading: false,
  },
  isAdAccountChanging: false,
};

type changePasswordPayload = {
  euid: string;
  newPassword: string;
  currentPassword: string;
};

type changeNamePayload = {
  euid: number;
  newName: string;
};

export const changeName = createAsyncThunk(
  "/user/changeName",
  (data: changeNamePayload, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/user/change_name`, {
      method: "POST",
      body: JSON.stringify({
        euid: data.euid,
        new_name: data.newName,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        return res.data;
      });
  }
);

export const changePassword = createAsyncThunk(
  "/user/changePassword",
  (data: changePasswordPayload, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/auth/change_password`, {
      method: "POST",
      body: JSON.stringify({
        current_password: data.currentPassword,
        euid: data.euid,
        new_password: data.newPassword,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        return res.data;
      });
  }
);

export const getForgetPasswordCode = createAsyncThunk(
  "/user/fetchForgotPasswordCode",
  (email: string, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/auth/forgot_password`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        return res.data;
      });
  }
);

export const validateResetCode = createAsyncThunk(
  "/user/validateResetCode",
  (code: string, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/auth/validate_reset_code`, {
      method: "POST",
      body: JSON.stringify({
        code: code,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        return res.data;
      });
  }
);

type ResetPasswordProps = {
  code: string;
  password: string;
};

export const resetPassword = createAsyncThunk(
  "/user/resetPassword",
  (data: ResetPasswordProps, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/auth/reset_password`, {
      method: "POST",
      body: JSON.stringify({
        code: data.code,
        password: data.password,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        return res.data;
      });
  }
);

export const fetchUserAdAccounts = createAsyncThunk(
  "/user/fetchUserAdAccounts",
  (data: fetchAdAccountsProps) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/facebook/fetch_ad_accounts`, {
      method: "POST",
      body: JSON.stringify({
        app_fb_user_id: data.appFbUserId,
        euid: data.euid,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        return res.data;
      });
  }
);

type fetchUserProfileData = {
  email: string;
  // euid: string
};

export const fetchUserProfile = createAsyncThunk(
  "/user/fetchUserProfile",
  (data: fetchUserProfileData) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v2/user/profile`, {
      method: "POST",
      body: JSON.stringify({ email: data.email }),
    })
      .then((response) => response.json())
      .then((res) => {
        localStorage.setItem("ENTERPRISE_DASHBOARD_EMAIL", data.email);
        return res;
      });
  }
);

export const fetchActiveUser = createAsyncThunk(
  "/user/fetchActiveUser",
  (euid: string) => {
    return fetch(
      `${UNIVERSAL.BASEURL}/api/v1/facebook/get_active_user/${euid}`,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((res) => {
        return res.data;
      });
  }
);

export const saveUserAccount = createAsyncThunk(
  "/user/saveUserAccount",
  (data: SaveUserAccount, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/facebook/save_user_account`, {
      method: "POST",
      body: JSON.stringify({
        access_token: data.fbAccessToken,
        euid: String(data.euid),
        page_id: data.pageId,
        page_token: data.pageToken,
        user_id: data.fbId,
        page_name: data.page_name,
        page_profile_picture_url: data.page_profile_picture_url,
        user_name: data.user_name,
        user_profile_picture_url: data.user_profile_picture_url,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res.data;
      });
  }
);

export const reAuthenticate = createAsyncThunk(
  "/user/reAuthenticate",
  (data: ReAuthenticate, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/facebook/reauthenticate`, {
      method: "POST",
      body: JSON.stringify({
        access_token: data.fbAccessToken,
        euid: String(data.euid),
        user_id: data.fbId,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res.data;
      });
  }
);

export const saveFbDetails = createAsyncThunk(
  "/user/saveFbDetails",
  (data: SaveFbDetailData, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v2/facebook/save_ad_account`, {
      method: "POST",
      body: JSON.stringify({
        account_status: data.account_status,
        ad_account_id: data.ad_account_id,
        ad_account_name: data.ad_account_name,
        app_fb_user_id: data.app_fb_user_id,
        currency: data.currency,
        currency_offset: data.currency_offset,
        disable_reason: data.disable_reason,
        disable_reason_display_string: data.disable_reason_display_string,
        euid: data.euid,
        min_daily_budget: data.min_daily_budget,
        timezone_id: data.timezone_id,
        timezone_offset_hours_utc: data.timezone_offset_hours_utc,
        timezone_name: data.timezone_name,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
        if (res.success) return res.data;
      });
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProfile: (state, action) => {
      state.user = action.payload;
    },
    setFbDetails: (state, action) => {
      state.fb = action.payload;
    },
    setActiveAdAccount: (state, action) => {
      state.currentAdAccount = action.payload;
    },
    setIsAdAccountChanging: (state, action) => {
      state.isAdAccountChanging = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = "";
    });
    builder.addCase(fetchUserProfile.rejected, (state, action) => {
      state.loading = false;
      state.user = "";
      state.error = action.error.message;
    });
    builder.addCase(saveFbDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveFbDetails.fulfilled, (state, action) => {
      state.loading = false;
      // state.user = action.payload;
      state.error = "";
    });
    builder.addCase(saveUserAccount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveUserAccount.fulfilled, (state, action) => {
      state.loading = false;
      // state.user = action.payload;
      state.error = "";
    });
    builder.addCase(reAuthenticate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(reAuthenticate.fulfilled, (state, action) => {
      state.loading = false;
      // state.user = action.payload;
      state.error = "";
    });
    builder.addCase(reAuthenticate.rejected, (state, action) => {
      state.loading = false;
      // state.user = action.payload;
      state.error = action.error.message;
    });
    builder.addCase(saveFbDetails.rejected, (state, action) => {
      state.loading = false;
      state.user = "";
      state.error = action.error.message;
    });
    builder.addCase(fetchUserAdAccounts.pending, (state) => {
      state.loading = true;
      state.fbadaccountLoading = true;
    });
    builder.addCase(fetchUserAdAccounts.fulfilled, (state, action) => {
      state.loading = false;
      state.fbadaccountLoading = false;
      state.userAdAccounts = action.payload;
      state.error = "";
    });
    builder.addCase(fetchUserAdAccounts.rejected, (state, action) => {
      state.loading = false;
      state.fbadaccountLoading = false;
      state.userAdAccounts = [];
      state.error = action.error.message;
    });
    builder.addCase(fetchActiveUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchActiveUser.fulfilled, (state, action) => {
      state.loading = false;
      state.activeUser = action.payload;
      state.error = "";
    });
    builder.addCase(fetchActiveUser.rejected, (state, action) => {
      state.loading = false;
      state.activeUser = [];
      state.error = action.error.message;
    });
    builder.addCase(getForgetPasswordCode.pending, (state) => {
      state.forgotPassword.loading = true;
    });
    builder.addCase(getForgetPasswordCode.fulfilled, (state, action) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.code = action.payload;
    });
    builder.addCase(getForgetPasswordCode.rejected, (state) => {
      state.forgotPassword.loading = false;
      state.forgotPassword.code = "";
    });
  },
});

export default userSlice.reducer;
export const {
  setProfile,
  setFbDetails,
  setActiveAdAccount,
  setIsAdAccountChanging,
} = userSlice.actions;
