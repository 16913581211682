import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {
  TableBodyCell,
  TableHeaderCell as StyledTableHeaderCell,
} from "../../../components/Table/Cell";

import { createStyles } from "../../../config/theme";

import { OverageCharges } from "../../../interfaces/entity";

const TableHeaderCell = StyledTableHeaderCell<OverageCharges>;

interface OverageChargesProps {
  data?: OverageCharges[];
  switcher: any;
}
interface OverageChargesBodyProps {
  rows: number;
  columns: number;
  data: OverageCharges[];
  switcher: any;
}
interface OverageChargesDataRowProps {
  row: OverageCharges;
  switcher: any;
}

export default function OverageChargesDataTable({
  data = [],
  switcher,
}: OverageChargesProps) {
  return (
    <Box>
      <TableContainer>
        <Table sx={styles.table}>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableHeaderCell name="plans" minWidth={120}>
                Plans
              </TableHeaderCell>
              <TableHeaderCell minWidth={120}>Spends Upto</TableHeaderCell>
              <TableHeaderCell style={styles.tableHeaderCell} minWidth={150}>
                Overage
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBodyContent
            switcher={switcher}
            data={data}
            rows={6}
            columns={3}
          />
        </Table>
      </TableContainer>
    </Box>
  );
}

const TableBodyContent = ({ data, switcher }: OverageChargesBodyProps) => {
  return (
    <TableBody>
      {data?.map((row) => (
        <DataRow key="key" switcher={switcher} row={row} />
      ))}
    </TableBody>
  );
};

const DataRow = ({ row, switcher }: OverageChargesDataRowProps) => {
  return (
    <TableRow sx={{ "&:last-child td": { border: 0 } }}>
      <TableBodyCell border>
        {switcher ? row.plans : row.usd_plans}
      </TableBodyCell>
      <TableBodyCell border>
        {switcher ? row.spends_upto : row.usd_spends_upto}
      </TableBodyCell>
      <TableBodyCell style={styles.tableBodyCell} border>
        {row.overage}
      </TableBodyCell>
    </TableRow>
  );
};

const styles = createStyles({
  table: {
    borderCollapse: "separate",
  },
  tableHead: {
    backgroundColor: "rgba(0, 9, 37, 0.2)",
    borderColor: "red",
  },
  tableHeaderCell: {
    textAlign: "center",
  },
  tableBodyCell: {
    textAlign: "center",
  },
});
