import React, { useState, useEffect } from 'react'
import { Wrapper } from './PaymentRedirect.styles'
import { useNavigate } from 'react-router-dom';
import UNIVERSAL from 'src/config/config';
import Lottie from 'lottie-react';
import walletLoading from "src/assets/wallet-loading.json"
import succesfull from "src/assets/succesfull.json"

const PaymentRedirect = () => {
    const [counter, setCounter] = useState<any>(5)
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const razorpay_payment_id = urlParams.get("razorpay_payment_id");
        const razorpay_signature = urlParams.get("razorpay_signature");
        const razorpay_payment_link_reference_id = urlParams.get("razorpay_payment_link_reference_id");
        const razorpay_payment_link_status = urlParams.get("razorpay_payment_link_status");
        const razorpay_payment_link_id = urlParams.get("razorpay_payment_link_id")

        if (!paymentSuccess) {
            fetch(`${UNIVERSAL.BASEURL}/api/v1/wallet/verify_payment`, {
                method: "POST",
                body: JSON.stringify({
                    razorpay_payment_id: razorpay_payment_id,
                    razorpay_payment_link_id: razorpay_payment_link_id,
                    signature: razorpay_signature,
                    transaction_id: razorpay_payment_link_reference_id
                })
            }).then((res => res.json()))
                .then((res => {
                    if (res.success) {
                        setPaymentSuccess(true);
                    } else {
                        alert("Error making payment")
                    }
                }
                ))
                .catch((err => alert("ERROR making payment")))
        } else {
            const interval = setInterval(() => {
                if (counter === 0) {
                    if (paymentSuccess) navigate("/dashboard")
                    return;
                    // navigate("/dashboard")

                }
                setCounter(counter - 1)
            }, 1000)

            return () => clearInterval(interval)
        }
    })

    return (
        <Wrapper>
            {!paymentSuccess && <>
                <Lottie
                    style={{
                        width: '18rem',
                        marginBottom: "-3rem"
                    }}
                    animationData={walletLoading}
                    loop={true}
                />
                <h2>Your payment is being validated.</h2>
            </>}
            {paymentSuccess &&
                <>
                    <Lottie
                        style={{
                            width: '18rem',
                            marginBottom: "-3rem"
                        }}
                        animationData={succesfull}
                        loop={false}
                    />
                    <h2>Payment successfull</h2>
                    <p>Please wait and we will be redirecting you in {counter}</p>
                </>
            }
        </Wrapper >
    )
}

export default PaymentRedirect