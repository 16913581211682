import { useCallback, useEffect, useRef, useState } from "react";

export function useCountdown(props?: {
  start?: number;
  end?: number;
  skip?: boolean;
}) {
  const start = props?.start ?? 30;
  const end = props?.end ?? 0;

  const [countdown, setCountdown] = useState(start);

  const handle = useRef<NodeJS.Timeout>();

  const stopCountdown = () => {
    if (handle.current) clearInterval(handle.current);
  };

  const resetCountdown = () => {
    if (handle.current) clearInterval(handle.current);
    setCountdown(start);
  };

  const startCountdown = () => {
    onUpdateCountdown();
  };

  const resetAndStartCountdown = () => {
    resetCountdown();
    startCountdown();
  };

  const onUpdateCountdown = useCallback(() => {
    if (handle.current) clearInterval(handle.current);
    handle.current = setInterval(() => {
      setCountdown((state) => (state <= end ? state : state - 1));
    }, 1000);
  }, [end]);

  useEffect(() => {
    if (props?.skip) return;
    onUpdateCountdown();
    return () => {
      if (handle.current) clearInterval(handle.current);
    };
  }, [onUpdateCountdown, props?.skip]);

  return [
    countdown,
    {
      stopCountdown,
      startCountdown,
      resetCountdown,
      resetAndStartCountdown,
      hasCountdownEnded: countdown === end,
    },
  ] as const;
}
