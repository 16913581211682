export const defaultCurrency = "USD";
export const defaultCurrencyOffset = 100;

export const currencies = [
  { countryCode: "DZ", currencyCode: "DZD", currencyName: "Algerian Dinar" },
  { countryCode: "AR", currencyCode: "ARS", currencyName: "Argentine Peso" },
  { countryCode: "AU", currencyCode: "AUD", currencyName: "Australian Dollars" },
  { countryCode: "BD", currencyCode: "BDT", currencyName: "Bangladesh Taka" },
  { countryCode: "BO", currencyCode: "BOB", currencyName: "Bolivian Boliviano" },
  { countryCode: "GB", currencyCode: "GBP", currencyName: "British Pound" },
  { countryCode: "BR", currencyCode: "BRL", currencyName: "Brazilian Real" },
  { countryCode: "CA", currencyCode: "CAD", currencyName: "Canadian Dollars" },
  { countryCode: "CL", currencyCode: "CLP", currencyName: "Chilean Peso" },
  { countryCode: "CN", currencyCode: "CNY", currencyName: "Chinese Yuan" },
  { countryCode: "CO", currencyCode: "COP", currencyName: "Colombian Peso" },
  { countryCode: "CR", currencyCode: "CRC", currencyName: "Costa Rican Colon" },
  { countryCode: "CZ", currencyCode: "CZK", currencyName: "Czech Republic Koruna" },
  { countryCode: "DK", currencyCode: "DKK", currencyName: "Danish Krone" },
  { countryCode: "EG", currencyCode: "EGP", currencyName: "Egyptian Pound" },
  { countryCode: "EU", currencyCode: "EUR", currencyName: "Euro" },
  { countryCode: "GT", currencyCode: "GTQ", currencyName: "Guatemalan Quetzal" },
  { countryCode: "HN", currencyCode: "HNL", currencyName: "Honduran Lempira" },
  { countryCode: "HK", currencyCode: "HKD", currencyName: "Hong Kong Dollar" },
  { countryCode: "HU", currencyCode: "HUF", currencyName: "Hungarian Forint" },
  { countryCode: "IS", currencyCode: "ISK", currencyName: "Icelandic Krona" },
  { countryCode: "IN", currencyCode: "INR", currencyName: "Indian Rupee" },
  { countryCode: "ID", currencyCode: "IDR", currencyName: "Indonesian Rupiah" },
  { countryCode: "IL", currencyCode: "ILS", currencyName: "Israeli New Shekel" },
  { countryCode: "JP", currencyCode: "JPY", currencyName: "Japanese Yen" },
  { countryCode: "KE", currencyCode: "KES", currencyName: "Kenyan Shilling" },
  { countryCode: "MO", currencyCode: "MOP", currencyName: "Macanese Pataca" },
  { countryCode: "MY", currencyCode: "MYR", currencyName: "Malaysian Ringgit" },
  { countryCode: "MX", currencyCode: "MXN", currencyName: "Mexican Peso" },
  { countryCode: "TW", currencyCode: "TWD", currencyName: "New Taiwan Dollar" },
  { countryCode: "NZ", currencyCode: "NZD", currencyName: "New Zealand Dollar" },
  { countryCode: "NI", currencyCode: "NIO", currencyName: "Nicaraguan Cordoba" },
  { countryCode: "NG", currencyCode: "NGN", currencyName: "Nigerian Naira" },
  { countryCode: "NO", currencyCode: "NOK", currencyName: "Norwegian Krone" },
  { countryCode: "PK", currencyCode: "PKR", currencyName: "Pakistan Rupee" },
  { countryCode: "PY", currencyCode: "PYG", currencyName: "Paraguayan Guarani" },
  { countryCode: "PE", currencyCode: "PEN", currencyName: "Peruvian Nuevo Sol" },
  { countryCode: "PH", currencyCode: "PHP", currencyName: "Philippine Peso" },
  { countryCode: "PL", currencyCode: "PLN", currencyName: "Polish Zloty" },
  { countryCode: "QA", currencyCode: "QAR", currencyName: "Qatari Rials" },
  { countryCode: "RO", currencyCode: "RON", currencyName: "Romanian Leu" },
  { countryCode: "RU", currencyCode: "RUB", currencyName: "Russian Rouble" },
  { countryCode: "SA", currencyCode: "SAR", currencyName: "Saudi Riyal" },
  { countryCode: "SG", currencyCode: "SGD", currencyName: "Singapore Dollar" },
  { countryCode: "ZA", currencyCode: "ZAR", currencyName: "South African Rand" },
  { countryCode: "KR", currencyCode: "KRW", currencyName: "South Korean Won" },
  { countryCode: "SE", currencyCode: "SEK", currencyName: "Swedish Krona" },
  { countryCode: "CH", currencyCode: "CHF", currencyName: "Swiss Franc" },
  { countryCode: "TH", currencyCode: "THB", currencyName: "Thai Baht" },
  { countryCode: "TR", currencyCode: "TRY", currencyName: "Turkish Lira" },
  { countryCode: "AE", currencyCode: "AED", currencyName: "UAE Dirham" },
  { countryCode: "UA", currencyCode: "UAH", currencyName: "Ukrainian hryvnia" },
  { countryCode: "UY", currencyCode: "UYU", currencyName: "Uruguayan Peso" },
  { countryCode: "US", currencyCode: "USD", currencyName: "US Dollars" },
  { countryCode: "VE", currencyCode: "VEF", currencyName: "Venezuelan Bolivar" },
  { countryCode: "VN", currencyCode: "VND", currencyName: "Vietnamese Dong" },
];
