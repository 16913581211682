import { useState, useEffect } from "react";
import {
  Wrapper,
  LinkAdAccountModalContent,
  ArrowIcon,
  Main,
  Dialog,
  StatData,
  StatFlex,
  CampaignPerformance,
  TitleFlex,
  GraphWrapper,
  SchedulerModal,
  SchedulerModalBox,
  SchedulerModalHeader,
  SchedulerModalContainer,
  SchedulerRedirectionLink,
} from "./NotLinked.styles";
import { Button, Link } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import linkAdAccountIcon from "src/assets/linkAdAccountIcon.png";
import arrowIcon from "src/assets/arrowIcon.png";
import clicks from "src/assets/clicks.svg";
import ctr from "src/assets/ctr.svg";
import impression from "src/assets/impressions.svg";
import spend from "src/assets/total-spends.svg";
import cpm from "src/assets/cpm.svg";
import results from "src/assets/results.svg";
import roas from "src/assets/roas.svg";
import costs from "src/assets/costs.svg";
import CloseCircleIcon from "../../../assets/close-circle.svg";

import StatCard from "../statCard/StatCard";
import Chart from "react-apexcharts";
import { NotLinkedContentProps } from "./types";
import { useAppSelector } from "src/hooks/useAppSelector";

const NotLinkedContent = ({
  setAdAccountModal,
  setLinkModal,
  modal,
}: NotLinkedContentProps) => {
  const [campaignChartData, setCampaignChartData] = useState<any>("");
  const [campaignChartOptions, setCampaignChartOptions] = useState<any>("");
  const user = useAppSelector((state) => state.user.user);

  const [showScheduler, setShowScheduler] = useState(false);

  useEffect(() => {
    const cac = [
      446.2, 702.14, 488.25, 473.37, 492.88, 504.41, 377.58, 882.27, 497.04,
      508.51, 472.25, 409.53, 700.59, 460.31, 321.33, 405.61, 462.79, 373.72,
      610.97, 622.81, 486.82, 800.97, 756.51, 530.65, 452.6,
    ];
    const result = [
      36, 28, 39, 43, 72, 73, 76, 42, 47, 30, 23, 21, 14, 18, 29, 22, 23, 33,
      20, 21, 27, 17, 15, 13, 21,
    ];

    let dateStart: any[] = [
      "2023-02-02T00:00:00.000Z",
      "2023-02-01T00:00:00.000Z",
      "2023-01-31T00:00:00.000Z",
      "2023-01-30T00:00:00.000Z",
      "2023-01-29T00:00:00.000Z",
      "2023-01-28T00:00:00.000Z",
      "2023-01-27T00:00:00.000Z",
      "2023-01-26T00:00:00.000Z",
      "2023-01-25T00:00:00.000Z",
      "2023-01-24T00:00:00.000Z",
      "2023-01-23T00:00:00.000Z",
      "2023-01-22T00:00:00.000Z",
      "2023-01-21T00:00:00.000Z",
      "2023-01-20T00:00:00.000Z",
      "2023-01-19T00:00:00.000Z",
      "2023-01-18T00:00:00.000Z",
      "2023-01-17T00:00:00.000Z",
      "2023-01-16T00:00:00.000Z",
      "2023-01-15T00:00:00.000Z",
      "2023-01-14T00:00:00.000Z",
      "2023-01-13T00:00:00.000Z",
      "2023-01-12T00:00:00.000Z",
      "2023-01-11T00:00:00.000Z",
      "2023-01-10T00:00:00.000Z",
      "2023-01-09T00:00:00.000Z",
    ];

    dateStart = dateStart.map((dt) => new Date(dt).toISOString());
    setCampaignChartData([
      {
        name: "Purchases",
        data: result,
      },
      {
        name: "CAC",
        data: cac,
      },
    ]);

    setCampaignChartOptions({
      chart: {
        type: "area",
        fontFamily: "Montserrat, sans-serif",
        toolbar: {
          show: false,
        },
      },
      grid: { show: false },
      markers: {
        size: 4,
        colors: undefined,
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: 4,
          sizeOffset: 3,
        },
      },
      colors: ["#150DD1", "#ef8f72"],
      dataLabels: {
        enabled: false,
        style: {
          opacity: 0,
          fontSize: 0,
          color: "#fff",
        },
        background: {
          borderRadius: 100,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        colors: undefined,
        width: 2,
        dashArray: 0,
      },
      xaxis: {
        type: "datetime",
        categories: dateStart.sort((a, b) => +new Date(b) - +new Date(a)),
        labels: {
          style: {
            colors: "#FFFFFF99",
          },
        },
      },
      yaxis: [
        {
          title: {
            text: "Purchases",
            style: {
              color: "#FFF",
            },
          },
          labels: {
            formatter: (val: any) => parseFloat(val).toFixed(1),
            style: {
              colors: "#FFFFFF99",
            },
          },
        },
        {
          opposite: true,
          title: {
            text: "CAC",
            style: {
              color: "#FFF",
            },
          },
          labels: {
            formatter: (val: any) => (val >= 1000 ? `${val / 1000}K` : val),
            style: {
              colors: "#FFFFFF99",
            },
          },
        },
      ],
      legend: {
        labels: {
          colors: "#fff",
        },
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
        y: {
          formatter: (val: any) => parseFloat(val).toFixed(1),
        },
      },
    });
  }, []);

  const handleRemindMeLater = () => {
    if (
      user?.data?.currency &&
      (user?.data?.currency === "INR" ||
        user?.data?.currency === "" ||
        user?.data?.currency === null)
    ) {
      setLinkModal(false);
    } else {
      setShowScheduler(true);
    }
  };

  return (
    <Wrapper modal={modal}>
      {modal && (
        <LinkAdAccountModalContent>
          <img src={linkAdAccountIcon} />
          <h3>Link your ad account</h3>
          <p>Link your ad accounts to view the insights for your ad</p>
          <Button onClick={() => setAdAccountModal(true)} variant="contained">
            Link ad account
          </Button>
          <Button
            onClick={handleRemindMeLater}
            sx={{ marginTop: "0.5rem", cursor: "pointer" }}
          >
            Remind me later
          </Button>
          <ArrowIcon src={arrowIcon} />
        </LinkAdAccountModalContent>
      )}

      <Main modal={modal}>
        <Dialog>
          {" "}
          <ErrorOutlineIcon />
          <p>
            This dashboard is built on sample data. Add your ad account to view
            the real data
          </p>
        </Dialog>
        <StatData>
          <StatFlex>
            <StatCard loading={false} title="Clicks" img={clicks} val="4234" />
            <StatCard
              loading={false}
              title="Impressions"
              img={impression}
              val="12000"
            />
            <StatCard
              loading={false}
              title="CTR"
              img={ctr}
              percentage
              val="56"
            />
            <StatCard
              loading={false}
              title="Total Ad spends"
              img={spend}
              val="23777"
              currency={true}
            />
          </StatFlex>
          <StatFlex>
            <StatCard
              loading={false}
              title="CPM"
              img={cpm}
              val="23"
              currency={true}
            />
            <StatCard
              loading={false}
              title="Results"
              img={results}
              val="12000"
            />
            <StatCard
              loading={false}
              title="Cost / Result"
              img={costs}
              val="26"
              currency={true}
            />
            <StatCard loading={false} title="ROAS" img={roas} val="0.0" />
          </StatFlex>
        </StatData>
        <CampaignPerformance>
          <TitleFlex>
            <h3>Campaign performance</h3>
            <p>
              <sub>30 &nbsp; days</sub>
            </p>
          </TitleFlex>

          <GraphWrapper>
            {campaignChartData && campaignChartOptions && (
              <Chart
                options={campaignChartOptions}
                series={campaignChartData}
                type="area"
                // width={740}
                height={window.innerWidth <= 900 ? 250 : 400}
              />
            )}
          </GraphWrapper>
        </CampaignPerformance>
      </Main>

      <SchedulerModal
        open={showScheduler}
        onClose={() => setShowScheduler(false)}
      >
        <SchedulerModalBox>
          <SchedulerModalHeader>
            <h3></h3>
            <img
              src={CloseCircleIcon}
              alt="circle-cross-icon"
              onClick={() => setShowScheduler(false)}
            />
          </SchedulerModalHeader>
          <SchedulerModalContainer>
            <h4>
              To get started with the ad account, Please do schedule a call with
              our sales team
            </h4>
            <SchedulerRedirectionLink
              href="https://meetings.hubspot.com/vikash-s"
              target="_blank"
              rel="noopener noreferrer"
            >
              Schedule a call
            </SchedulerRedirectionLink>
          </SchedulerModalContainer>
        </SchedulerModalBox>
      </SchedulerModal>
    </Wrapper>
  );
};

export default NotLinkedContent;
