import { Box, Stack, StackProps, Typography } from "@mui/material";

interface PageHeaderProps extends StackProps {
  title: string;
  subtitle: string;
}

export default function PageHeader({
  subtitle,
  title,
  children,
  ...props
}: PageHeaderProps) {
  return (
    <Stack
      direction="row"
      alignItems="flex-end"
      justifyContent="space-between"
      {...props}
    >
      <Box>
        <Typography variant="h5" color="text.primary" fontWeight={600}>
          {title}
        </Typography>
        <Typography color="text.secondary" lineHeight={1.75}>
          {subtitle}
        </Typography>
      </Box>
      <Box>{children}</Box>
    </Stack>
  );
}
