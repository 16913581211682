import { styled, TableCell } from "@mui/material";

const TableBodyCell = styled(TableCell, {
  shouldForwardProp: (prop) =>
    prop !== "fontSize" && prop !== "fontWeight" && prop !== "border",
})<{ fontSize?: number; fontWeight?: number; border?: boolean }>(
  ({ fontSize = 14, fontWeight = 400, border = false, theme }) => ({
    color: theme.palette.text.primary,
    borderBottomWidth: border ? 1 : 0,
    fontWeight,
    fontSize,
  })
);

export default TableBodyCell;
