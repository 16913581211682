import { Box, Modal } from "@mui/material";
import styled from "styled-components";

export const WalletIndianContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

export const PaymentLinkChargesContainer = styled.div`
  height: 275px;
  width: 234px;
  position: relative;
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  color: #fff;
  border-radius: 1rem;
  padding: 2rem;
  margin: 6.5rem 0 2rem 0;
  border: 1px solid rgba(20, 80, 255, 1);

  /* &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    border: 1px solid transparent;
    background: linear-gradient(
        to right,
        rgba(20, 80, 255, 1),
        rgba(0, 66, 255, 1)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  } */

  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 20px solid rgba(18, 51, 149, 1);
    top: 50%;
    left: -1.3rem;
    transform: translateY(-50%);
  }
`;

export const PaymentLinkCharges = styled.div`
  position: relative;
  > h4 {
    color: white;
    font-size: 18px;
    line-height: 25px;
  }

  > p {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 15px;
    margin: 0.4rem 0 1.5rem 0;
  }

  > span {
    display: block;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 15px;
    margin: 0.4rem 0;
  }
`;

export const Wrapper = styled.div`
  border-radius: 1.5rem;
  background-color: rgba(0, 28, 108, 1);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.3);
  width: 600px;
`;

export const WalletBalance = styled.div`
  background: rgba(30, 30, 30, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
  gap: 2.5rem;
  border: 1px solid #ffffff33;
  border-radius: 0.6rem;
  margin-bottom: 2rem;

  img {
    width: 2.5rem;
  }

  > h2 {
    color: white;
  }
`;

export const WalletBalanceLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  > img {
    width: 51px;
    height: 51px;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0.2rem;
    color: white;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #ffffff80;
  }
`;

export const AmountFlex = styled.div`
  margin-top: 1.2rem;
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;

  & > * {
    flex: 1;
  }

  /* &::after {
        content: "";
        position: absolute;
        bottom: -1rem;
        left: 0 ;
        width: 100%;
        height: 1px;
        border-bottom: 2px dashed #FFFFFF33 ;
    } */
`;

type AmountChipProps = {
  active?: boolean;
};

export const AmountChip = styled.div<AmountChipProps>`
  padding: 0.5rem 1rem;
  border: ${(props) =>
    props.active ? "1px solid #FFF" : "1px solid #FFFFFF33"};
  border-radius: 0.6rem;
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProcessingFee = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 2px;
  margin: 15px 0 0 0;

  > h4 {
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
  }

  > P {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
  }
`;

export const AmountDetail = styled.div`
  margin-top: 1rem;
  width: 100%;
  border-top: 1px dashed #3d569e;
  border-bottom: 1px dashed #3d569e;
  padding: 1rem 0;

  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
  }

  h4 {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
  }
`;

export const AmountTotal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  p {
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
  }

  h2 {
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
  }
`;

export const WalletNote = styled.div`
  border: 1px solid #ffffff33;
  border-radius: 2rem;
  background: rgba(255, 255, 255, 0.05);
  display: flex;
  padding: 0.5rem 1rem;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;

  p {
    color: #ffffffb2;
    font-size: 0.9rem;
  }
`;

export const SendEmail = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  align-self: self-start;
  margin-top: 0.5rem;
  cursor: pointer;

  & > * {
    padding: 0 !important;
  }
`;

export const SubmitButton = styled.button`
  height: 50px;
  border-radius: 10px;
  background: linear-gradient(
    to left,
    rgba(0, 102, 255, 1),
    rgba(0, 148, 255, 1)
  );
  opacity: ${(props) => (props.disabled ? "0.6" : "")};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  margin-top: 1rem;
`;

export const PaymentDetailsContainer = styled(Modal)``;

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  height: 610px;
  width: 780px;
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  color: #fff;
  border-radius: 15px;
  padding: 1.5rem;
`;

export const CrossIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  > img {
    cursor: pointer;
  }
`;

export const ModalBoxBody = styled.div`
  padding: 0 35px;
  margin-top: 10px;

  > hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    margin: 1rem 0 1.5rem 0;
  }
`;

export const ModalBodyTop = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  > img {
    height: 38px;
    width: 43px;
  }
  > div > h3 {
    font-size: 24px;
    font-weight: 600;
    color: white;
    line-height: 36px;
  }

  > div > p {
    font-size: 16px;
    font-weight: 400;
    color: white;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.4);
  }
`;

export const ModalBodyDetails = styled.div`
  border: 1px solid rgba(1, 104, 255, 1);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1.5rem;
  padding: 10px 20px 10px 40px;
  height: 420px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    > div {
      display: flex;
      align-items: center;
    }
    > div:nth-child(1) > h4 {
      /* width: 130px; */
    }

    > div > h4 {
      width: 150px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(255, 255, 255, 0.4);
    }
    > div > p {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: white;
      word-break: break-word;
    }

    > div:nth-child(1) > p {
      margin-left: 20px;
    }

    > div:nth-child(2) > p {
    }

    > div:nth-child(3) > p {
      margin-left: 2px;
    }

    > div:nth-child(4) > p {
      margin-left: 2px;
    }

    > div:nth-child(5) > p {
      margin-left: 2px;
    }

    > div:nth-child(6) > p {
      margin-left: 2px;
    }
  }
`;

export const PaymentSuccessContainer = styled(Modal)``;

export const ModalSuccessBox = styled(Box)`
  position: relative;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  height: 460px;
  width: 533px;
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  color: #fff;
  border-radius: 15px;
  padding: 1.5rem;
  outline: none;
`;

export const SuccessBoxTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 70px;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  > img:nth-child(1) {
    animation: fade 3s linear;
  }
  > img:nth-child(2) {
    position: absolute;
    top: 0;
    left: 150px;
    animation: fade 1s linear;
  }
  > img:nth-child(3) {
    position: absolute;
    top: 90px;
    left: 70px;
    animation: fade 1.5s linear;
  }
  > img:nth-child(4) {
    position: absolute;
    top: 60px;
    right: 70px;
    animation: fade 2s linear;
  }
  > img:nth-child(5) {
    position: absolute;
    top: 140px;
    right: 110px;
    animation: fade 2.5s linear;
  }
`;

export const SuccessBoxBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  > h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 26px;
    color: white;
  }
  > h4 {
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.5);
    margin: 0;
  }
  > p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    margin: 0;
  }
`;
export const PaymentFailedContainer = styled(Modal)``;

export const ModalFailedBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  height: 440px;
  width: 533px;
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  color: #fff;
  border-radius: 15px;
  padding: 1.5rem;
  outline: none;
`;

export const FailedBoxTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-top: 40px;
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  > img {
    animation: fade 1.5s linear;
  }
`;

export const FailedBoxBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 3rem;

  > h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 26px;
    color: rgba(217, 95, 61, 1);
  }
  > p {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    color: rgba(255, 255, 255, 0.5);
  }

  > button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    margin-top: 2rem;
  }

  > button > img {
  }
  > button > span {
    text-decoration: underline;
    color: rgba(192, 208, 255, 1);
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 1px;
  }
`;
