import React from "react";
import Chart from "react-apexcharts";
import {
  Bottom,
  ChartContainer,
  Dot,
  Label,
  TitleFlex,
  Wrapper,
} from "./BudgetPieChart.styles";
import { useAppSelector } from "src/hooks/useAppSelector";
import { fetchSymbolFromCurrency } from "src/libs/utils";
const BudgetPieChart = () => {
  const user = useAppSelector((state: any) => state.user);

  const series = [444561, 52345];
  const options = {
    chart: {
      fontFamily: "Montserrat, sans-serif",
      toolbar: {
        show: false,
      },
    },
    colors: ["#66C674", "#FFC700"],
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 1,
        donut: {
          size: "75%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "0.8rem",
              fontWeight: "600",
              color: "#fff",
            },
            value: {
              show: true,
              color: "#fff",
              fontSize: "1.6rem",
              fontWeight: "500",
              formatter: function (val: any) {
                return (
                  `${
                    user?.user?.data?.currency
                      ? fetchSymbolFromCurrency(user?.user?.data?.currency)
                      : "₹"
                  }` + String(val).toLocaleString()
                );
              },
            },
            total: {
              show: true,
              label: "Budget",
              fontSize: "0.9rem",
              color: "#FFFFFF80",
              formatter: function (val: any) {
                console.log(val, "TOTAL VAL");
                let total = 0;
                val.config.series?.map((item: any) => {
                  total = total + item;
                });
                return (
                  `${
                    user?.user?.data?.currency
                      ? fetchSymbolFromCurrency(user?.user?.data?.currency)
                      : "₹"
                  }` + String(total).toLocaleString()
                );
              },
            },
          },
        },
      },
    },
    labels: ["New", "Re-targeting"],
  };

  return (
    <Wrapper>
      <TitleFlex>
        <h3>Budget allocation</h3>
      </TitleFlex>
      <ChartContainer>
        <Chart
          // style={{ height: "90%", width: "100%" }}
          options={options as any}
          series={series}
          type="donut"
        />
        <Bottom>
          <Label>
            <Dot label="new" />
            <p>New</p>
          </Label>
          <Label>
            <Dot label="retargeting" />
            <p>Re-targeting</p>
          </Label>
        </Bottom>
      </ChartContainer>
    </Wrapper>
  );
};

export default BudgetPieChart;
