import { Paper, styled } from "@mui/material";

const Background = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4, 4, 2),
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: theme.palette.primary.light,
  background:
    "linear-gradient( to right,rgba(0,28,108,1), rgba(0, 9, 37, 0.2) )",
  borderRadius: "15px",
  border: "1px solid rgba(255, 255, 255, 0.3)",
  marginTop: "6rem",
}));

export default Background;
