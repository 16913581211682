import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Modal,
  Skeleton,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";

import DateRangePicker from "../../components/dateRangePicker/DateRangePicker";
// import {
//   CircularProgressbar,
//   CircularProgressbarWithChildren,
//   buildStyles,
// } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

import {
  Wrapper,
  TitleFlex,
  Filter,
  StatFlex,
  GraphWrapper,
  CampaignPerformance,
  LinkModalWrapper,
  FacebookBtn,
  AdAccountList,
  SelectAccountWrapper,
  FilterLeft,
  NoDataWrapper,
  Flex,
  DateRangeWrapper,
  // DateRangePicker,
  ResponsiveFilters,
  ResponsiveFlex,
  BottomDrawerContent,
  SearchInput,
  CustomMenuItem,
  SelectAdAccountBtns,
  ListItem,
  CustomPopover,
  CustomToolTip,
  Automation,
  AutomationStat,
  Analyze,
  GraphFlex,
  CreateAdAccountBtn,
} from "./Dashboard.styles";

import StatCard from "./statCard/StatCard";

import TuneIcon from "@mui/icons-material/Tune";

import clicks from "../../assets/clicks.svg";
import ctr from "../../assets/ctr.svg";
import impression from "../../assets/impressions.svg";
import spend from "../../assets/total-spends.svg";
import cpm from "../../assets/cpm.svg";
import results from "../../assets/results.svg";
import roas from "../../assets/roas.svg";
import costs from "../../assets/costs.svg";
import noDataImg from "../../assets/noData.jpg";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import moneyImg from "src/assets/money.png";

import { fetchUserProfile, saveFbDetails } from "../../slices/user/userSlice";
import { getAllFilters, getInsights } from "../../slices/insight/insightSlice";
import SearchIcon from "@mui/icons-material/Search";
import CampaignTable from "./campaignTable/CampaignTable";
import { DashboardProps } from "./types";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { Preset } from "src/entity/types";
import NotLinkedContent from "./notLinkedContent/NotLinkedContent";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import PurchasesCacChart from "src/components/purchasesCacChart/PurchasesCacChart";
import moment from "moment";
import {
  getAfterAllCampaignInsight,
  getAllCampaignInsight,
  getPrevAllCampaignInsight,
} from "src/slices/allcampaignInsight/allCamapignInsightSlice";
import AutomationAlert from "src/components/automationAlert/AutomationAlert";
// import CircularProgress from "src/components/circularProgress/CircularProgress";
import { getAutomationResult, getTotalAdSpends } from "src/slices/rules/rules";
import { getRule } from "src/slices/rules/rules";
import { TfiInfoAlt } from "react-icons/tfi";
import AdFatigueModal from "src/components/adFatigueModal/AdFatigueModal";
import BudgetPieChart from "src/components/budgetPieChart/BudgetPieChart";
import { useCreateNewAdAccountMutation } from "src/api/ad-account";
import { useSnackbar } from "notistack";
import { createStyles } from "src/config/theme";

const allCampaignStatus = [
  {
    id: "1",
    val: "ACTIVE",
    name: "Active",
  },
  {
    id: "2",
    val: "PAUSED",
    name: "Paused",
  },
  {
    id: "3",
    val: "ARCHIVED",
    name: "Archived",
  },
  {
    id: "4",
    val: "PENDING_REVIEW",
    name: "Pending review",
  },
  {
    id: "5",
    val: "DISAPPROVED",
    name: "Disapproved",
  },
  {
    id: "6",
    val: "PREAPPROVED",
    name: "Preapproved",
  },
  {
    id: "7",
    val: "PENDING_BILLING_INFO",
    name: "Pending billing info",
  },
  {
    id: "8",
    val: "CAMPAIGN_PAUSED",
    name: "Campaign paused",
  },
  {
    id: "9",
    val: "ARCHIVED",
    name: "Archived",
  },
  {
    id: "10",
    val: "ADSET_PAUSED",
    name: "Adset paused",
  },
];

const Dashboard = ({
  linkModal,
  setLinkModal,
  selectedAdAccount,
  setSelectedAdAccount,
  insightsData,
  noInsightModal,
  setNoInsightModal,
  setAllCampaigns,
  allCampaigns,
  allAdsets,
  setAllAdsets,
  allAds,
  setAllAds,
  campaignListForTable,
  allAdAccount,
  isComparison,
  setIsComparison,
  adAccountModal,
  setAdAccountModal,
  setChangeAdAccountModal,
}: DashboardProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [bottomDrawer, setBottomDrawer] = useState(false);
  const [adAccountSearchList, setAdAccountSearchList] = useState<any[]>([]);
  const [adAccountSearchTerm, setAdAccountSearchTerm] = useState("");
  const [datePickerDropDown, setDatePickerDropDown] = useState<any>(null);
  const [datePickerPopper, setDatePickerPopper] = useState(false);
  const [isResetFilter, setIsResetFilter] = useState(false);
  const [automationCircularLoading, setAutomationCircularLoading] =
    useState(true);
  const [fatigueModal, setFatigueModal] = useState(false);

  const [fbAccountName, setFbAccountName] = useState("");
  const [fbAccessToken, setFbAccessToken] = useState("");

  // const [createNewAdAccount] = useCreateNewAdAccountMutation();

  const [currentCampaign, setCurrentCampaign] = useState({
    id: "0",
    name: "All",
  });
  const [currentAd, setCurrentAd] = useState({ id: "0", name: "All" });
  const [currentAdset, setCurrentAdset] = useState({ id: "0", name: "All" });
  const [preset, setPreset] = useState<Preset>({
    val: "last_30d",
    string: "Last 30 days",
  });

  const [currentCampaign2, setCurrentCampaign2] = useState({
    id: "0",
    name: "All",
  });
  const [currentAd2, setCurrentAd2] = useState({
    id: "0",
    name: "All",
  });
  const [currentAdset2, setCurrentAdset2] = useState({ id: "0", name: "All" });
  const [startDate2, setStartDate2] = useState<Date | "">("");
  const [endDate2, setEndDate2] = useState<Date | "">("");
  const [preset2, setPreset2] = useState({
    val: "last_30d",
    string: "Last 30 days",
  });

  // FILTERS

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [campaignStatus, setCampaignStatus] = useState<any>({
    id: "0",
    val: "ALL",
    name: "All",
  });
  const [campaignStatus2, setCampaignStatus2] = useState<any>({
    id: "0",
    val: "ALL",
    name: "All",
  });

  const [objective, setObjective] = useState("ALL");
  // const [startEndDate, setStartEndDate] = useState([
  //   {
  //     startDate: new Date(),
  //     endDate: new Date(),
  //     key: 'selection',
  //   },
  // ]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    user,
    loading: userLoading,
    fbadaccountLoading,
  } = useAppSelector((state) => state.user);
  const fbDetail = useAppSelector((state) => state.user.fb);
  const { loading: insightDataLoading } = useAppSelector(
    (state) => state.insight
  );
  const {
    comparison,
    prevDate,
    afterDate,
    startDate: storeStartDate,
    endDate: storeEndDate,
  } = useAppSelector((state) => state.allCampaignInsight);
  const rule = useAppSelector((state) => state.rules);

  useEffect(() => {
    setAdAccountSearchList(allAdAccount);
  }, [allAdAccount]);

  useEffect(() => {
    if (user?.data?.euid && user?.data?.fb_ad_account) {
      dispatch(
        getAutomationResult({
          ad_account_id: user?.data?.fb_ad_account,
          euid: String(user?.data?.euid),
        })
      );
      // dispatch(getAutomationResult({ ad_account_id: "488714393181545", euid: "37" }))
      // dispatch(getTotalAdSpends({ ad_account_id: "488714393181545" }))
      dispatch(getTotalAdSpends({ ad_account_id: user?.data?.fb_ad_account }));
    }
  }, [user?.data?.euid, user?.data?.fb_ad_account]);

  useEffect(() => {
    if (adAccountSearchTerm === "") {
      setAdAccountSearchList(allAdAccount || []);
    } else {
      const filter_data = allAdAccount.filter((item: any) =>
        item.ad_account_name
          .toLowerCase()
          .includes(adAccountSearchTerm.toLowerCase())
          ? true
          : false
      );

      setAdAccountSearchList(filter_data);
    }
  }, [adAccountSearchTerm]);

  useEffect(() => {
    let d = new Date();
    d = new Date(d.setDate(d.getDate() - 30));
    // setStartEndDate([
    //   {
    //     ...startEndDate[0],
    //     startDate: new Date(d),
    //   },
    // ]);
    setEndDate(new Date());
    setStartDate(d);

    setStartDate2(d);
    setEndDate2(new Date());
  }, []);

  useEffect(() => {
    config_data_top_performing(insightsData?.data?.chart_data);
  }, [insightsData]);

  useEffect(() => {
    if (user?.data?.euid)
      dispatch(
        getRule({
          ad_account_id: user?.data?.fb_ad_account,
          euid: String(user?.data?.euid),
        })
      );
  }, [user?.data?.fb_ad_account, user?.data?.euid]);

  // const get_ad_accounts = (accessToken: string, url: string) => {
  //   const _window = window as any
  //   const _FB = _window.FB

  //   _FB.api(url, (res: any) => {
  //     if (res.data.length !== 0) {
  //       setAdAccounts([...adAccounts, ...res.data]);
  //       const url = `/me/adaccounts?fields=account_id,id,name&access_token=${accessToken}&after=${res.paging.cursors.after}`;
  //       get_ad_accounts(accessToken, url);
  //     } else {
  //       setNoInsightModal(false);
  //       setAdAccountModal(true);
  //     }
  //   });
  //   return;
  // };

  const handle_close_ad_account_modal = () => {
    if (selectedAdAccount?.ad_account_id !== "") {
      if (
        selectedAdAccount?.ad_account_name === "" ||
        selectedAdAccount?.ad_account_name === "-" ||
        selectedAdAccount?.ad_account_name === null ||
        !selectedAdAccount?.ad_account_name
      ) {
        enqueueSnackbar("Ad Account Name is required", {
          variant: "error",
        });
        return;
      } else if (
        selectedAdAccount?.ad_account_id === "" ||
        selectedAdAccount?.ad_account_id === "-" ||
        selectedAdAccount?.ad_account_id === null ||
        !selectedAdAccount?.ad_account_id
      ) {
        enqueueSnackbar("Ad Account Id is required", {
          variant: "error",
        });
        return;
      } else {
        setChangeAdAccountModal(true);
        const tmp = {
          account_status: selectedAdAccount.account_status,
          ad_account_id: selectedAdAccount?.ad_account_id,
          ad_account_name: selectedAdAccount?.ad_account_name,
          app_fb_user_id: user?.data?.app_fb_user_id,
          currency: selectedAdAccount.currency,
          currency_offset: selectedAdAccount.currency_offset,
          disable_reason: selectedAdAccount.disable_reason,
          disable_reason_display_string:
            selectedAdAccount.disable_reason_display_string,
          euid: String(user?.data?.euid),
          min_daily_budget: selectedAdAccount.min_daily_budget,
          timezone_id: selectedAdAccount.timezone_id,
          timezone_offset_hours_utc:
            selectedAdAccount.timezone_offset_hours_utc,
          timezone_name: selectedAdAccount.timezone_name,
        };
        dispatch(saveFbDetails(tmp))
          .unwrap()
          .then(() => {
            dispatch(fetchUserProfile({ email: user?.data?.email }))
              .unwrap()
              .then(() => {
                setLinkModal(false);
                const end = new Date();
                const start = new Date(new Date().setDate(end.getDate() - 30));

                dispatch(
                  getAllFilters({
                    euid: user?.data?.euid,
                    ad_account: selectedAdAccount?.account_id,
                    ad_id: "",
                    adset_id: "",
                    campaign_id: "",
                    date_start: new Date(start).toISOString(),
                    date_stop: new Date(end).toISOString(),
                    preset: "last_30d",
                    status: campaignStatus?.val,
                  })
                )
                  .unwrap()
                  .then((res: any) => {
                    setAllCampaigns(res?.data?.campaigns || []);
                    setAllAds(res?.data?.ads || []);
                    setAllAdsets(res?.data?.adsets || []);
                  })
                  .catch((error) => console.log(error));

                dispatch(
                  getInsights({
                    euid: user?.data?.euid,
                    ad_account: selectedAdAccount?.account_id,
                    ad_id: "",
                    adset_id: "",
                    campaign_id: "",
                    date_start: new Date(start).toISOString(),
                    date_stop: new Date(end).toISOString(),
                    preset: "last_30d",
                    status: campaignStatus?.val,
                  })
                )
                  .unwrap()
                  .then((res: any) => {
                    if (res.message === "No insights are found") {
                      setNoInsightModal(true);
                    }
                  })
                  .catch((error) => console.log(error));
              });

            setChangeAdAccountModal(false);
          });
        setAdAccountModal(false);
      }
    }
  };

  const config_data_top_performing = (data: any) => {
    let clicksData: any[] = [];
    const cpmData: any[] = [];
    const ctrData: any[] = [];
    let impression: any[] = [];
    let results: any[] = [];
    let cac: any[] = [];
    const dateStart: any[] = [];
    let clickTmp = 0;
    let impressionTmp = 0;
    let ctrAvgTmp = 0;
    let spendAvgTmp = 0;

    data?.map((item: any) => {
      clicksData.push(parseInt(item?.clicks));
      cpmData.push(parseInt(item?.cpm));
      ctrData.push(parseInt(item?.ctr));
      impression.push(parseInt(item?.impressions));
      dateStart.push(new Date(item?.date_start).toISOString());
      clickTmp = clickTmp + parseInt(item?.clicks);
      impressionTmp = impressionTmp + parseInt(item?.impressions);
      ctrAvgTmp = ctrAvgTmp + parseInt(item?.ctr);
      spendAvgTmp = spendAvgTmp + parseInt(item?.spend);
      results.push(parseInt(item?.purchases));
      cac.push(parseFloat(item?.cost_per_purchase));
    });

    clicksData = clicksData.reverse();
    impression = impression.reverse();
    cac = cac.reverse();
    results = results.reverse();

    // setCtrAvg((parseFloat(String(ctrAvgTmp / data?.length)).toFixed(1)));
    // setSpendAvg(parseFloat(String(spendAvgTmp / data?.length)).toFixed(1));

    // setClickSum(clickTmp);
    // setImpressionSum(impressionTmp);

    // setChart1Data({
    //   name: chart1Type,
    //   data:
    //     chart1Type === 'clicks'
    //       ? clicksData
    //       : chart1Type === 'cpm'
    //         ? cpmData
    //         : ctrData,
    // });
  };

  useEffect(() => {
    if (insightsData && insightsData?.data?.chart_data?.length) {
      config_data_top_performing(insightsData?.data?.chart_data);
    }
  }, [insightsData]);

  const format_date = (d: Date) => {
    const year = d.getFullYear();
    const month =
      d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`;
    const date = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`;

    return `${year}-${month}-${date}`;
  };

  const get_prev_insight_data = (d1: Date, d2: Date) => {
    const start = new Date(d1);
    const end = new Date(d2);

    dispatch(
      getPrevAllCampaignInsight({
        euid: String(user?.data?.euid),
        ad_account: selectedAdAccount.ad_account_id,
        date_start: start,
        date_stop: end,
        campaign_list: campaignListForTable,
        preset: isComparison ? "" : preset?.val,
      })
    );
  };

  const get_after_insight_data = (d1: Date, d2: Date) => {
    const start = new Date(d1);
    const end = new Date(d2);
    setDatePickerPopper(false);

    dispatch(
      getAfterAllCampaignInsight({
        euid: String(user?.data?.euid),
        ad_account: selectedAdAccount.ad_account_id,
        date_start: start,
        date_stop: end,
        campaign_list: campaignListForTable,
        preset: isComparison ? "" : preset?.val,
      })
    );
  };

  useEffect(() => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (user?.data?.euid && selectedAdAccount.ad_account_id) {
      dispatch(
        getAllCampaignInsight({
          euid: String(user?.data?.euid),
          ad_account: selectedAdAccount.ad_account_id,
          date_start: start,
          date_stop: end,
          campaign_list: campaignListForTable,
          preset: preset?.val,
        })
      );
    }
  }, [user?.data?.euid, selectedAdAccount.ad_account_id, campaignListForTable]);

  const get_all_campaign_insight_data = (d1: Date, d2: Date) => {
    const start = new Date(d1);
    const end = new Date(d2);
    setDatePickerPopper(false);

    dispatch(
      getAllCampaignInsight({
        euid: String(user?.data?.euid),
        ad_account: selectedAdAccount.ad_account_id,
        date_start: start,
        date_stop: end,
        campaign_list: campaignListForTable,
        preset: preset?.val,
      })
    );
  };

  const get_insights_with_filters = () => {
    // let start = new Date(`${startEndDate[0].startDate}`);
    // start = new Date(start.setDate(start.getDate() + 1));
    // let end = new Date(`${startEndDate[0].endDate}`);
    // end = new Date(end.setDate(end.getDate() + 1));
    const start = moment(startDate).format("YYYY-MM-DD");
    const end = moment(endDate).format("YYYY-MM-DD");

    if (preset?.val !== preset2?.val) {
      dispatch(
        getAllFilters({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: "",
          adset_id: "",
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          setPreset2(preset);
        })
        .catch((error) => console.log(error));

      dispatch(
        getInsights({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: "",
          adset_id: "",
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
          // date_start: startEndDate[0].startDate.toISOString(),
          // date_stop: startEndDate[0].endDate.toISOString(),
        })
      )
        .unwrap()
        .then((res) => {
          if (res.message === "No insights are found") {
            setNoInsightModal(true);
          }
        })
        .catch((error) => console.log(error));
    } else if (campaignStatus?.val !== campaignStatus2?.val) {
      dispatch(
        getAllFilters({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: "",
          adset_id: "",
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          setCampaignStatus2(campaignStatus);
        })
        .catch((error) => console.log(error));

      dispatch(
        getInsights({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: "",
          adset_id: "",
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.message === "No insights are found") {
            setNoInsightModal(true);
          }
        })
        .catch((error) => console.log(error));
    } else if (currentCampaign?.id !== currentCampaign2?.id) {
      setAllAdsets([]);
      setAllAds([]);

      dispatch(
        getInsights({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: "",
          adset_id: "",
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.message === "No insights are found") {
            setNoInsightModal(true);
          }
        })
        .catch((error) => console.log(error));

      dispatch(
        getAllFilters({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: "",
          adset_id: "",
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          if (currentCampaign.name === "ALL") {
            setAllCampaigns(res?.data?.campaigns || []);
            setAllAds(res?.data?.ads || []);
            setAllAdsets(res?.data?.adsets || []);
          } else {
            setAllAds(res?.data?.ads || []);
            setAllAdsets(res?.data?.adsets || []);
          }
          setCurrentCampaign2(currentCampaign);
        })
        .catch((error) => console.log(error));
    } else if (currentAdset?.id !== currentAdset2?.id) {
      setAllAds([]);

      dispatch(
        getInsights({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: "",
          adset_id: currentAdset.id,
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.message === "No insights are found") {
            setNoInsightModal(true);
          }
        })
        .catch((error) => console.log(error));

      dispatch(
        getAllFilters({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: "",
          adset_id: currentAdset.id,
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.data) {
            setAllAds(res?.data?.ads || []);
          }

          setCurrentAdset2(currentAdset);
        })
        .catch((error) => console.log(error));
    } else if (currentAd?.id !== currentAd2?.id) {
      dispatch(
        getInsights({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: currentAd.id,
          adset_id: currentAdset.id,
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.message === "No insights are found") {
            setNoInsightModal(true);
          }

          // setAllAds(insight?.data?.filters);
          // setCurrentAdset2(currentAdset);
        })
        .catch((error) => console.log(error));

      dispatch(
        getAllFilters({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: currentAd.id,
          adset_id: currentAdset.id,
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          setCurrentAd(currentAd);
          // setAllAds(insight?.data?.filters);
          // setCurrentAdset2(currentAdset);
        })
        .catch((error) => console.log(error));
    } else if (
      new Date(startDate).toLocaleDateString() !==
        new Date(startDate2).toLocaleDateString() ||
      new Date(endDate).toLocaleDateString() !==
        new Date(endDate2).toLocaleDateString()
    ) {
      dispatch(
        getInsights({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: currentAd.id,
          adset_id: currentAdset.id,
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          if (res.message === "No insights are found") {
            setNoInsightModal(true);
          }
        })
        .catch((error) => console.log(error));

      dispatch(
        getAllFilters({
          euid: user?.data?.euid,
          ad_account: selectedAdAccount.ad_account_id,
          campaign_id: currentCampaign.id,
          ad_id: currentAd.id,
          adset_id: currentAdset.id,
          date_start: start,
          date_stop: end,
          preset: preset?.val,
          status: campaignStatus?.val,
        })
      )
        .unwrap()
        .then((res) => {
          setStartDate2(new Date(startDate));
          setEndDate2(new Date(endDate));

          if (currentCampaign?.name === "All") {
            if (res?.data) {
              setAllCampaigns(res?.data?.campaigns || []);
            }
          }
          if (currentAdset?.name === "All") {
            if (res?.data) {
              setAllAdsets(res?.data?.adsets || []);
            }
          }
          if (currentAd?.name === "All") {
            if (res?.data) {
              setAllAds(res?.data?.ads || []);
            }
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const clear_filters = () => {
    setIsResetFilter(true);
    setAllAds([]);
    setAllAdsets([]);
    setCurrentCampaign({ id: "0", name: "All" });
    setCurrentAd({ id: "0", name: "All" });
    setCurrentAdset({ id: "0", name: "All" });
    setCurrentAd2({ id: "0", name: "All" });
    setCurrentAdset2({ id: "0", name: "All" });
    setPreset({ val: "last_30d", string: "Last 30 days" });
    setPreset2({ val: "last_30d", string: "Last 30 days" });
    setCampaignStatus({
      id: "0",
      val: "ALL",
      name: "All",
    });
    setCampaignStatus2({
      id: "0",
      val: "ALL",
      name: "All",
    });

    dispatch(
      getAllFilters({
        euid: user?.data?.euid,
        ad_account: selectedAdAccount.ad_account_id,
        campaign_id: currentCampaign.id,
        ad_id: "",
        adset_id: "",
        date_start: "",
        date_stop: "",
        preset: "last_30d",
        status: campaignStatus?.val,
      })
    )
      .unwrap()
      .then((res) => {
        setIsResetFilter(false);
      });

    dispatch(
      getInsights({
        euid: user?.data?.euid,
        ad_account: selectedAdAccount.ad_account_id,
        campaign_id: currentCampaign.id,
        ad_id: "",
        adset_id: "",
        date_start: "",
        date_stop: "",
        preset: "last_30d",
        status: campaignStatus?.val,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.message === "No insights are found") {
          setNoInsightModal(true);
        }
      });
  };

  useEffect(() => {
    if (currentCampaign?.name !== currentCampaign2?.name) {
      setAllAdsets([]);
      setAllAds([]);
      setCurrentAd({ id: "0", name: "All" });
      setCurrentAdset({ id: "0", name: "All" });
      setCampaignStatus({
        id: "0",
        val: "ALL",
        name: "All",
      });
      // setCurrentCampaign2(currentCampaign);
    }
  }, [currentCampaign]);

  useEffect(() => {
    if (currentAdset?.id !== currentAdset2?.id) {
      setAllAds([]);
      setCurrentAd({ id: "0", name: "All" });
      setCampaignStatus({
        id: "0",
        val: "ALL",
        name: "All",
      });
      // setCurrentAdset2(currentAdset);
    }
  }, [currentAdset]);

  useEffect(() => {
    if (
      user?.data?.fb_ad_account &&
      user?.data?.fb_access_token &&
      user?.data?.euid &&
      !isResetFilter
    ) {
      if (currentCampaign?.id && currentAdset?.id && currentAd?.id)
        get_insights_with_filters();
    }
  }, [currentCampaign, currentAdset, currentAd, campaignStatus]);

  // const handleCreateNewAdAccount = async () => {
  //   if (!user?.data?.euid || !user?.data?.business_name) return;
  //   try {
  //     await createNewAdAccount({
  //       account_name: user?.data.business_name + "Zocket",
  //       euid: user?.data?.euid,
  //     }).unwrap();
  //     enqueueSnackbar("New Ad account created successful", {
  //       variant: "success",
  //     });
  //   } catch (e) {
  //     console.log(e);
  //     enqueueSnackbar("Something went wrong", { variant: "error" });
  //   }
  // };

  return (
    <Wrapper
      initial={{ y: 60, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: -60, opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.5, delay: 0.1 }}
    >
      {!(rule?.loading || automationCircularLoading) &&
        user?.data?.fb_ad_account &&
        !rule?.data?.length && (
          <Automation>
            <img src={moneyImg} />
            <AutomationStat>
              {/* <h3>45%</h3>
            <LinearProgress sx={{ width: "15rem", height: "0.8rem", borderRadius: "1rem" }} variant='determinate' value={45} /> */}
              <h2>
                If used zocket, your purchase value would be{" "}
                <strong>
                  {user.data.currency
                    ? user.data.currency === "INR"
                      ? "₹"
                      : user.data.currency === "USD"
                      ? "$"
                      : "₹"
                    : "₹"}{" "}
                  {parseFloat(rule.totalAdSpends).toFixed(0).toLocaleString()}
                </strong>
              </h2>
              <p>
                Check how zocket would help you to maintain the best metrics for
                an ad, which would have helped in achieving the purchase value
                of{" "}
                {user.data.currency
                  ? user.data.currency === "INR"
                    ? "₹"
                    : user.data.currency === "USD"
                    ? "$"
                    : "₹"
                  : "₹"}
                {parseFloat(rule.totalAdSpends).toFixed(0).toLocaleString()}{" "}
                instead of{" "}
                {user.data.currency
                  ? user.data.currency === "INR"
                    ? "₹"
                    : user.data.currency === "USD"
                    ? "$"
                    : "₹"
                  : "₹"}
                8,640,170.
              </p>
            </AutomationStat>
            <Button
              onClick={() => setFatigueModal(true)}
              startIcon={<TfiInfoAlt />}
              sx={{
                backgroundImage:
                  "linear-gradient(270.06deg, #6ECE78 -0.37%, #1B7C51 99.96%)",
              }}
              variant="contained"
            >
              Check now
            </Button>
          </Automation>
        )}
      {/* {(rule?.loading || automationCircularLoading) && !rule?.data?.length && user?.data?.fb_ad_account &&
        <Analyze>
          <CircularProgress setAutomationCircularLoading={setAutomationCircularLoading} />
          <div>
            <h2>Analysing your campaigns...</h2>
            <p>Please wait for a sec, we are analysing your campaigns, and get to know how much you can reduce your ad spends with Zocket</p>
          </div>
        </Analyze>
      } */}
      <TitleFlex>
        <h2>Performance Metrics</h2>
        {/* <CreateAdAccountBtn onClick={handleCreateNewAdAccount}>
          <div>+</div> Create Ad Account
        </CreateAdAccountBtn> */}
      </TitleFlex>
      {window.innerWidth <= 900 && (
        <ResponsiveFilters>
          <Filter
            style={{
              height: "max-content",
              flexDirection: "row",
            }}
          >
            <h3>Show</h3>
            <Button variant="outlined" onClick={() => setBottomDrawer(true)}>
              <TuneIcon />
            </Button>
          </Filter>
          <ResponsiveFlex>
            {/* <Chip
              sx={{ background: '#05986a', color: '#fff' }}
              label={
                currentCampaign === 'ALL' ? 'All campaigns' : currentCampaign
              }
            />
            <Chip
              sx={{ backgroundColor: '#05986a', color: '#fff' }}
              label={objective === 'ALL' ? 'All objectives' : objective}
            />
            <Chip
              sx={{ backgroundColor: '#05986a', color: '#fff' }}
              label={`${moment(startEndDate[0]?.startDate).format(
                'Do MMM YY'
              )} to ${moment(startEndDate[0]?.endDate).format('Do MMM YY')}`}
            /> */}
          </ResponsiveFlex>
        </ResponsiveFilters>
      )}
      {window.innerWidth > 900 && (
        <Filter>
          <FilterLeft>
            <h3>Show</h3>
            <Autocomplete
              size="small"
              disableListWrap
              options={[{ id: "0", name: "All" }, ...allCampaigns]}
              componentsProps={{
                paper: {
                  sx: {
                    width: "max-content",
                    minWidth: "100%",
                  },
                },
              }}
              sx={{ flex: "1", marginRight: "0.6rem" }}
              value={currentCampaign}
              onChange={(e, newVal) => {
                setCurrentCampaign(newVal);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Campaign" />
              )}
            />
            <Autocomplete
              size="small"
              disableListWrap
              options={[{ id: "0", name: "All" }, ...allAdsets]}
              componentsProps={{
                paper: {
                  sx: {
                    width: "max-content",
                    minWidth: "100%",
                  },
                },
              }}
              sx={{ flex: "1", marginRight: "0.6rem" }}
              value={currentAdset}
              onChange={(e, newVal) => {
                setCurrentAdset(newVal);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Adset" />}
            />
            {/* <TextField
              select
              variant="outlined"
              size="small"
              disabled={currentCampaign === 'ALL' || currentAdset === 'ALL'}
              label="Ad"
              sx={{ flex: '1', marginRight: '0.6rem' }}
              value={currentAd}
              onChange={(e) => {
                setCurrentAd(e.target.value);
              }}
            >
              <MenuItem value="ALL">All</MenuItem>
              {allAds?.map((item) => {
                return (
                  <MenuItem key={item?.id} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                );
              })}
            </TextField> */}
            <Autocomplete
              size="small"
              disableListWrap
              options={[{ id: "0", val: "All", name: "All" }, ...allAds]}
              componentsProps={{
                paper: {
                  sx: {
                    width: "max-content",
                    minWidth: "100%",
                  },
                },
              }}
              sx={{ flex: "1", marginRight: "0.6rem" }}
              value={currentAd}
              onChange={(e, newVal) => {
                setCurrentAd(newVal);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Ads" />}
            />
            <Autocomplete
              size="small"
              disableListWrap
              options={[{ id: "0", name: "All" }, ...allCampaignStatus]}
              componentsProps={{
                paper: {
                  sx: {
                    width: "max-content",
                    minWidth: "100%",
                  },
                },
              }}
              sx={{ flex: "1", marginRight: "0.6rem" }}
              value={campaignStatus}
              onChange={(e, newVal) => {
                setCampaignStatus(newVal);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />
            {/* <DateRangePicker
              setStartDate={setStartDate}
              startDate={startDate}
              setEndDate={setEndDate}
              endDate={endDate}
              preset={preset}
              setPreset={setPreset}
              setPreset2={setPreset2}
              get_insights_with_filters={get_insights_with_filters}
            /> */}
            <Tooltip
              TransitionComponent={Zoom}
              arrow
              title={
                <CustomToolTip>
                  {comparison ? (
                    <>
                      <p>{`${moment(prevDate.startDate).format(
                        "DD/MM/YYYY"
                      )} - ${moment(prevDate.endDate).format(
                        "DD/MM/YYYY"
                      )}`}</p>
                      <p>with</p>
                      <p>{`${moment(afterDate.startDate).format(
                        "DD/MM/YYYY"
                      )} - ${moment(afterDate.endDate).format(
                        "DD/MM/YYYY"
                      )}`}</p>
                    </>
                  ) : (
                    <p>{`${moment(startDate).format("DD/MM/YYYY")} - ${moment(
                      endDate
                    ).format("DD/MM/YYYY")}`}</p>
                  )}
                </CustomToolTip>
              }
            >
              <TextField
                label="Date"
                size="small"
                sx={{ flex: "1" }}
                value={preset.val ? preset.string : "Custom date range"}
                onClick={(e) => {
                  setDatePickerPopper(true);
                  setDatePickerDropDown(e.currentTarget);
                }}
              />
            </Tooltip>
            <CustomPopover
              sx={{ borderRadius: 20 }}
              open={datePickerPopper}
              onClose={() => {
                setDatePickerPopper(false);
              }}
              anchorEl={datePickerDropDown}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              <DateRangePicker
                setStartDate={setStartDate}
                startDate={startDate}
                setEndDate={setEndDate}
                endDate={endDate}
                preset={preset}
                setPreset={setPreset}
                setPreset2={setPreset2}
                get_insights_with_filters={get_insights_with_filters}
                isComparison={isComparison}
                setIsComparison={setIsComparison}
                get_prev_insight_data={get_prev_insight_data}
                get_after_insight_data={get_after_insight_data}
                datePickerPopper={datePickerPopper}
                setDatePickerPopper={setDatePickerPopper}
                get_all_campaign_insight_data={get_all_campaign_insight_data}
              />
            </CustomPopover>
          </FilterLeft>
          <Button
            variant="outlined"
            style={{ float: "right" }}
            onClick={() => clear_filters()}
          >
            Clear filters
          </Button>
        </Filter>
      )}
      {!user?.data?.fb_ad_account && !userLoading && (
        <NotLinkedContent
          modal={false}
          setLinkModal={setLinkModal}
          setAdAccountModal={setAdAccountModal}
        />
      )}
      {user?.data?.fb_ad_account && (
        <>
          <StatFlex>
            <StatCard
              loading={insightDataLoading}
              title="Clicks"
              img={clicks}
              val={insightsData?.data?.statistics?.clicks}
            />
            <StatCard
              loading={insightDataLoading}
              title="Impressions"
              img={impression}
              val={insightsData?.data?.statistics?.impressions}
            />
            <StatCard
              loading={insightDataLoading}
              title="CTR"
              img={ctr}
              percentage
              val={parseFloat(insightsData?.data?.statistics?.ctr).toFixed(1)}
            />
            <StatCard
              loading={insightDataLoading}
              title="Total Ad spends"
              img={spend}
              val={parseFloat(insightsData?.data?.statistics?.spend).toFixed(0)}
              currency={true}
            />
          </StatFlex>
          <StatFlex>
            <StatCard
              loading={insightDataLoading}
              title="CPM"
              img={cpm}
              val={parseFloat(insightsData?.data?.statistics?.cpm).toFixed(0)}
              currency={true}
            />
            <StatCard
              loading={insightDataLoading}
              title="Results"
              img={results}
              val={insightsData?.data?.statistics?.purchase}
            />
            <StatCard
              loading={insightDataLoading}
              title="Cost / Result"
              img={costs}
              val={
                isNaN(
                  Number(
                    parseFloat(insightsData?.data?.statistics?.costs).toFixed(0)
                  )
                )
                  ? "0"
                  : parseFloat(insightsData?.data?.statistics?.costs).toFixed(0)
              }
              currency={true}
            />
            <StatCard
              loading={insightDataLoading}
              title="ROAS"
              img={roas}
              val={
                roas === "0"
                  ? "-"
                  : parseFloat(insightsData?.data?.statistics?.roa).toFixed(1)
              }
            />
          </StatFlex>

          {/* <ChartFlex>
        <ChartCard width="25rem" bg={true} padding="2rem 1.4rem 1.5rem ">
          <CircularWrapper>
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              strokeWidth="12"
              styles={buildStyles({
                textColor: '#fff',
                pathColor: '#FFC700',
                trailColor: '#07875B',
              })}
            />
          </CircularWrapper>
          <CompleteDetail>
            <h2>Complete your profile..</h2>
            <p>
              Add the remaining social media ads account to view the result of
              your ads in one page.
            </p>
            <WhiteButton
              variant="contained"
              endIcon={<ArrowRightAltRoundedIcon />}
            >
              Complete it
            </WhiteButton>
          </CompleteDetail>
        </ChartCard>
        <ChartCard>
          <TopFlex>
            <img src={topPerforming} />
            <div>
              <p>Top performing campaign</p>
              <h3>Blueberry cakes sales</h3>
            </div>
          </TopFlex>
          <GraphWrapper>
            <Chart
              options={ageGenderdOptions}
              series={ageGenderSeries}
              type="area"
              // width={740}
              height={260}
            />
          </GraphWrapper>
          <BottomFlex>
            <BottomStat
              current={chart1Type === 'clicks'}
              onClick={() => setChart1Type('clicks')}
            >
              <h5>Clicks: </h5>
              <p> 4,567</p>
            </BottomStat>
            <BottomStat
              current={chart1Type === 'ctr'}
              onClick={() => setChart1Type('ctr')}
            >
              <h5>CTR: </h5>
              <p>4,567</p>
            </BottomStat>
            <BottomStat
              current={chart1Type === 'cpm'}
              onClick={() => setChart1Type('cpm')}
            >
              <h5>CPM: </h5>
              <p>2%</p>
            </BottomStat>
            <BottomStat>
              <h5>Cost: </h5>
              <p>$23,556</p>
            </BottomStat>
          </BottomFlex>
        </ChartCard>
        <ChartCard>
          <TopFlex>
            <img src={topSpending} />
            <div>
              <p>Top spending campaign</p>
              <h3>Chocolate Truffle cake</h3>
            </div>
          </TopFlex>
          <GraphWrapper>
            <Chart
              options={ageGenderdOptions}
              series={ageGenderSeries}
              type="area"
              // width={740}
              height={260}
            />
          </GraphWrapper>
          <BottomFlex>
            <BottomStat>
              <h5>Clicks: </h5>
              <p> 4,567</p>
            </BottomStat>
            <BottomStat>
              <h5>CV: </h5>
              <p>4,567</p>
            </BottomStat>
            <BottomStat>
              <h5>CVR: </h5>
              <p>2%</p>
            </BottomStat>
            <BottomStat>
              <h5>Cost: </h5>
              <p>$23,556</p>
            </BottomStat>
          </BottomFlex>
        </ChartCard>
      </ChartFlex> */}
          {rule?.data?.length && (
            <AutomationAlert
              loading={rule?.loading || automationCircularLoading}
            />
          )}
          <GraphFlex>
            <CampaignPerformance>
              <TitleFlex>
                {insightsData && !insightDataLoading && (
                  <>
                    <h3>ROAS comparision chart</h3>
                    {/* <p>
                      <sub>
                        {Math.round(
                          (endDate?.getTime() - startDate?.getTime()) /
                          (1000 * 3600 * 24)
                        )}
                        &nbsp; days
                      </sub>
                    </p> */}
                    <TextField
                      size="small"
                      defaultValue="New vs Repeats"
                      select
                    >
                      <MenuItem value="New vs Repeats">New vs Repeats</MenuItem>
                      <MenuItem value="By Ad Format">By Ad Format</MenuItem>
                      <MenuItem value="By Platform">By Platform</MenuItem>
                    </TextField>
                  </>
                )}

                {!insightsData ||
                  (insightDataLoading && (
                    <>
                      <Skeleton
                        variant="rounded"
                        width="20rem"
                        height="1.6rem"
                      />
                      <Skeleton variant="rounded" width="15rem" height="1rem" />
                    </>
                  ))}
              </TitleFlex>

              <GraphWrapper>
                {!insightsData ||
                  (insightDataLoading && (
                    <Skeleton style={{ marginTop: "-4rem" }} height="30rem" />
                  ))}
                {!insightDataLoading && (
                  <PurchasesCacChart
                    loading={insightDataLoading}
                    chartData={insightsData?.data?.chart_data}
                  />
                )}
              </GraphWrapper>
            </CampaignPerformance>
            <BudgetPieChart />
          </GraphFlex>
          <CampaignTable
            euid={String(user?.data?.euid)}
            ad_account={selectedAdAccount?.ad_account_id}
            date_start={startDate}
            date_stop={endDate}
            campaign_list={campaignListForTable}
            preset={preset}
            isComparison={isComparison}
          />
        </>
      )}
      <Modal open={adAccountModal} onClose={() => setAdAccountModal(false)}>
        <SelectAccountWrapper>
          <p>Your ad accounts</p>
          <Divider />

          <SearchInput>
            <TextField
              size="small"
              fullWidth
              placeholder="Search with ad account name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={adAccountSearchTerm}
              onChange={(e) => setAdAccountSearchTerm(e.target.value)}
            />
          </SearchInput>
          <AdAccountList fbadaccountLoading={fbadaccountLoading}>
            {fbadaccountLoading ? (
              <CircularProgress
                style={styles.circularProgressStyle}
                size={25}
              />
            ) : (
              adAccountSearchList?.map((item) => {
                return (
                  <>
                    <ListItem
                      onClick={() => {
                        setSelectedAdAccount(item);
                        setFbAccountName(item?.ad_account_name);
                      }}
                      key={item?.account_id}
                      selected={
                        selectedAdAccount.ad_account_id === item?.ad_account_id
                      }
                    >
                      <div>
                        <h4>{item?.ad_account_name}</h4>
                        <p>{item?.ad_account_id}</p>
                      </div>
                      {/* <Radio
                      value={item?.account_id}
                      checked={selectedAdAccount === item?.account_id}
                      onChange={(e) => {
                        setSelectedAdAccount(item?.account_id);
                        setFbAccountName(item?.name);
                      }}
                    /> */}
                      <Button
                        sx={{ paddingTop: "0.5rem", paddingBottom: "0.5rem" }}
                        startIcon={<DeleteIcon />}
                        size="small"
                        variant="outlined"
                        color="warning"
                      >
                        Remove
                      </Button>
                    </ListItem>
                    <Divider />
                  </>
                );
              })
            )}
          </AdAccountList>

          <SelectAdAccountBtns>
            <Button variant="outlined">Add more</Button>
            <Button
              startIcon={<CheckCircleIcon />}
              sx={{
                marginLeft: "0.6rem",
              }}
              onClick={() => handle_close_ad_account_modal()}
              variant="contained"
            >
              Done
            </Button>
          </SelectAdAccountBtns>
        </SelectAccountWrapper>
      </Modal>
      <Modal open={noInsightModal} onClose={() => setNoInsightModal(false)}>
        <NoDataWrapper>
          <img src={noDataImg} />
          <h3>No Insights Found!</h3>
          <Flex>
            <Button
              sx={{
                borderRadius: "0.6rem",
                textTransform: "none",
                fontFamily: "Montserrat, sans-serif",
              }}
              variant="contained"
              onClick={() => {
                setNoInsightModal(false);
                // setLinkModal(true);
                setAdAccountModal(true);
              }}
            >
              Change ad account
            </Button>
            <Button
              sx={{
                borderRadius: "0.6rem",
                textTransform: "none",
                fontFamily: "Montserrat, sans-serif",
              }}
              variant="contained"
              onClick={() => {
                setNoInsightModal(false);
              }}
            >
              Try with different filters
            </Button>
          </Flex>
        </NoDataWrapper>
      </Modal>
      <Drawer
        anchor="bottom"
        open={bottomDrawer}
        onClose={() => setBottomDrawer(false)}
      >
        <BottomDrawerContent>
          <h3>Filters</h3>

          <Autocomplete
            size="small"
            disableListWrap
            options={[{ id: "0", name: "All" }, ...allCampaigns]}
            componentsProps={{
              paper: {
                sx: {
                  width: "max-content",
                  minWidth: "100%",
                },
              },
            }}
            sx={{ flex: "1", marginRight: "0.6rem" }}
            value={currentCampaign}
            onChange={(e, newVal) => {
              setCurrentCampaign(newVal);
            }}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Campaign" />}
          />
          <TextField
            sx={{ flex: "1", marginRight: "0.6rem" }}
            size="small"
            select
            label="Objective"
            value="ALL"
            disabled
          >
            <MenuItem value="ALL">All</MenuItem>
          </TextField>

          {/* <p>Date Range</p>
          <TextField
            fullWidth
            sx={{ marginBottom: '1.5rem' }}
            autoComplete="off"
            onClick={(e) => {
              setDatePickerModal(true);
              setDateRangeAnchor(e.currentTarget);
            }}
            value={`${moment(startEndDate[0]?.startDate).format(
              'L'
            )} to ${moment(startEndDate[0]?.endDate).format('L')}`}
            variant="outlined"
            size="small"
          /> */}

          <Button variant="outlined" sx={{ margin: "2rem 0 1rem " }}>
            Apply Filters
          </Button>
        </BottomDrawerContent>
      </Drawer>
      {/* <Modal open={datePickerModal} onClose={() => setDatePickerModal(false)}>
        <DateRangePicker
          setStartDate={setStartDate}
          startDate={startDate}
          setEndDate={setEndDate}
          endDate={endDate}
          preset={preset}
          setPreset={setPreset}
          setPreset2={setPreset2}
          get_insights_with_filters={get_insights_with_filters}
        />

      </Modal> */}
      <Modal open={linkModal} onClose={() => setLinkModal(false)}>
        <NotLinkedContent
          modal={true}
          setLinkModal={setLinkModal}
          setAdAccountModal={setAdAccountModal}
        />
      </Modal>
      <AdFatigueModal
        fatigueModal={fatigueModal}
        setFatigueModal={setFatigueModal}
      />
    </Wrapper>
  );
};

export default Dashboard;

const styles = createStyles({
  circularProgressStyle: {
    color: "white",
  },
});
