import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UNIVERSAL from '../../config/config';

type InitialState = {
    data: any;
    loading: boolean;
    error: any;
};

type GetAdCopiesPayload = {
    adAccountId: string;
    after: string;
    before: string;
    euid: string;
}


const initialState: InitialState = {
    data: '',
    loading: false,
    error: '',
};

// const get_ad_copies = (adAccountId: string, euid: string, after: string, ads: any[]) => {
//     let allAds = ads;
//     return fetch(`${UNIVERSAL.BASEURL}/api/v1/facebook/ads`, {
//         method: "POST",
//         body: JSON.stringify({
//             // ad_acccount_id: data.adAccountId,
//             // after: "",
//             // before: "",
//             // euid: String(data.euid),
//             ad_acccount_id: "529924100719082",
//             after: after,
//             before: "",
//             euid: "57",
//         })
//     }).then(res => res.json())
//         .then(res => {
//             allAds = [...allAds, res.data.ads]
//             if (res.data.paging.after) {
//                 get_ad_copies(adAccountId, euid, res.data.paging.after, allAds)
//             } else {
//                 return allAds
//             }
//         })

//     return;
// }

export const getAllAdCopies = createAsyncThunk(
    '/adCopies/getAllAdCopies',
    (data: GetAdCopiesPayload, { rejectWithValue }) => {

        return fetch(`${UNIVERSAL.BASEURL}/api/v1/facebook/ads`, {
            method: 'POST',
            body: JSON.stringify({
                ad_acccount_id: data.adAccountId,
                after: data?.after,
                before: data?.before,
                euid: String(data.euid),
                // ad_acccount_id: "529924100719082",
                // after: data?.after,
                // before: data?.before,
                // euid: "57",
            }),
        })
            .then((response) => response.json())
            .then((res) => {
                if (!res.success) return rejectWithValue(res);
                if (res.success) return res;
            });

        return data;
    }
);


const getAdCopieSlice = createSlice({
    name: 'allAdCopies',
    initialState,
    reducers: {
        clearLiveAdCopies: (state) => {
            state.data = ""
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAllAdCopies.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getAllAdCopies.fulfilled, (state, action) => {
            state.loading = false;
            // let tmp = action.payload.data.ads
            // tmp = state?.data?.ads?.length ? [...state.data.ads, ...action.payload.data.ads] : action.payload.data.ads
            // state.data = {
            //     ...action.payload.data,
            //     ads: tmp.filter((item: any, i: number, a: any) => a.findLastIndex((v2: any) => (v2.ad_id === item.ad_id)) === i)
            // }
            // console.log({
            //     ...action.payload.data,
            //     ads: tmp.filter((item: any, i: number, a: any) => a.findLastIndex((v2: any) => (v2.ad_id === item.ad_id)) === i)
            // })
            let allAds: any[]

            if (state?.data?.paging?.after === action.payload.data.paging.after) {
                allAds = action.payload.data.ads;
            } else {
                allAds = state?.data?.ads?.length ? [...state.data.ads, ...action.payload.data.ads] : action.payload.data.ads
            }

            state.data = {
                ...action.payload.data,
                ads: allAds
            }
            state.error = '';
        });
        builder.addCase(getAllAdCopies.rejected, (state, action) => {
            state.loading = false;
            state.data = '';
            state.error = action?.payload;
        });
    },
});

export default getAdCopieSlice.reducer;
export const { clearLiveAdCopies } = getAdCopieSlice.actions;