import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UNIVERSAL from "../../config/config";

type InitialState = {
  data: any;
  loading: boolean;
  error: any;
};

type SignupWithEmailPayload = {
  name: string;
  email: string;
  password: string;
  mobile: string;
  website: string;
  countryDialCode: string;
};

type EmailLoginPayload = {
  email: string;
  password: string;
};

const initialState: InitialState = {
  data: "",
  loading: false,
  error: "",
};

export const loginWithGoogle = createAsyncThunk(
  "/authentication/loginWithGoogle",
  (token: string, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/auth/google/login`, {
      method: "POST",
      body: JSON.stringify({
        googleJWT: token,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res?.data?.is_user_exists === false) {
          return rejectWithValue(res);
        } else {
          localStorage.setItem(
            "ENTERPRISE_DASHBOARD_EMAIL",
            res.data.user.email
          );
          return res.data;
        }
      });
  }
);

export const signupWithGoogle = createAsyncThunk(
  "/authentication/signupWithGoogle",
  (token: string) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/auth/google/signup`, {
      method: "POST",
      body: JSON.stringify({
        googleJWT: token,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          // loginWithGoogle(token);
        }
      });
  }
);

export const signupWithEmail = createAsyncThunk(
  "/authentication/signupWithEmail",
  (data: SignupWithEmailPayload, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/auth/signup`, {
      method: "POST",
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        password: data.password,
        mobile: data.mobile,
        website: data.website,
        countryDialCode: data.countryDialCode,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (!res.success) return rejectWithValue(res);
      });
  }
);

export const emailLogin = createAsyncThunk(
  "/authentication/emailLogin",
  (credential: EmailLoginPayload, { rejectWithValue }) => {
    return fetch(`${UNIVERSAL.BASEURL}/api/v1/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        email: credential.email,
        password: credential.password,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.success) {
          localStorage.setItem("ENTERPRISE_DASHBOARD_EMAIL", credential.email);
          return res.data;
        } else {
          return rejectWithValue(res);
        }
      })
      .catch((e) => {
        return e;
      });
  }
);

const authenticationSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      state.data = "";
    },
    setAuthData: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginWithGoogle.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginWithGoogle.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      state.error = "";
    });
    builder.addCase(loginWithGoogle.rejected, (state, action) => {
      state.loading = false;
      state.data = "";

      state.error = action.error.message;
    });
    builder.addCase(emailLogin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(emailLogin.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(emailLogin.rejected, (state, action) => {
      state.loading = false;
      state.data = "";

      state.error = action.error.message;
    });
  },
});

export default authenticationSlice.reducer;
export const { logout, setAuthData } = authenticationSlice.actions;
