import styled from "styled-components";
import { TableCell, TableHead } from "@mui/material";

export const Wrapper = styled.div`
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
  padding: 0 1.5rem 1.5rem;
  margin-bottom: 1rem;
`;

export const TitleFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  color: #fff;
  border-bottom: 1px solid #efefef4d;
  /* padding-bottom: 1rem; */
  margin-bottom: 2rem;

  sub {
    color: #fff;
    font-weight: 700;
  }

  p {
    color: #fff;
    display: flex;
    align-items: center;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

export const CustomTableHead = styled(TableHead)``;

type CustomTableCellProps = {
  headCell?: boolean;
  firstCell?: boolean;
};

export const CustomTableCell = styled(TableCell)<CustomTableCellProps>`
  color: #fff !important;

  padding: ${(props) => (!props.headCell ? "1rem !important" : "0 !important")};
  text-align: ${(props) =>
    props.firstCell ? "left !important" : "center !important"};
  width: max-content !important;
  border: none !important;

  span {
    color: #ffffff99;
  }
`;

export const CustomTableBody = styled.div`
  margin-top: 1rem;
`;

type TableHeadLabelProps = {
  headLeftCornerCell?: boolean;
  headRightCornerCell?: boolean;
  headCell?: boolean;
};

export const TableHeadLabel = styled.div<TableHeadLabelProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.headRightCornerCell && "center"};
  width: max-content;
  margin: auto;
  background-color: rgba(27, 125, 255, 0.1);
  width: 100%;
  padding: 1rem;
  height: 3rem;
  /* margin-bottom: 1rem; */
  color: #ffffff99;
  /* text-align: center; */
  border-radius: ${(props) => props.headLeftCornerCell && " 0.8rem 0 0 0.8rem"};
  border-radius: ${(props) => props.headRightCornerCell && "0 0.8rem 0.8rem 0"};
  /* cursor: ${(props) => props.headCell && "pointer"}; */

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }
`;

export const SeverityFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* margin: auto; */
  width: max-content;
`;

export const Campaign = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  /* margin: auto; */
  width: max-content;

  img {
    width: 4rem;
    height: 4rem;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    border: 1px solid #fff;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    /* max-width: 16rem; */

    h3 {
      font-weight: 400;
      /* width: 15rem; */
      white-space: nowrap;
      text-overflow: ellipsis;
      flex-wrap: nowrap;
      cursor: pointer;
    }

    p {
      color: #ffffff99;
    }
  }
`;

export const Reason = styled.div`
  /* margin: auto; */
  width: max-content;
  max-width: 20rem;

  h3 {
    font-weight: 400;
  }

  p {
    color: #ffffff99;
    text-align: center;
  }
`;
