import React from "react";
import {
  Campaign,
  CustomTableCell,
  CustomTableHead,
  Reason,
  SeverityFlex,
  TableHeadLabel,
  TableWrapper,
  TitleFlex,
  Wrapper,
} from "./AutomationAlert.styles";
import {
  Button,
  Switch,
  Table,
  TableBody,
  TableRow,
  Tooltip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorIcon from "@mui/icons-material/Error";
import { GiArrowCursor } from "react-icons/gi";
import { Skeleton } from "@mui/material";
import { useAppSelector } from "src/hooks/useAppSelector";

const AutomationAlert = ({ loading }: AutomationAlertProps) => {
  const automationRules = useAppSelector(
    (state) => state.rules.automationResult
  );

  const get_rule_string = (str: string) => {
    const r: any = JSON.parse(str);
    console.log(r);

    let rule_string = "";

    r?.map((item: any, i: number) => {
      if (i === 0) {
        rule_string =
          rule_string +
          `${item.param} ${item.cop === "ht" ? "higher than" : "lower than"} ${
            item?.val
          }`;
      } else {
        rule_string =
          rule_string +
          ` ${item.lop} ${item.param} ${
            item.cop === "ht" ? "higher than" : "lower than"
          } ${item?.val}`;
      }
    });

    return rule_string;
  };

  return (
    <Wrapper>
      <TitleFlex>
        <h3>Automation alerts</h3>
      </TitleFlex>

      <TableWrapper>
        {!loading && !automationRules?.length && (
          <p style={{ color: "#fff" }}>No automation alert!</p>
        )}
        {loading && (
          <Skeleton
            sx={{ marginTop: "-4rem", width: "100%", height: "16rem" }}
          />
        )}
        {!loading && automationRules?.length !== 0 && (
          <Table sx={{ width: "100%", margin: "auto" }}>
            <CustomTableHead>
              <TableRow>
                <CustomTableCell headCell>
                  <TableHeadLabel headCell headLeftCornerCell>
                    <p>On / Off</p>
                  </TableHeadLabel>
                </CustomTableCell>
                <CustomTableCell headCell>
                  <TableHeadLabel headCell>
                    <p>Severity</p>
                  </TableHeadLabel>
                </CustomTableCell>
                <CustomTableCell headCell>
                  <TableHeadLabel headCell>
                    <p>Name</p>
                  </TableHeadLabel>
                </CustomTableCell>
                <CustomTableCell headCell align="center">
                  <TableHeadLabel headCell style={{ justifyContent: "center" }}>
                    <p>Reason</p>
                  </TableHeadLabel>
                </CustomTableCell>
                <CustomTableCell headCell>
                  <TableHeadLabel headCell headRightCornerCell>
                    <p>Action</p>
                  </TableHeadLabel>
                </CustomTableCell>
              </TableRow>
            </CustomTableHead>
            <TableBody
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {automationRules?.map((item: any, i: number) => {
                return (
                  <TableRow key={i}>
                    <CustomTableCell align="center">
                      <Switch />
                    </CustomTableCell>
                    <CustomTableCell align="left">
                      <SeverityFlex>
                        <CancelIcon sx={{ color: "#FC3939" }} />
                        <p>Trouble</p>
                      </SeverityFlex>
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      <Campaign>
                        {/* <img /> */}
                        <div>
                          <Tooltip title={item?.campaign_name}>
                            <h3>
                              {item?.campaign_name.substring(0, 30)}{" "}
                              {item?.campaign_name?.length > 30 ? "..." : ""}{" "}
                            </h3>
                          </Tooltip>
                          <p>Created on 14 july</p>
                        </div>
                      </Campaign>
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      <Reason>
                        <h3>{item?.rules_name}</h3>
                        <p>{get_rule_string(item?.rules)}</p>
                      </Reason>
                    </CustomTableCell>
                    <CustomTableCell align="center">
                      <Button startIcon={<GiArrowCursor />} variant="outlined">
                        Take action
                      </Button>
                    </CustomTableCell>
                  </TableRow>
                );
              })}

              {/* <TableRow >
                                <CustomTableCell align='center'  >
                                    <Switch />
                                </CustomTableCell>
                                <CustomTableCell align='center'>
                                    <SeverityFlex>
                                        <ErrorIcon sx={{ color: "#FF8514" }} />
                                        <p>Trouble</p>
                                    </SeverityFlex>

                                </CustomTableCell>
                                <CustomTableCell align='left'>
                                    <Campaign>
                                        <img />
                                        <div>
                                            <h3>Plum cake ad set</h3>
                                            <p>Created on 14 july</p>
                                        </div>
                                    </Campaign>
                                </CustomTableCell>
                                <CustomTableCell align='center'>
                                    <Reason>
                                        <h3>Lower Impression and Lower CTR</h3>
                                        <p>Impression - 3000 and CTR - 10%</p>
                                    </Reason>
                                </CustomTableCell>
                                <CustomTableCell align='center'>
                                    <Button startIcon={<GiArrowCursor />} variant="outlined" >Take action</Button>
                                </CustomTableCell>
                            </TableRow> */}
            </TableBody>
          </Table>
        )}
      </TableWrapper>
    </Wrapper>
  );
};

export default AutomationAlert;

type AutomationAlertProps = {
  loading: boolean;
};
