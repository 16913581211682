import styled from "styled-components";

export const Wrapper = styled.div`
  height: 7rem;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;

  img {
    margin-right: 0.7rem;
  }

  @media only screen and (max-width: 900px) {
    padding: 0.5rem;
    height: max-content;
    /* flex-grow: 0; */
  }
`;

export const StatData = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;

  span {
    color: #ffffff99;
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
  }
`;

export const StatDataFlex = styled.div`
  display: flex;
  align-items: flex-end;

  h4 {
    font-size: 1.5rem;
    margin: 0;
    /* line-height: 0; */
    display: flex;
    align-items: flex-end;
  }

  p {
    margin-left: 0.4rem;
    display: flex;
    align-items: center;
    color: #fc3f3f;
  }

  sub {
    font-size: 1.1rem;
    font-weight: 600;
    margin-right: 0.2rem;
  }
`;
