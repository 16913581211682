import { Box, TableCell, TableRow, Typography } from "@mui/material";

interface TableEmptyStateProps {
  columns: number;
}

export default function TableEmptyState({ columns }: TableEmptyStateProps) {
  return (
    <TableRow>
      <TableCell colSpan={columns}>
        <Box display="flex" alignItems="center" justifyContent="center" p={2}>
          <Typography textAlign="center">No Data Found</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
}
