import styled from "styled-components";

export const Wrapper = styled.div`
    width: 22rem;
    min-height: 35rem;
    border-radius: 1rem ;
    background-color: #fff;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

`

export const Top = styled.div`
display: flex;
padding: 0 1rem;

img {
    margin-right: 0.6rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

h4 {
    color: #0050BA;
    font-size: 1rem;
}

p {
    color: #1C1C1C80;
    display: flex;
    align-items: center;

    img {
        width: 0.8rem;
        height: 0.8rem;
        margin-left: 0.3rem;
    }
}
`

export const Caption = styled.p`
padding: 0 1rem;
    margin: 1rem 0;
    line-height: 1.5rem;
    font-size: 0.95rem;
`

export const Bottom = styled.div`
    img {
        border-radius: 0.6rem;
        height: 18rem;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
`

export const ImgWrapper = styled.div`
padding: 0 1rem;

img {
    aspect-ratio: 9/16;
    
}
`

export const Pay = styled.div`
    margin-top: 1rem;
    width: 100%;
    padding: 0.6rem 1.4rem ;
    background-color: #F1F2F4;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h4 {
        margin-bottom: 0.2rem;
    }

    p {
        font-size: 0.8rem;
        color: #00000080;
    }
`

export const FbBtn = styled.div`
    background-color: #E5F0FF;
    border-radius: 0.8rem;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 1rem auto 0; */
    cursor: pointer;
    flex: 1;

    img {
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0.6rem ;
    }

    p{
        color: #1075FF;
        text-decoration: none;
    }
`

export const BottomFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 0.5rem 0;
`

export const CustomToolTip = styled.div`
  font-size: 0.9rem;
  padding: 0.4rem 0.5rem;
`