import { useEffect, useState, useCallback, useRef } from "react";

// Define HubSpotWindow interface to extend Window
interface HubSpotWindow extends Window {
  hsConversationsOnReady?: (() => void)[];
  HubSpotConversations?: {
    on: (eventName: string, callback: (payload: any) => void) => void;
    off: (eventName: string, callback: (payload: any) => void) => void;
    widget: {
      open: () => void;
      close: () => void;
    };
  };
}

const useHubspotChat = (portalId: string) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [activeConversation, setActiveConversation] = useState<
    string | boolean
  >(false);
  const eventRef = useRef<(payload: any) => void | null>(() => null); // Initialize with a default value

  useEffect(() => {
    // Add event listener to hsConversationsOnReady
    if ((window as HubSpotWindow).hsConversationsOnReady) {
      (window as HubSpotWindow).hsConversationsOnReady!.push(() => {
        setHasLoaded(true);
      });
    } else {
      (window as HubSpotWindow).hsConversationsOnReady = [
        (): void => {
          setHasLoaded(true);
        },
      ];
    }

    // Create script element and append to body
    const script = document.createElement("script");
    script.src = `//js.hs-scripts.com/${portalId}.js`;
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove script and reset hsConversationsOnReady
    return () => {
      document.body.removeChild(script);
      if ((window as HubSpotWindow).hsConversationsOnReady) {
        (window as HubSpotWindow).hsConversationsOnReady = [];
      }
    };
  }, [portalId]);

  useEffect(() => {
    // Define event handler for conversationStarted event
    const handler = (payload: any): void => {
      setActiveConversation(payload.conversation.conversationId);
    };

    // Subscribe to conversationStarted event if hasLoaded is true and HubSpotConversations is defined
    if (hasLoaded && (window as HubSpotWindow).HubSpotConversations) {
      (window as HubSpotWindow).HubSpotConversations!.on(
        "conversationStarted",
        handler
      );
      eventRef.current = handler; // Update current value of eventRef
    }

    // Cleanup function to unsubscribe from conversationStarted event
    return () => {
      if (
        hasLoaded &&
        (window as HubSpotWindow).HubSpotConversations &&
        eventRef.current
      ) {
        (window as HubSpotWindow).HubSpotConversations!.off(
          "conversationStarted",
          eventRef.current
        );
      }
    };
  }, [hasLoaded]);

  const openHandler = useCallback(() => {
    // Open chat widget if hasLoaded is true and HubSpotConversations is defined
    if (hasLoaded && (window as HubSpotWindow).HubSpotConversations) {
      (window as HubSpotWindow).HubSpotConversations!.widget.open();
    }
  }, [hasLoaded]);

  const closeHandler = useCallback(() => {
    // Close chat widget if hasLoaded is true and HubSpotConversations is defined, and reset active conversation state
    if (hasLoaded && (window as HubSpotWindow).HubSpotConversations) {
      (window as HubSpotWindow).HubSpotConversations!.widget.close();
      setActiveConversation(false);
    }
  }, [hasLoaded]);

  return {
    hasLoaded,
    activeConversation,
    openHandler,
    closeHandler,
  };
};

export default useHubspotChat;
