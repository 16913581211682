import styled from "styled-components";
import Bg from "src/assets/dashboardBg.webp";
import { Popover } from "@mui/material";
import { motion } from "framer-motion";

export const Wrapper = styled.div`
  background-image: url(${Bg});
  background-size: cover;
  background-position: left bottom;
  min-height: 100vh;
  color: #fff;
  padding: 0 2rem 2rem;
`;

export const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.6rem 0rem;
  margin-bottom: 2.5rem;
  border-bottom: 1px solid #ffffff4d;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  color: #fff;

  img {
    margin-right: 0.8rem;
    width: 150px;
  }
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  img {
    margin-right: 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    /* border: 1px solid #fff; */
  }
`;

export const AvatarDetail = styled.div`
  p {
    color: #ffffff99;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: max-content;
  margin: auto;
`;

export const ConnectFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  width: 20rem;
`;

type ConnectIconWrapperProps = {
  inactive?: boolean;
};

export const ConnectIconWrapper = styled.div<ConnectIconWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 0.4rem;
    color: ${(props) => (props.inactive ? "#FFFFFF82" : "#fff")};
  }
`;

type ConnectIconProps = {
  inactive?: boolean;
};

export const ConnectIcon = styled.div<ConnectIconProps>`
  padding: 0.5rem;
  background-image: ${(props) =>
    !props.inactive &&
    "linear-gradient(270.06deg, #0066FF -0.37%, #0094FF 99.96%)"};
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 0.5rem;
  width: max-content;
  background-color: ${(props) => props.inactive && "rgba(12, 14, 38, 0.15)"};
  backdrop-filter: ${(props) => props.inactive && "blur(16px)"};
  position: relative;

  img {
    width: 2rem;
    object-fit: contain;
  }
`;

export const ConnectBar = styled.div`
  width: 10rem;
  height: 0.2rem;
  background-image: linear-gradient(270.06deg, #0066ff -0.37%, #0094ff 99.96%),
    linear-gradient(269.99deg, #0f6eff 5%, rgba(15, 110, 255, 0) 102.3%);
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(-50, -50%);
`;

export const CompanyDetailWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;

export const CompanyDetail = styled.div`
  width: 38rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
  padding: 2rem;
  margin-bottom: 1rem;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
  }

  p {
    color: #ffffff99;
  }
`;

export const InputLabel = styled.label`
  margin-bottom: 0.2rem;
`;

export const Input = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 3rem;
`;

export const LinkFb = styled.div`
  width: 38rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 20px;
  margin-bottom: 1rem;

  img {
    width: 100%;
  }
`;

export const LinkFbWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: max-content;
`;

export const LinkFbBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.5rem;
`;

export const LinkFbBottomLeft = styled.div`
  h3 {
    margin-bottom: 0.5rem;
  }
  p {
    color: #ffffff80;
    font-size: 0.9rem;
    font-weight: 300;
  }
`;

export const LinkFBPagebtn = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #4f5e8e;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;

  img {
    margin-right: 0.6rem;
    width: 2rem;
  }
`;

export const GetStartedWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
`;
export const GetStarted = styled.div`
  width: 38rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 20px;
  margin-bottom: 1rem;
  padding: 2rem;

  h3 {
    margin-bottom: 0.6rem;
  }
`;

export const AdAccountFlex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
`;

export const AdAccount = styled.div`
  display: flex;
  align-items: center;

  img {
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
  }
`;

export const AdAccountDetail = styled.div`
  h3 {
    font-weight: 500;
    margin-bottom: 0.3rem;
  }
  p {
    color: #ffffff80;
    font-size: 0.9rem;
  }
`;

export const SelectPageWrapper = styled.div`
  width: 35rem;
  /* height: 25rem; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #fff; */
  background: linear-gradient(97.29deg, #00258f 10.08%, #001963 99.77%), #052275;
  border-radius: 1rem;
  padding: 2rem 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  outline: none;
  color: #fff;

  p {
    margin-bottom: 0.6rem;
  }

  h2 {
    font-weight: 600;
    margin-top: 0.5rem;
  }
`;

export const SearchInput = styled.div`
  margin: 1rem 0;
  width: 100%;
`;

export const PageList = styled.ul`
  list-style: none;
  width: 100%;
  margin-top: 0.5rem;
  /* margin-bottom: 1rem; */
  max-height: 20rem;
  overflow-y: auto;

  &::-webkit-scrollbar {
    border-radius: 0.4rem;
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    background-color: darkgrey;
    /* outline: 1px solid slategrey; */
  }
`;

type ListItemProps = {
  selected?: boolean;
};

export const ListItem = styled.li<ListItemProps>`
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.selected && "rgba(27,125,255,0.2)"};
  cursor: pointer;
  border-radius: 0.3rem;

  h4 {
    font-weight: 400;
    font-size: 1rem;
  }

  p {
    color: #fff9;
    font-size: 0.9rem;
  }
`;

export const SelectAdAccountBtns = styled.div`
  display: flex;
  border-top: 1px dashed #fff9;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
`;

export const PageDetail = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 0.5rem;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
  }
`;
export const LinkSuccessFulModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 36rem;
  border-radius: 1rem;
  outline: none;
  overflow: hidden;
  background: linear-gradient(97.29deg, #00258f 10.08%, #001963 99.77%);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);

  img {
    width: 100%;
  }
`;

export const LinkSuccessFulModalBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  color: #fff;

  h3 {
    margin-bottom: 0.5rem;
  }
  p {
    color: #ffffff80;
    font-size: 0.9rem;
    font-weight: 300;
  }
`;

export const CustomPopover = styled(Popover)`
  height: 33rem;
  /* margin-top: 1rem ; */

  .MuiPopover-paper {
    border-radius: 1rem !important;
    overflow: hidden !important;
  }
`;

export const Dropdown = styled.div`
  min-width: 10rem;
  border-radius: 1rem;
  background-color: #012385;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
