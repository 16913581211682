import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import UNIVERSAL from "src/config/config";
import { ResponseSuccess } from "src/interfaces/api";

export type getBillingDetailsProps = {
  euid: number;
};

export type updateBillingDetailsProps = {
  city: string;
  euid: number;
  gst_number: string;
  state: string;
  streetAddress: string;
  zipcode: string;
  contact: string;
};

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: UNIVERSAL.BASEURL,
  }),
  tagTypes: ["user"],
  endpoints: (builder) => ({
    // eslint-disable-next-line
    getBillingDetails: builder.query<any, getBillingDetailsProps>({
      query: (body) => ({
        url: `/api/v1/user/get_billing_details`,
        method: "POST",
        body,
      }),
      providesTags: ["user"],
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    updateBillingDetails: builder.mutation<any, updateBillingDetailsProps>({
      query: (body) => ({
        url: `/api/v1/user/update_billing_details`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["user"],
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
  }),
});

export const { useGetBillingDetailsQuery, useUpdateBillingDetailsMutation } =
  userApi;
