import { Sort } from "../../../interfaces/state";
import { Stack, styled, TableCell, TableCellProps } from "@mui/material";
import {
  ArrowUp2 as ArrowUpIcon,
  ArrowDown2 as ArrowDownIcon,
} from "iconsax-react";

type TableHeaderCellProps<T> = TableCellProps & {
  sort?: Sort<T>;
  name?: keyof T;
  minWidth?: number;
  sortable?: boolean;
};

export default function TableHeaderCell<T = any>({
  sortable,
  sort,
  name,
  children,
  ...props
}: TableHeaderCellProps<T>) {
  if (sortable)
    return (
      <StyledTableCell role="button" tabIndex={0} {...props}>
        <Stack direction="row" alignItems="center">
          {children}
          <Stack ml={0.5} spacing={-0.5}>
            {(sort?.field !== name ||
              (sort?.field === name && sort?.type === "desc")) && (
              <ArrowUpIcon variant="Bold" size={12} />
            )}
            {(sort?.field !== name ||
              (sort?.field === name && sort?.type === "asc")) && (
              <ArrowDownIcon variant="Bold" size={12} />
            )}
          </Stack>
        </Stack>
      </StyledTableCell>
    );

  return (
    <StyledTableCell role="cell" {...props}>
      {children}
    </StyledTableCell>
  );
}

const StyledTableCell = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== "minWidth" && prop !== "sortable",
})<{ minWidth?: number; sortable?: boolean }>(({ minWidth, sortable }) => ({
  minWidth,
  fontSize: 12,
  borderTopWidth: 1,
  borderBottomWidth: 1,
  borderTopStyle: "solid",
  borderTopColor: "rgba(255, 255, 255, 0.2)",
  borderBottomStyle: "solid",
  borderBottomColor: "rgba(255, 255, 255, 0.2)",
  cursor: sortable ? "pointer" : "default",

  userSelect: "none",
  WebkitTouchCallout: "none",
  KhtmlUserSelect: "none",
  WebkitUserSelect: "none",
  MozUserSelect: "none",
  msUserSelect: "none",

  "&:first-of-type": {
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    borderLeftWidth: 1,
    borderLeftStyle: "solid",
    borderLeftColor: "rgba(255, 255, 255, 0.2)",
  },

  "&:last-of-type": {
    borderTopRightRadius: 12,
    borderBottomRightRadius: 12,
    borderRightWidth: 1,
    borderRightStyle: "solid",
    borderRightColor: "rgba(255, 255, 255, 0.2)",
  },
}));
