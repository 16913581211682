import { Skeleton, TableRow } from "@mui/material";
import { TableBodyCell } from "../../../components/Table/Cell";
import { Fragment } from "react";

interface TablePlaceholderStateProps {
  rows: number;
  columns: number;
}

export default function TablePlaceholderState({
  rows,
  columns,
}: TablePlaceholderStateProps) {
  return (
    <Fragment>
      {Array(rows)
        .fill(0)
        .map((_, index) => (
          <TableRow key={index}>
            {Array(columns)
              .fill(0)
              .map((_, index) => (
                <TableBodyCell key={index}>
                  <Skeleton />
                </TableBodyCell>
              ))}
          </TableRow>
        ))}
    </Fragment>
  );
}
