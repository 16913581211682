import styled from "styled-components";
import dashboardBg from "src/assets/dashboardBg.webp";
import { Box, Modal } from "@mui/material";

type OnModal = {
  modal: boolean;
};

export const Wrapper = styled.div<OnModal>`
  outline: none;
  display: flex;
  width: 100%;
  height: ${(props) => (props.modal ? "100vh" : "max-content")};
  overflow-y: auto;
  /* position: absolute;
    top: 7rem;
    left: 1rem; */
`;

export const LinkAdAccountModalContent = styled.div`
  width: 15rem;
  height: max-content;
  padding: 1.5rem;
  color: #000;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  text-align: center;
  margin-top: 7rem;
  left: 1rem;
  position: relative;

  img {
    margin-bottom: 2rem;
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: #0b1a3366;
    font-size: 0.9rem;
  }
`;

export const ArrowIcon = styled.img`
  position: absolute;
  top: 26rem;
  left: 9rem;
`;

export const Main = styled.div<OnModal>`
  flex: 1;
  margin-left: ${(props) => (props.modal ? "2rem" : "0")};
  /* background-color: #fff; */
  position: relative;
  margin-top: ${(props) => (props.modal ? "18.2rem" : "1rem")};
  margin-right: ${(props) => (props.modal ? "2.3rem" : "0")};
`;

export const Dialog = styled.div`
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(7px);
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  padding: 1rem 2rem;
  width: max-content;
  border-radius: 1rem;
  display: flex;
  align-items: center;

  p {
    margin-left: 0.5rem;
  }
`;

export const StatData = styled.div`
  width: 100%;
  margin-top: 5.3rem;
  padding: 0.5rem;
  border-radius: 1rem;
  margin-bottom: 1rem;
  /* height: 40rem; */
  background-image: url(${dashboardBg});
`;

export const StatFlex = styled.div`
  display: flex;
  margin-bottom: 1rem;

  & > *:not(:last-child) {
    margin-right: 0.7rem;

    @media only screen and (max-width: 900px) {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
    gap: 0.7rem;
    align-items: center;
    justify-content: center;
  }
`;

export const StatCard = styled.div`
  height: 7rem;
  border-radius: 1rem;
  flex: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  img {
    margin-right: 0.7rem;
  }
`;

// export const StatData = styled.div`
//   display: flex;
//   flex-direction: column;

//   span {
//     color: #00000066;
//     font-size: 0.8rem;
//     margin-bottom: 0.2rem;
//   }
// `;

export const StatDataFlex = styled.div`
  display: flex;
  align-items: flex-end;

  h4 {
    font-size: 1.5rem;
    margin: 0;
    /* line-height: 0; */
    display: flex;
    align-items: flex-end;
  }

  p {
    margin-left: 0.4rem;
    display: flex;
    align-items: center;
    color: #fc3f3f;
  }

  sub {
    font-size: 1rem;
    font-weight: 500;
    margin-right: 0.2rem;
  }
`;

export const CampaignPerformance = styled.div`
  background-color: #fff;
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
  background-image: url(${dashboardBg});

  @media only screen and (max-width: 900px) {
    padding: 0.5rem;
  }
`;

export const TitleFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #fff;
  border-bottom: 1px solid #efefef4d;
  padding-bottom: 1rem;
  margin-bottom: 2rem;

  sub {
    color: #fff;
    font-weight: 700;
  }

  p {
    color: #fff;
    display: flex;
    align-items: center;
  }
`;

export const GraphWrapper = styled.div`
  padding: 0 1rem 0rem;
  width: 100%;

  @media only screen and (max-width: 900px) {
    padding: 0;
  }
`;

export const SchedulerModal = styled(Modal)``;

export const SchedulerModalBox = styled(Box)`
  outline: none;
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  height: 250px;
  width: 533px;
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  color: #fff;
  border-radius: 15px;
  padding: 1.5rem;
`;

export const SchedulerModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SchedulerModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: 2rem;

  & > h4 {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
  }
`;

export const SchedulerRedirectionLink = styled.a`
  border-radius: 10px;
  color: #ffffff;
  background: linear-gradient(270deg, #0066ff 0%, #0094ff 100%);
  padding: 10px 22px;
  font-weight: 700;
  font-size: 16px;
  margin-top: 2rem;
  cursor: pointer;
  text-decoration: none;
`;
