import { motion } from "framer-motion";
import styled from "styled-components";

export const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  @media only screen and (max-width: 900px) {
    padding: 0.5rem;
  }
`;

export const TitleFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  color: #fff;
  border-bottom: 1px solid #efefef4d;
  padding-bottom: 0rem;
  margin-bottom: 2rem;

  sub {
    color: #fff;
    font-weight: 700;
  }

  p {
    color: #fff;
    display: flex;
    align-items: center;
  }
`;

export const AdsFlex = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  padding: 1rem 2rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
  overflow: hidden;
`;

export const Track = styled.div`
  /* width: max-content; */
  display: flex;
  height: max-content;
  gap: 1rem;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  & > * {
    flex-shrink: 0;
  }
`;

export const NoAdCopiesFound = styled.p`
  width: 100%;
  max-width: 100%;
  text-align: center;
  color: white;
  font-size: 16px;
`;
