import React, { useEffect, useState } from "react";
import {
  ExcludingGSTText,
  SliderContainer,
  SubscriptionContainer,
  SwitchContainer,
  SwitchContainerText1,
  SwitchContainerText2,
} from "./Subscription.styles";
import { Slider, Switch, styled } from "@mui/material";
import Save20Img from "../../assets/Save20.svg";
import SubscriptionCard from "src/components/SubscriptionCard/SubscriptionCard";
import { useAppSelector } from "src/hooks/useAppSelector";

type Props = {
  data: any;
  isLoading: boolean;
  isSuccess: boolean;
  activePlan: any;
  index: any;
  allAdAccount: any;
  setChangeAdAccountModal: any;
  setAdAccountPopper: any;
  setSelectedAdAccount: any;
  setCurrentAdAccount: any;
  setIsComparison: any;
  isSubsSuccess: boolean;
};

// const data = [
//   {
//     id: 3,
//     plan_id: "plan_NDmYqFGeg9ryV3",
//     duration: "Month",
//     amount: 8299,
//     tax: 1494,
//     total_amount: 9793,
//     price_range: "166000",
//   },
//   {
//     id: 4,
//     plan_id: "plan_NDmYqFGeg9ryV3",
//     duration: "Month",
//     amount: 16599,
//     tax: 1494,
//     total_amount: 9793,
//     price_range: "266000",
//   },
//   {
//     id: 5,
//     plan_id: "plan_NDmYqFGeg9ryV3",
//     duration: "Month",
//     amount: 24899,
//     tax: 1494,
//     total_amount: 9793,
//     price_range: "366000",
//   },
// ];

const Subscription = ({
  data,
  isLoading,
  isSuccess,
  activePlan,
  index,
  allAdAccount,
  setChangeAdAccountModal,
  setAdAccountPopper,
  setSelectedAdAccount,
  setCurrentAdAccount,
  setIsComparison,
  isSubsSuccess,
}: Props) => {
  const [sliderValue, setSliderValue] = useState(1);
  const [switcher, setSwitcher] = useState<boolean>(false);
  const [isNormalPlan, setIsNormalPlan] = useState<boolean>(true);
  const user = useAppSelector((state) => state.user.user);

  function valuetext(value: number) {
    return `${value}°C`;
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    if ((newValue as number) < 1) return;
    setSliderValue(newValue as number);
    if (data.length === newValue) {
      setIsNormalPlan(false);
    } else {
      setIsNormalPlan(true);
    }
  };

  let currData =
    !isLoading &&
    isSubsSuccess &&
    data?.find((currData: any) => currData.index + 1 === sliderValue);

  useEffect(() => {
    if (!activePlan) return;
    if (activePlan && new Date(activePlan?.expires_date) > new Date()) {
      setSliderValue(activePlan.amount > 0 ? index + 1 : 1);
    }
  }, [activePlan]);

  return (
    <SubscriptionContainer>
      <h4>Pick the right plan for your ad budget</h4>
      <h2>
        My Monthly Ad Spend Is Up To
        <span>
          {switcher ? "₹" : "$"}
          {!isLoading &&
            isSuccess &&
            isSubsSuccess &&
            (switcher
              ? String(
                  currData?.price_range.replace(/\,/g, "")
                ).toLocaleString()
              : String(
                  currData?.usd_price_range.replace(/\,/g, "")
                ).toLocaleString())}
        </span>
      </h2>

      <CustomSlider
        aria-label="Temperature"
        defaultValue={20}
        getAriaValueText={valuetext}
        // valueLabelDisplay="auto"
        value={sliderValue}
        onChange={handleChange}
        step={1}
        marks
        min={0}
        max={!isLoading && data.length}
      />

      <SwitchContainer>
        <SwitchContainerText1 isSelected={switcher}>USD</SwitchContainerText1>
        <AntSwitch
          checked={switcher}
          onClick={() => setSwitcher(!switcher)}
          inputProps={{ "aria-label": "ant design" }}
        />
        <SwitchContainerText2 isSelected={switcher}>INR</SwitchContainerText2>
        {/* <img src={Save20Img} alt="save-20%-img" /> */}
      </SwitchContainer>
      {data && (
        <SubscriptionCard
          isNormalPlan={isNormalPlan}
          switcher={switcher}
          allAdAccount={allAdAccount}
          setChangeAdAccountModal={setChangeAdAccountModal}
          setAdAccountPopper={setAdAccountPopper}
          setSelectedAdAccount={setSelectedAdAccount}
          setCurrentAdAccount={setCurrentAdAccount}
          setIsComparison={setIsComparison}
          data={
            !isLoading &&
            data.find((currData: any) => currData.index + 1 === sliderValue)
          }
          maxPlan={
            !isLoading &&
            data.find((currData: any) => currData.index + 1 === data.length)
          }
          activePlan={activePlan}
        />
      )}
      <ExcludingGSTText>*Excluding GST</ExcludingGSTText>
    </SubscriptionContainer>
  );
};

export default Subscription;

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const CustomSlider = styled(Slider)(({ theme }) => ({
  marginTop: "2rem",
  width: "564px",
  height: "12px",
  "& .MuiSlider-thumb": {
    backgroundColor: "rgba(255, 168, 0, 1)",
    width: "20px",
    height: "20px",
    border: "2px solid white",
  },
  "& .MuiSlider-rail": {
    backgroundColor: "rgba(39, 63, 130, 1)",
  },
  "& .MuiSlider-track": {
    background:
      "linear-gradient(to left, rgba(1, 147, 255, 1), rgba(1, 104, 255, 1))",
    border: "none",
  },
}));
