import styled from "styled-components";

export const ReAuthBtn = styled.button<{ disableBtn?: boolean }>`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to right,
    rgba(0, 82, 241, 1),
    rgba(0, 117, 255, 1)
  );
  height: 53px;
  width: 300px;
  border-radius: 12px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  outline: none;
  gap: 0.5rem;
  padding: 0 10px;
  opacity: ${(props) => (props.disableBtn ? "60%" : "100%")};
`;

export const ReAuthLoadingContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8) 1%, black 99%);
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;
