import styled from "styled-components";

export const Wrapper = styled.div`
display: flex;
width: 100%;
height: 100vh;
justify-content: center;
align-items: center;
flex-direction: column;

h2 {
    margin-bottom: 0.5rem;
}
`