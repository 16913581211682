import { Box, Modal } from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";

export const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-top: 1rem;

  @media only screen and (max-width: 900px) {
    padding: 0.5rem;
  }
`;

export const TitleFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 4rem;
  color: #fff;
  border-bottom: 1px solid #efefef4d;
  padding-bottom: 0rem;
  margin-bottom: 2rem;

  sub {
    color: #fff;
    font-weight: 700;
  }

  p {
    color: #fff;
    display: flex;
    align-items: center;
  }
`;

export const WalletBalance = styled.div`
  width: 100%;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  color: #fff;

  p {
    color: #ffffff80;
  }
`;

export const WalletBalanceLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const Transaction = styled.div`
  width: 50%;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
  color: #fff;

  h3 {
    margin-bottom: 0.8rem;
  }
`;

export const TransactionTabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ffffff1a;
  position: relative;
  margin-top: 0.5rem;
`;

type TabProps = {
  active?: boolean;
};

export const Tab = styled.div<TabProps>`
  flex: 1;
  cursor: pointer;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.6rem;
  transition: all 0.1s ease-in-out;
  color: ${(props) => (props.active ? "#fff" : "#FFFFFF99")};

  &:hover {
    background-color: #002284;
  }
`;
type TabIndicatorProps = {
  left: string;
};

export const TabIndicator = styled.div<TabIndicatorProps>`
  position: absolute;
  bottom: 0;
  left: ${(props) => props.left};
  width: 33.33%;
  height: 2px;
  background: linear-gradient(270.06deg, #0066ff -0.37%, #0094ff 99.96%);
  transition: all 0.2s ease-in-out;
`;

export const TransactionList = styled.ul`
  list-style: none;
  margin: 0;
  max-height: 30rem;
  overflow-y: auto;
  margin-top: 0.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background-color: darkgrey;
    /* outline: 1px solid slategrey; */
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 0.5rem;
    border-radius: 0.4rem;

    p {
      margin-bottom: 0.2rem;
    }

    span {
      font-size: 0.9rem;
      color: #ffffff66;
    }

    &:hover {
      background-color: #002284;
    }
  }
`;

export const Status = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-end;

  div {
    display: flex;
    align-items: flex-end;
    height: max-content;
    gap: 0.4rem;

    h3 {
      font-weight: 400;
    }

    & > * {
      margin: 0 !important;
    }

    span {
      color: #fff;
      font-weight: 400;
      font-size: 0.8rem;
    }
  }
`;

export const AddMoney = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

export const WalletContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 0 0 2rem 0;

  > img {
    cursor: pointer;
  }
  > h4 {
    font-size: 24px;
    font-weight: 700;
    color: white;
  }
`;

export const AdAccountContainer = styled.div`
  > h2 {
    font-size: 24px;
    font-weight: 400;
    color: white;
    line-height: 20px;
  }
  > p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    line-height: 32px;
  }
`;

export const SelectAdAccountContainer = styled.div`
  position: relative;
  background: linear-gradient(
    to right,
    rgb(17 50 148 / 0%),
    rgb(0 28 108 / 0%)
  );
  color: #000;
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  margin-top: 2rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    border: 1px solid transparent;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.15)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }

  > h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    color: white;
  }

  > hr {
    border: 0;
    margin: 0.7rem 0 2rem 0;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
`;

export const SubsExp = styled(Modal)``;

export const SubsExpBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  width: 533px;
  height: 243px;
  border: 1px solid rgba(15, 110, 255, 1);
  background: linear-gradient(
    to right,
    rgba(17, 50, 148, 1),
    rgba(0, 28, 108, 1)
  );
  color: #000;
  border-radius: 15px;
  padding: 1.5rem;
`;

export const SubsExpBoxFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  > h2 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: rgba(255, 255, 255, 1);
  }

  > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 0.5);
  }

  > a {
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      to right,
      rgba(0, 82, 241, 1),
      rgba(0, 117, 255, 1)
    );
    height: 53px;
    width: 190px;
    border-radius: 12px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    outline: none;
    gap: 0.2rem;
    margin-top: 1rem;
  }
`;
