import { Wrapper, StatData, StatDataFlex } from "./StatCard.styles";
import { Skeleton } from "@mui/material";

import { StatCardProps } from "./types";
import { useAppSelector } from "src/hooks/useAppSelector";
import { fetchSymbolFromCurrency } from "src/libs/utils";

const StatCard = ({
  loading,
  img,
  title,
  val,
  sub,
  currency,
  percentage,
}: StatCardProps) => {
  const user = useAppSelector((state: any) => state.user);
  return (
    <Wrapper>
      {loading ? (
        <Skeleton
          width="3.6rem"
          height="5.8rem"
          style={{ marginRight: "0.8rem" }}
        />
      ) : (
        <img src={img} />
      )}
      <StatData>
        {loading ? (
          <Skeleton width="4rem" height="1.3rem" />
        ) : (
          <span>{title}</span>
        )}
        {loading ? (
          <Skeleton variant="rounded" width="7rem" height="2.5rem" />
        ) : (
          <StatDataFlex>
            {currency && (
              <h4>
                <sub>
                  {user?.user?.data?.currency
                    ? fetchSymbolFromCurrency(user?.user?.data?.currency)
                    : "₹"}
                </sub>

                {/* {isNaN(val) ? 0 : val} */}
              </h4>
            )}
            <h4>
              {" "}
              {isNaN(Number(val))
                ? "0"
                : percentage
                ? `${val?.toLocaleString()} % `
                : val.toLocaleString()}
            </h4>
            {/* <p>
          {sub?.status === 'up' ? (
            <ArrowDropUpIcon
              sx={{ fontSize: '1rem', color: 'green' }}
            />
          ) : (
            <ArrowDropDownIcon
              sx={{ fontSize: '1rem', color: 'red' }}
            />
          )}
          {parseFloat(sub?.val).toFixed(1)} %
        </p> */}
          </StatDataFlex>
        )}
      </StatData>
    </Wrapper>
  );
};

export default StatCard;
