import React, { useEffect, useState } from 'react';
import { addDays, sub, getDaysInMonth, startOfMonth } from 'date-fns';
import { TextField, Popover, Divider, Button, Alert, IconButton, Switch } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import { DateRange, Calendar } from 'react-date-range';
import { DateRange } from 'src/utils/dateRangePicker';
import moment from 'moment/moment';
import {
  DateRangeWrapper,
  CustomMenuItem,
  HorizontalCalendarWrapper,
  Flex,
  ButtonContainer,
  TopbarFlex,
  Left,
  Middle,
  Right,
  DateInputFlex,
  BtnFlex,
  ListIndicator,
} from './DateRangePicker.styles';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { DateRangePickerProps } from './types';
import {
  useTheme,
  styled,
  createTheme,
  ThemeProvider,
} from '@mui/material/styles';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { clearPrevAfter, clearStoreStartEnd, setAfterDate, setComparison, setPrevDate, setStoreEndDate, setStoreStartDate } from 'src/slices/allcampaignInsight/allCamapignInsightSlice';
import add from 'date-fns/add';
import { useAppSelector } from 'src/hooks/useAppSelector';

const theme = createTheme({
  palette: {
    mode: 'light',
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
  },
});

const DateRangePicker = ({
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  get_insights_with_filters,
  setPreset,
  setPreset2,
  preset,
  isComparison,
  setIsComparison,
  get_prev_insight_data,
  get_after_insight_data,
  datePickerPopper,
  setDatePickerPopper,
  get_all_campaign_insight_data
}: DateRangePickerProps) => {
  const [dateRangeAnchor, setDateRangeAnchor] = useState<any>(null);
  const [dateRange, setDateRange] = useState<any>([
    {
      startDate: startDate ? startDate : new Date(),
      endDate: endDate ? endDate : new Date(),
      key: 'selection'
    }
  ]);
  const [isCustomCalendar, setIsCustomCalendar] = useState(false);
  const [dateRangeError, setDateRangeError] = useState<string | boolean>(false);
  const [selectedPreset, setSelectedPreset] = useState("");
  const [prevStartDateInput, setPrevStartDateInput] = useState<any>("");
  const [prevEndDateInput, setPrevEndDateInput] = useState<any>("");
  const [afterStartDateInput, setAfterStartDateInput] = useState<any>("");
  const [afterEndDateInput, setAfterEndDateInput] = useState<any>("");
  const [isInputDate, setIsInputDate] = useState(false);
  const [invalidDateAlert, setInvalidDateAlert] = useState("");
  const [isInvalidDateAlert, setIsInValidDateAlert] = useState(false);

  const [dateRangeSelection, setDateRangeSelection] = useState({
    selection1: {
      startDate: addDays(new Date(), -6),
      endDate: new Date(),
      key: 'selection1'
    },
    selection2: {
      startDate: addDays(new Date(), 1),
      endDate: addDays(new Date(), 7),
      key: 'selection2'
    }
  })

  const { prevDate, afterDate } = useAppSelector(state => state.allCampaignInsight)

  useEffect(() => {
    if (prevDate && afterDate) {
      setDateRangeSelection({
        selection1: {
          startDate: prevDate.startDate,
          endDate: prevDate.endDate,
          key: 'selection1'
        },
        selection2: {
          startDate: afterDate.startDate,
          endDate: afterDate.endDate,
          key: 'selection2'
        }
      })
    }
  }, [prevDate, afterDate])

  useEffect(() => {
    setPrevDate(dateRangeSelection.selection1.startDate)
  }, [dateRangeSelection])

  const dispatch = useAppDispatch();
  const { comparison } = useAppSelector(state => state.allCampaignInsight)

  useEffect(() => {
    console.log(dateRangeSelection)
  }, [dateRangeSelection])

  useEffect(() => {
    if (isCustomCalendar === true) {
      console.log("ooooo")
      setPreset({ val: "", string: "" });
      setPreset2({ val: "", string: "" });
    }
  }, [startDate, endDate, isCustomCalendar]);

  useEffect(() => {
    setIsInputDate(false);
    setInvalidDateAlert("");
  }, [preset])

  useEffect(() => {
    if (preset.val !== "") {
      if (!isComparison) {
        if (preset.val === "today") {
          setDateRange([
            {
              startDate: new Date(),
              endDate: new Date(),
              key: 'selection'
            }
          ])
        } else if (preset.val === "yesterday") {
          setDateRange([
            {
              startDate: sub(new Date(), { days: 1 }),
              endDate: sub(new Date(), { days: 1 }),
              key: 'selection'
            }
          ])
        } else if (preset.val === "last_7d") {
          setDateRange([
            {
              startDate: sub(new Date(), { days: 7 }),
              endDate: sub(new Date(), { days: 1 }),
              key: 'selection'
            }
          ])
        } else if (preset.val === "last_14d") {
          setDateRange([
            {
              startDate: sub(new Date(), { days: 14 }),
              endDate: sub(new Date(), { days: 1 }),
              key: 'selection'
            }
          ])
        } else if (preset.val === "this_month") {
          setDateRange([
            {
              startDate: startOfMonth(new Date()),
              endDate: new Date(),
              key: 'selection'
            }
          ])
        } else if (preset.val === "last_month") {
          setDateRange([
            {
              startDate: sub(new Date(), { months: 2 }),
              endDate: sub(new Date(), { months: 1 }),
              key: 'selection'
            }
          ])
        } else if (preset.val === "last_30d") {
          setDateRange([
            {
              startDate: sub(new Date(), { days: 30 }),
              endDate: sub(new Date(), { days: 1 }),
              key: 'selection'
            }
          ])
        }
      } else if (isComparison) {
        if (preset.val === "today") {
          setDateRangeSelection({
            selection1: {
              startDate: sub(new Date(), { days: 1 }),
              endDate: sub(new Date(), { days: 1 }),
              key: 'selection1'
            },
            selection2: {
              startDate: new Date(),
              endDate: new Date(),
              key: 'selection2'
            }
          })
        } else if (preset.val === "yesterday") {
          setDateRangeSelection({
            selection1: {
              startDate: sub(new Date(), { days: 2 }),
              endDate: sub(new Date(), { days: 2 }),
              key: 'selection1'
            },
            selection2: {
              startDate: sub(new Date(), { days: 1 }),
              endDate: sub(new Date(), { days: 1 }),
              key: 'selection2'
            }
          })
        } else if (preset.val === "last_7d") {
          setDateRangeSelection({
            selection1: {
              startDate: sub(new Date(), { days: 14 }),
              endDate: sub(new Date(), { days: 8 }),
              key: 'selection1'
            },
            selection2: {
              startDate: sub(new Date(), { days: 7 }),
              endDate: sub(new Date(), { days: 1 }),
              key: 'selection2'
            }
          })
        } else if (preset.val === "last_14d") {
          setDateRangeSelection({
            selection1: {
              startDate: sub(new Date(), { days: 28 }),
              endDate: sub(new Date(), { days: 15 }),
              key: 'selection1'
            },
            selection2: {
              startDate: sub(new Date(), { days: 14 }),
              endDate: sub(new Date(), { days: 1 }),
              key: 'selection2'
            }
          })
        } else if (preset.val === "this_month") {
          setDateRangeSelection({
            selection1: {
              startDate: startOfMonth(sub(new Date(), { months: 1 })),
              endDate: sub(startOfMonth(new Date()), { days: 1 }),
              key: 'selection1'
            },
            selection2: {
              startDate: startOfMonth(new Date()),
              endDate: new Date(),
              key: 'selection2'
            }
          })
        } else if (preset.val === "last_month") {
          setDateRange([
            {
              startDate: sub(new Date(), { months: 2 }),
              endDate: sub(new Date(), { months: 1 }),
              key: 'selection'
            }
          ])
        } else if (preset.val === "last_30d") {
          setDateRangeSelection({
            selection1: {
              startDate: sub(new Date(), { days: 30 * 2 }),
              endDate: sub(new Date(), { days: 31 }),
              key: 'selection1'
            },
            selection2: {
              startDate: sub(new Date(), { days: 30 }),
              endDate: sub(new Date(), { days: 1 }),
              key: 'selection2'
            }
          })
        }
      }
    }
  }, [preset, isComparison])



  useEffect(() => {
    setStartDate(dateRange[0].startDate ? new Date(dateRange[0].startDate) : new Date())
    setEndDate(dateRange[0].endDate ? new Date(dateRange[0].endDate) : new Date())
  }, [dateRange[0].startDate, dateRange[0].endDate])

  useEffect(() => {
    console.log(startDate, endDate, "DATES")
  }, [startDate, endDate])

  // useEffect(() => {
  //   if (isCustomCalendar && isComparison) {
  //     get_compare_data()
  //   }
  // }, [dateRangeSelection])

  const get_compare_data = () => {
    const prevStart = dateRangeSelection.selection1.startDate;
    const prevEnd = dateRangeSelection.selection1.endDate;
    get_prev_insight_data(prevStart, prevEnd);
    dispatch(setPrevDate({
      startDate: prevStart,
      endDate: prevEnd
    }))


    const afterStart = dateRangeSelection.selection2.startDate;
    const afterEnd = dateRangeSelection.selection2.endDate;
    get_after_insight_data(afterStart, afterEnd)

    dispatch(setAfterDate({
      startDate: afterStart,
      endDate: afterEnd
    }))

    dispatch(setComparison(true))
    dispatch(clearStoreStartEnd())
  }


  const get_date_string = (preset: string) => {
    const today = new Date();
    if (preset === 'today') {
      return moment(today).format('Do MMM YY');
    } else if (preset === 'yesterday') {
      const yesterday = new Date(new Date().setDate(today.getDate() - 1));
      return moment(yesterday).format('Do MMM YY');
    } else if (preset === 'last_7d') {
      const last_7d = new Date(new Date().setDate(today.getDate() - 7));
      return `${moment(last_7d).format('Do MMM YY')} - ${moment(today).format(
        'Do MMM YY'
      )}`;
    } else if (preset === 'last_14d') {
      const last_14d = new Date(new Date().setDate(today.getDate() - 14));
      return `${moment(last_14d).format('Do MMM YY')} - ${moment(today).format(
        'Do MMM YY'
      )}`;
    } else if (preset === 'last_30d') {
      const last_30d = new Date(new Date().setDate(today.getDate() - 30));
      return `${moment(last_30d).format('Do MMM YY')} - ${moment(today).format(
        'Do MMM YY'
      )}`;
    } else if (preset === 'this_month') {
      return moment(today).format('MMMM YY');
    } else if (preset === 'last_month') {
      const lat_month = new Date(new Date().setDate(today.getMonth() - 1));
      return moment(lat_month).format('MMMM YY');
    }
  };

  useEffect(() => {
    console.log(preset)
  }, [preset])

  useEffect(() => {
    if (isCustomCalendar) {
      if (isComparison) {
        setPrevStartDateInput(moment(dateRangeSelection.selection1.startDate).format("DD/MM/YYYY"))
        setPrevEndDateInput(moment(dateRangeSelection.selection1.endDate).format("DD/MM/YYYY"))
        setAfterStartDateInput(moment(dateRangeSelection.selection2.startDate).format("DD/MM/YYYY"))
        setAfterEndDateInput(moment(dateRangeSelection.selection2.endDate).format("DD/MM/YYYY"))
      } else {
        setPrevStartDateInput(moment(dateRange[0].startDate).format("DD/MM/YYYY"))
        setPrevEndDateInput(moment(dateRange[0].endDate).format("DD/MM/YYYY"))

      }
    }

  }, [isCustomCalendar])

  useEffect(() => {
    console.log(prevStartDateInput, "PREV START DATE INPUT")
  }, [prevStartDateInput])

  const handle_date_change = (e: any, dateType: string) => {
    setInvalidDateAlert("")
    setIsInputDate(true);
    setIsCustomCalendar(true);

    if (dateType === "prevStart") {
      setPrevStartDateInput(e.target.value)
      console.log("hello")
      if (e.target.value.length === 10) {
        const date_component = e.target.value.split("/");

        if (date_component[1] > 12) {
          return setInvalidDateAlert("prevStart")
        } else if (date_component[0] > getDaysInMonth(new Date(date_component[2], date_component[1] - 1))) {
          return setInvalidDateAlert("prevStart")
        } else {
          if (isComparison) {
            setDateRangeSelection({
              selection1: {
                ...dateRangeSelection.selection1,
                startDate: new Date(date_component[2], date_component[1] - 1, date_component[0])
              },
              selection2: dateRangeSelection.selection2,

            })
          } else {
            setDateRange(
              [
                {
                  ...dateRange[0],
                  startDate: new Date(date_component[2], date_component[1] - 1, date_component[0])
                }
              ]
            )
          }
        }
      } else {
        return setInvalidDateAlert("prevStart")
      }
    } else if (dateType === "prevEnd") {
      setPrevEndDateInput(e.target.value)
      if (e.target.value.length === 10) {
        const date_component = e.target.value.split("/");

        if (date_component[1] > 12) {
          return setInvalidDateAlert("prevEnd")
        } else if (date_component[0] > getDaysInMonth(new Date(date_component[2], date_component[1] - 1))) {
          return setInvalidDateAlert("prevEnd")
        } else {
          if (isComparison) {
            setDateRangeSelection({
              selection1: {
                ...dateRangeSelection.selection1,
                endDate: new Date(date_component[2], date_component[1] - 1, date_component[0])
              },
              selection2: dateRangeSelection.selection2,

            })
          } else {
            setDateRange(
              [
                {
                  ...dateRange[0],
                  endDate: new Date(date_component[2], date_component[1] - 1, date_component[0])
                }
              ]
            )
          }
        }
      } else {
        return setInvalidDateAlert("prevEnd")
      }
    } else if (dateType === "afterStart") {
      setAfterStartDateInput(e.target.value)
      if (e.target.value.length === 10) {
        const date_component = e.target.value.split("/");

        if (date_component[1] > 12) {
          return setInvalidDateAlert("afterStart")
        } else if (date_component[0] > getDaysInMonth(new Date(date_component[2], date_component[1] - 1))) {
          return setInvalidDateAlert("afterStart")
        } else {
          if (isComparison) {
            setDateRangeSelection({
              selection1: dateRangeSelection.selection1,
              selection2: {
                ...dateRangeSelection.selection2,
                startDate: new Date(date_component[2], date_component[1] - 1, date_component[0])
              },
            })
          }
        }
      } else {
        return setInvalidDateAlert("afterStart")
      }
    } else if (dateType === "afterEnd") {
      setAfterEndDateInput(e.target.value)
      if (e.target.value.length === 10) {
        const date_component = e.target.value.split("/");

        if (date_component[1] > 12) {
          return setInvalidDateAlert("afterEnd")
        } else if (date_component[0] > getDaysInMonth(new Date(date_component[2], date_component[1] - 1))) {
          return setInvalidDateAlert("afterEnd")
        } else {
          if (isComparison) {
            setDateRangeSelection({
              selection1: dateRangeSelection.selection1,
              selection2: {
                ...dateRangeSelection.selection2,
                endDate: new Date(date_component[2], date_component[1] - 1, date_component[0])
              },
            })
          }
        }
      } else {
        return setInvalidDateAlert("afterEnd")
      }
    }


  }

  useEffect(() => {
    setIsComparison(comparison)
  }, [comparison])

  useEffect(() => {
    if (!invalidDateAlert) {
      setIsInValidDateAlert(false)
    }
  }, [invalidDateAlert])

  return (
    <DateRangeWrapper>
      <Left>
        <ul>
          <ListIndicator top={preset.val === "" ? "0rem"
            : preset.val === "today" ? "2.5rem"
              : preset.val === "yesterday" ? "5rem"
                : preset.val === "last_7d" ? "7.5rem"
                  : preset.val === "last_14d" ? "10rem"
                    : preset.val === "this_month" ? "12.5rem"
                      : preset.val === "last_30d" ? "15rem"
                        : "17.5rem"
          } />
          <li onClick={() => setIsCustomCalendar(true)} >Custom</li>
          <li onClick={() => { setPreset({ val: 'today', string: 'Today' }); setIsCustomCalendar(false) }}>Today</li>
          <li onClick={() => { setPreset({ val: 'yesterday', string: 'Yesterday' }); setIsCustomCalendar(false) }}>Yesterday</li>
          <li onClick={() => { setPreset({ val: 'last_7d', string: 'Last 7 days' }); setIsCustomCalendar(false) }}>Last 7 days</li>
          <li onClick={() => { setPreset({ val: 'last_14d', string: 'Last 14 days' }); setIsCustomCalendar(false) }}>Last 14 days</li>
          <li onClick={() => { setPreset({ val: 'this_month', string: 'This month' }); setIsCustomCalendar(false) }}>This month</li>
          <li onClick={() => { setPreset({ val: 'last_30d', string: 'Last 30 days' }); setIsCustomCalendar(false) }}>Last 30 days</li>
          {/* <li>Previous month</li> */}
        </ul>
      </Left>
      <Middle>
        {isComparison &&
          <p>Previous</p>}
        <DateInputFlex>
          <TextField
            value={isInputDate ? prevStartDateInput
              : isComparison ? moment(dateRangeSelection.selection1.startDate).format("DD/MM/YYYY")
                : moment(dateRange[0].startDate).format("DD/MM/YYYY")
            }
            size='small'
            label="From"
            error={invalidDateAlert === "prevStart"}
            // helperText={invalidDateAlert === "prevStart" ? "Invalid date" : ""}
            onChange={(e) => { handle_date_change(e, "prevStart") }}
          />
          <span>-</span>
          <TextField
            value={isInputDate ? prevEndDateInput
              : isComparison ? moment(dateRangeSelection.selection1.endDate).format("DD/MM/YYYY")
                : moment(dateRange[0].endDate).format("DD/MM/YYYY")}
            size='small'
            label="To"
            error={invalidDateAlert === "prevEnd"}
            onChange={(e) => { handle_date_change(e, "prevEnd") }}
          />
        </DateInputFlex>
        <DateInputFlex>
          <Switch checked={isComparison} onChange={(e) => setIsComparison(e.target.checked)} />
          <p>Compare with</p>
        </DateInputFlex>
        {isComparison &&
          <>
            <p>After</p>
            <DateInputFlex>
              <TextField
                value={isInputDate ? afterStartDateInput
                  : moment(dateRangeSelection.selection2.startDate).format("DD/MM/YYYY")}
                size='small'
                label="From"
                error={invalidDateAlert === "afterStart"}
                onChange={(e) => { handle_date_change(e, "afterStart") }}
              />
              <span>-</span>
              <TextField
                value={isInputDate ? afterEndDateInput : moment(dateRangeSelection.selection2.endDate).format("DD/MM/YYYY")}
                size='small'
                label="To"
                error={invalidDateAlert === "afterEnd"}
                onChange={(e) => { handle_date_change(e, "afterEnd") }}
              />
            </DateInputFlex>
          </>
        }
        {isInvalidDateAlert && <Alert severity='warning'>Please provide valid date </Alert>}
      </Middle>
      <Right>
        {isComparison ?
          <DateRange
            onChange={(item: any) => { setIsCustomCalendar(true); setDateRangeSelection({ ...dateRangeSelection, ...item }); }}
            moveRangeOnFirstSelection={false}
            // dragSelectionEnabled={false}
            ranges={[dateRangeSelection.selection1, dateRangeSelection.selection2]}
          />
          :
          <DateRange
            onChange={(item: any) => { setIsCustomCalendar(true); setDateRange([item.selection]) }}
            moveRangeOnFirstSelection={false}
            ranges={dateRange}
          />
        }
        <BtnFlex>
          <Button variant='outlined' onClick={() => setDatePickerPopper(false)} >Cancel</Button>
          <Button variant='contained' onClick={() => {
            if (invalidDateAlert) {
              setIsInValidDateAlert(true);
            } else {
              if (isComparison) {

                get_compare_data()


              } else {
                get_insights_with_filters()
                dispatch(setComparison(false))
                dispatch(clearPrevAfter())
                dispatch(setStoreStartDate(startDate));
                dispatch(setStoreEndDate(endDate));

                get_all_campaign_insight_data(dateRange[0].startDate ? new Date(dateRange[0].startDate) : new Date(), dateRange[0].endDate ? new Date(dateRange[0].endDate) : new Date())
              }
            }
          }} >Apply</Button>
        </BtnFlex>
      </Right>
    </DateRangeWrapper>
  )
};

export default DateRangePicker;
