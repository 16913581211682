import styled from "styled-components"

export const Wrapper = styled.div`
    width: 40rem;
  /* height: 25rem; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #fff; */
  background: linear-gradient(97.29deg, #00258F 10.08%, #001963 99.77%), #052275;
  border-radius: 1rem;
  padding: 2rem 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
  color: #fff;

  p {
    margin-bottom: 0.6rem ;
  }

  h2 {
    font-weight: 600;
    margin-top: 0.5rem;
  
  }
`

export const AdFatigueTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  font-size: 1.1rem;

  p {
    margin: 0;
  }
`

export const TrackWrapper = styled.div`
    width: 100%;
    overflow: hidden;
    width: 30rem;
    /* margin-top: 1rem; */
`

export const Track = styled.div`
    display: flex;
    gap: 2rem;
    flex-wrap: nowrap;
    flex-shrink: 0;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    /* transition:  all .3s; */

    &::-webkit-scrollbar {
        display: none;
    }
`

export const ChartWrapper = styled.div`
    width: 30rem;
    flex-wrap: nowrap;
    flex-shrink: 0;
    scroll-snap-align: start;
    /* color: #000; */

`

export const Bottom = styled.div`
    color: #fff;
    text-align: center;
    padding: 0 2rem;
    margin-top: 1rem;

    h4 {
        font-size: 1.1rem;
        line-height: 1.6rem;
        font-weight: 400;

        span {
            font-weight: 800;
            background: linear-gradient(#37E4FC, #26B7F5);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }

        strong {
            font-weight: 800;
            background: linear-gradient(#FF5757,#FF4D00);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }

    p {
        color: #FFFFFF99;
        margin: 1rem 0 2rem ;
    }

 
`

export const DotFlex = styled.div`
    display: flex;
    justify-content: center;
    gap: 0.5rem;
`

type DotProps = {
    active?: boolean
}

export const Dot = styled.div<DotProps>`
    /* cursor: pointer; */
    width: 0.5rem;
    height: 0.5rem; 
    border-radius: 50%;
    background-color: ${props => props.active ? "#fff" : "#FFFFFF33"} ;
`

export const CloseBtn = styled.div`
   position :absolute ;
   top: 1rem;
   right: 1rem;
`