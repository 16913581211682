import { format, parseISO } from "date-fns";
import { useMemo, useState } from "react";
import SuccessGreenTickIcon from "../../../assets/GreenSuccessTick.svg";
import PendingIcon from "../../../assets/PendingIcon.svg";
import FailureIcon from "../../../assets/FailureIcon.svg";
import DownloadIcon from "../../../assets/DownloadIcon.svg";
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  Box,
  // Chip,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip as MuiTooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
  IconButton,
} from "@mui/material";

import {
  TableEmptyState,
  TablePlaceholderState,
} from "../../../components/Table/State";
import {
  TableBodyCell,
  TableHeaderCell as StyledTableHeaderCell,
} from "../../../components/Table/Cell";

import { createStyles } from "../../../config/theme";
import { rowsPerPageOptions } from "../../../constants/table";

import { InvoiceDetails } from "../../../interfaces/entity";
import Invoice from "src/components/Invoice/Invoice";
import { StatusTypography } from "src/layout/Layout.styles";
import { useAppSelector } from "src/hooks/useAppSelector";
import { fetchSymbolFromCurrency } from "src/libs/utils";

const TableHeaderCell = StyledTableHeaderCell<InvoiceDetails>;

interface InvoiceProps {
  query: string;
  isLoading: boolean;
  data?: InvoiceDetails[];
}
interface InvoiceBodyProps {
  loading: boolean;
  rows: number;
  columns: number;
  data: InvoiceDetails[];
}
interface InvoiceDataRowProps {
  row: InvoiceDetails;
}

type PaymentDetail = {
  ad_account_id: string[];
  proforma_invoice: string;
  credits: number;
  invoice_no: string;
  platform_use_amount: number;
  euid: string;
  pay_id: number | string;
  email: string;
  business_name: string;
  business_category: string;
  ad_account: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  pan_number: string;
  gst_no: string;
  gst_amount: number;
  platform_use: number;
  pincode: string;
  requested_amount: number;
  tax: number | null;
  requested_by: string;
  requested_date: string;
  payment_id: string | null;
  payment_status: number;
  finance_status: number;
  finance_approved_by: string | null;
  finance_approved_date: string | null;
  payment_type: string;
  paymet_mode: string;
  payment_date: string;
  paid_amount: string;
  payment_completed: number;
  status: number;
  proforma_year: string;
  total_amount: number;
  gateway_charge: number;
};

const Testing_Pdf_Data: PaymentDetail = {
  euid: "534",
  pay_id: 5,
  email: "shreeshyamindustry2023@gmail.com",
  business_name: "Shree Shyam Industry",
  business_category: "Retail \u0026 E-commerce",
  ad_account: "140187142470620",
  address1: "C-218 S/F, Old – 1450/32 Gali No-6, Plot No-32,",
  address2: "KH No-514, Hardev Puri Shahdara, North East Delhi,",
  city: "Delhi",
  state: "Delhi",
  pan_number: "LFDPS2250H",
  gst_no: "07LFDPS2250H1Z9",
  pincode: "110093",
  requested_amount: 20000,
  tax: null,
  invoice_no: "PR23-24/Nov/005",
  requested_by: "vikash@zocket.com",
  requested_date: "2023-11-03 05:42:01",
  payment_id: null,
  payment_status: 1,
  finance_status: 1,
  finance_approved_by: null,
  finance_approved_date: null,
  payment_type: "Bank Transfer ",
  paymet_mode: "Bank",
  payment_date: "2023-11-03 00:00:00",
  paid_amount: "20800",
  payment_completed: 1,
  status: 0,
  credits: 20000,
  platform_use: 4,
  total_amount: 24544,
  gst_amount: 3744,
  platform_use_amount: 800,
  proforma_invoice: "EN",
  proforma_year: "2023-2024",
  ad_account_id: ["act_895185918617686", "act_1060724534930970"],
  gateway_charge: 0,
};

export default function InvoicesDataTable({
  isLoading,
  query,
  data = [],
}: InvoiceProps) {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState({ field: "", type: "" });
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const sorted = useMemo(() => {
    if (!sort.field) return data;
    const field = sort.field as keyof InvoiceDetails;
    return [...data].sort((first, second) =>
      sort.type === "asc"
        ? first[field]! < second[field]!
          ? 1
          : -1
        : first[field]! > second[field]!
        ? 1
        : -1
    );
  }, [sort, data]);

  const filtered = useMemo(() => {
    if (query === "") {
      return sorted;
    }
    const filteredData = sorted?.filter((row) => {
      if (query !== "") {
        const cid: string = row.invoice_no.toString();
        return (
          row.business_name
            .toLowerCase()
            .includes(query.trim().toLowerCase()) || cid.includes(query)
        );
      }
    });
    return filteredData;
  }, [query, sorted]);

  const onSortChange = (field: any) => () => {
    if (!sort.type) {
      setSort({ field: field, type: "asc" });
    } else if (sort.type === "asc") {
      setSort({ field: field, type: "desc" });
    } else if (sort.type === "desc") {
      setSort({ field: field, type: "asc" });
    }
  };

  const paginated = useMemo(() => {
    return filtered?.slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  }, [page, rowsPerPage, filtered]);

  const onPageChange = (_: any, value: number) => {
    setPage(value);
  };

  const onRowsChange = (event: any) => {
    setRowsPerPage(parseInt(event.target.value));
  };

  return (
    <Box>
      <TableContainer>
        <Table sx={styles.table}>
          <TableHead sx={styles.tableHead}>
            <TableRow>
              <TableHeaderCell
                sortable
                sort={sort}
                onClick={onSortChange("created_at")}
                name="created_at"
                minWidth={130}
              >
                Date
              </TableHeaderCell>
              <TableHeaderCell minWidth={210}>
                Business Name & Email
              </TableHeaderCell>
              <TableHeaderCell minWidth={140}>Paid Amount</TableHeaderCell>
              <TableHeaderCell minWidth={140}>Overage Fee</TableHeaderCell>
              <TableHeaderCell minWidth={200}>
                Payment Gateway Charge
              </TableHeaderCell>
              <TableHeaderCell minWidth={140}>Tax</TableHeaderCell>
              <TableHeaderCell minWidth={200}>Adspend Amount</TableHeaderCell>
              {/* <TableHeaderCell minWidth={140}>Download Invoice</TableHeaderCell> */}
            </TableRow>
          </TableHead>
          <TableBodyContent
            data={paginated}
            loading={isLoading}
            rows={rowsPerPage}
            columns={6}
          />
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        sx={styles.pagination}
        rowsPerPageOptions={rowsPerPageOptions}
        colSpan={6}
        count={filtered ? filtered?.length : 1}
        rowsPerPage={rowsPerPage}
        page={page}
        showFirstButton
        showLastButton
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsChange}
      />
    </Box>
  );
}

const TableBodyContent = ({
  data,
  loading,
  rows,
  columns,
}: InvoiceBodyProps) => {
  //
  if (loading)
    return (
      <TableBody>
        <TablePlaceholderState rows={rows} columns={columns} />
      </TableBody>
    );

  if (!data?.length)
    return (
      <TableBody>
        <TableEmptyState columns={columns} />
      </TableBody>
    );

  return (
    <TableBody>
      {data?.map((row) => (
        <DataRow key={row.invoice_no} row={row} />
      ))}
    </TableBody>
  );
};

const DataRow = ({ row }: InvoiceDataRowProps) => {
  const user = useAppSelector((state) => state.user.user);
  const currency = user?.data?.currency
    ? fetchSymbolFromCurrency(user?.data?.currency)
    : "₹";
  // const status = useMemo(() => {
  //   switch (row.status) {
  //     case "LIVE_CAMPAIGNS":
  //       return { label: "Live Now", type: "live" } as const;
  //     case "COMPLETED_CAMPAIGNS":
  //       return { label: "Completed", type: "completed" } as const;
  //     case "AWAITING_APPROVAL":
  //       return { label: "Pending", type: "pending" } as const;
  //     case "STOPPED_CAMPAIGNS":
  //       return { label: "Paused", type: "paused" } as const;
  //   }
  // }, [row]);

  return (
    <TableRow>
      <TableBodyCell border>
        {format(parseISO(row.created_at), "dd MMM yyyy")}
      </TableBodyCell>
      <TableBodyCell border>
        <Box style={styles.dFlex}>
          <div>
            {row.business_name.length > 30 ? (
              <Tooltip title={row.business_name} arrow placement="right">
                <Typography variant="body2" width="fit-content">
                  {row.business_name.substring(0, 30) + "..."}
                </Typography>
              </Tooltip>
            ) : (
              <Typography variant="body2">{row.business_name}</Typography>
            )}

            <Typography variant="caption" color="text.primary">
              {row.email}
            </Typography>
          </div>
        </Box>
      </TableBodyCell>
      <TableBodyCell border>
        {row?.total_amount ? currency + row?.total_amount : currency + "0"}
      </TableBodyCell>
      <TableBodyCell border>
        {row?.processing_fee ? currency + row?.processing_fee : currency + "0"}
      </TableBodyCell>
      <TableBodyCell border>
        {row?.gateway_charge ? currency + row?.gateway_charge : currency + "0"}
      </TableBodyCell>
      <TableBodyCell border>
        {row?.tax ? currency + row?.tax : currency + "0"}
      </TableBodyCell>
      <TableBodyCell border>
        <Typography style={styles.dFlex2}>
          +{row.adspend_amount}
          <Box style={styles.dFlex3}>
            <img
              src={
                row.api_status === "false"
                  ? PendingIcon
                  : row.payment_completed === "Completed" ||
                    row.payment_completed === "paid"
                  ? SuccessGreenTickIcon
                  : FailureIcon
              }
              alt="success-icon"
            />
            <StatusTypography
              invoiceStatus={
                row.api_status === "false"
                  ? "Pending"
                  : row.payment_completed === "Completed" ||
                    row.payment_completed === "paid"
                  ? "Success"
                  : "Failure"
              }
            >
              {row.api_status === "false"
                ? "Pending"
                : row.payment_completed === "Completed" ||
                  row.payment_completed === "paid"
                ? "Success"
                : "Failure"}
            </StatusTypography>
          </Box>
        </Typography>
        <Typography style={styles.grayText}>
          {format(parseISO(row.created_at), "dd MMMM yyyy")},{" "}
          {format(parseISO(row.created_at), "hh:mm aaaa")}
        </Typography>
      </TableBodyCell>
      {/* <TableBodyCell border>
        <PDFDownloadLink
          document={<Invoice data={Testing_Pdf_Data} />}
          fileName={`Invoice-Helper.pdf`}
        >
          <IconButton style={styles.downloadBtn}>
            <img src={DownloadIcon} alt="download-icon" />
            Download
          </IconButton>
        </PDFDownloadLink>
      </TableBodyCell> */}
    </TableRow>
  );
};

// const Status = styled(Chip)<{ status: string }>(({ status, theme }) => ({
//   fontWeight: 600,
//   padding: theme.spacing(0.25, 1, 0),
//   backgroundColor:
//     status === "completed"
//       ? "#DEE4FF"
//       : status === "pending"
//       ? "#FFDEDE"
//       : status === "live"
//       ? "#D3FBD1"
//       : "FAFAFA",
//   color:
//     status === "completed"
//       ? theme.palette.primary.main
//       : status === "pending"
//       ? theme.palette.warning.main
//       : status === "live"
//       ? theme.palette.success.main
//       : theme.palette.secondary.main,
// }));

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    backgroundColor: theme.palette.common.black,
  },
}));

const styles = createStyles({
  table: {
    borderCollapse: "separate",
  },
  tableHead: {
    backgroundColor: "rgba(0, 9, 37, 0.2)",
    borderColor: "red",
  },
  checkbox: {
    color: (theme) => theme.palette.text.primary,
  },
  details: {
    "& > .MuiTableCell-root": {
      borderBottom: 0,
    },
  },
  pagination: {
    borderBottom: 0,
  },
  brandImg: {
    height: "36px",
    width: "36px",
    borderRadius: "50%",
  },
  dFlex: {
    display: "flex",
    alignContent: "center",
    gap: "1rem",
  },
  dFlex2: {
    display: "flex",
    alignContent: "center",
    gap: "0.5rem",
  },
  dFlex3: {
    display: "flex",
    alignContent: "center",
    gap: "0.2rem",
  },
  grayText: {
    color: "rgba(255,255,255,0.5)",
  },
  downloadBtn: {
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    gap: "0.5rem",
    height: "30px",
    width: "109px",
    borderRadius: "6px",
    backgroundColor: "transparent",
    border: "1px solid rgba(1, 106, 255, 1)",
    fontSize: "12px",
    fontWeight: "400",
  },
});
