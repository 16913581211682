import styled from "styled-components";
import loginMainBg from "src/assets/loginMainBg.webp";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff;
  padding: 1rem;
  display: flex;
  background-image: url(${loginMainBg});
  background-size: cover;

  color: #fff;
  position: relative;

  @media only screen and (max-width: 800px) {
    min-height: 100vh;
    height: max-content;
  }
`;

export const LoginTopRightImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Left = styled.div`
  width: 50%;
  height: 100%;
  margin-right: 4rem;
  position: relative;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

export const BgImg = styled.img`
  height: 100%;
  width: 105%;
  position: absolute;
  z-index: 1;
`;

export const LeftContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 100%;
  z-index: 2;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;

  h3 {
    color: #ffffff99;
  }

  h2 {
    display: flex;
    align-items: flex-end;
    font-size: 2rem;

    h3 {
      color: rgba(0, 209, 255, 1);
      text-decoration: underline;
    }
  }

  p {
    color: #ffffff99;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 75vh;

  h2 {
    font-weight: 600;
    font-size: 2rem;
  }

  img {
    margin-right: 0.8rem;
    width: 150px;
  }
`;

export const FeatureImg = styled.img`
  width: 20rem;
  margin: 3rem 0;
`;

export const RightDetail = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    display: flex;
    align-items: flex-end;
    font-size: 1.3rem;

    h3 {
      color: rgba(0, 209, 255, 1);
      text-decoration: underline;
    }
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 10vh;
    text-align: center;
    color: #0b1a3366;
  }

  @media only screen and (max-width: 1000px) {
    display: flex;
  }
`;

export const Right = styled.div`
  display: flex;
  /* height: 100%; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 8rem;
  flex: 1;

  @media only screen and (max-width: 800px) {
    justify-content: flex-start;
  }

  @media only screen and (max-width: 600px) {
    padding: 1rem;
  }
`;

export const ResetPasswordWrapper = styled.div`
  width: 100%;

  h3 {
    font-size: 1.6rem;
    margin-bottom: 0.4rem;
  }

  p {
    margin-bottom: 3rem;
    color: #ffffff99;
  }
`;

export const InputLabel = styled.label`
  width: 100%;
  margin-bottom: 0.1rem;
`;

export const Input = styled.div`
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const LinkSentModal = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 36rem;
  border-radius: 1.3rem;
  overflow: hidden;
  outline: none;
  overflow: hidden;
  background: linear-gradient(97.29deg, #00258f 10.08%, #001963 99.77%);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);

  img {
    width: 100%;
  }
`;

export const LinkSentModalBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 1.5rem;
  color: #fff;

  h3 {
    margin-bottom: 0.5rem;
  }
  p {
    color: #ffffff80;
    font-size: 0.9rem;
    font-weight: 300;
  }
`;
