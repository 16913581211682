import React, { useState } from "react";
import { ReAuthBtn, ReAuthLoadingContainer } from "./ReauthenticateFb.styles";
import { CircularProgress, Modal } from "@mui/material";
import {
  LinkSuccessFulModal,
  LinkSuccessFulModalBottom,
} from "src/pages/onboarding/Onboarding.styles";
import { useAppSelector } from "src/hooks/useAppSelector";
import permissionError from "src/assets/permissionError.png";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { fetchUserProfile, reAuthenticate } from "src/slices/user/userSlice";
import { useSnackbar } from "notistack";

type Props = {};

const ReauthenticateFb = (props: Props) => {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [disableBtn, setDisableBtn] = useState(false);
  const [permissionModal, setPermissionModal] = useState<boolean>(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);

  const { user, loading: saveUserAccountLoading } = useAppSelector(
    (state) => state.user
  );

  const check_permissions = (data: any): boolean => {
    let count = 0;
    const permissions = [
      "pages_read_engagement",
      "public_profile",
      "pages_show_list",
      "business_management",
      "pages_manage_metadata",
      "ads_management",
    ];

    data.map((item: any) => {
      if (permissions.includes(item.permission) && item.status === "granted") {
        count = count + 1;
      }
    });
    return count === permissions.length ? true : false;
  };

  const do_fb_login = () => {
    setDisableBtn(true);
    setShowLoadingSpinner(true);
    /* global FB */
    const _window = window as any;
    const _FB = _window.FB;

    _FB.login(
      function (response: any) {
        if (response.authResponse) {
          _FB.api(`/me/permissions`, "GET", {}, function (res: any) {
            const allPermissions = check_permissions(res.data);

            if (allPermissions) {
              _FB.api(
                `/${response.authResponse.userID}?fields=name,id,picture`,
                function (res: any) {
                  if (res) {
                    let userData = { ...response.authResponse, ...res };
                    dispatch(
                      reAuthenticate({
                        fbAccessToken: userData.accessToken,
                        euid: user?.data?.euid,
                        fbId: userData.id,
                      })
                    )
                      .unwrap()
                      .then((res) => {
                        dispatch(fetchUserProfile({ email: user?.data?.email }))
                          .unwrap()
                          .then(() => {
                            setShowLoadingSpinner(false);
                            setDisableBtn(false);
                          })
                          .catch((e) => {
                            console.log(e);
                            enqueueSnackbar("Something went wrong", {
                              variant: "error",
                            });
                            setShowLoadingSpinner(false);
                            setDisableBtn(false);
                          });
                      })
                      .catch((e) => {
                        console.log(e);
                        enqueueSnackbar("Something went wrong", {
                          variant: "error",
                        });
                        setShowLoadingSpinner(false);
                        setDisableBtn(false);
                      });
                  }
                }
              );

              _FB.api(
                `/debug_token?input_token=${response.authResponse.accessToken}`,
                function (response: any) {
                  if (response && !response.error) {
                    /* handle the result */

                    console.log(response, "DEBUG RESPONSE");
                  }
                }
              );
            } else {
              // SHOW PERMISSIONS NOT GRANTED
              // FB.logout();
              setPermissionModal(true);
              setShowLoadingSpinner(false);
              setDisableBtn(false);
            }
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
          setShowLoadingSpinner(false);
          setDisableBtn(false);
        }
      },
      {
        scope:
          "pages_read_engagement, public_profile, pages_show_list, business_management, pages_manage_metadata, ads_management",
        auth_type: "rerequest",
      }
    );
  };

  return (
    <>
      {showLoadingSpinner && (
        <ReAuthLoadingContainer>
          <CircularProgress style={{ width: "1.6rem", height: "1.6rem" }} />
        </ReAuthLoadingContainer>
      )}
      <ReAuthBtn disableBtn={disableBtn} onClick={do_fb_login}>
        <img
          src="https://d1n7zmj0fa1o7h.cloudfront.net/instamojo-assets/facebook.svg"
          alt="upgrade-plan-icon"
        />{" "}
        Link in your facebook page
      </ReAuthBtn>

      <Modal open={permissionModal} onClose={() => setPermissionModal(false)}>
        <LinkSuccessFulModal>
          <img src={permissionError} />
          <LinkSuccessFulModalBottom>
            <h3>Please grant permissions</h3>
            <p>
              you have not granted all the permissions pls grant all the
              permission
            </p>
          </LinkSuccessFulModalBottom>
        </LinkSuccessFulModal>
      </Modal>
    </>
  );
};

export default ReauthenticateFb;
