import React, { ChangeEvent, useEffect, useState } from "react";
import CloseCircleIcon from "../../assets/close-circle.svg";
import CashImage from "../../assets/MoneyImg.svg";
import PaymentSuccessImg from "../../assets/payment-success.svg";
import PaymentSuccessStar1 from "../../assets/PaymentSuccessStar1.svg";
import PaymentSuccessStar2 from "../../assets/PaymentSuccessStar2.svg";
import PaymentSuccessStar3 from "../../assets/PaymentSuccessStar3.svg";
import PaymentSuccessStar4 from "../../assets/PaymentSuccessStar4.svg";
import PaymentFailedImg from "../../assets/PaymentFailedImg.svg";
import RetryIcon from "../../assets/RetryIcon.svg";
import {
  AmountChip,
  AmountDetail,
  AmountFlex,
  AmountTotal,
  CrossIconContainer,
  FailedBoxBottomContainer,
  FailedBoxTopContainer,
  ModalBodyDetails,
  ModalBodyTop,
  ModalBox,
  ModalBoxBody,
  ModalFailedBox,
  ModalSuccessBox,
  PaymentDetailsContainer,
  PaymentFailedContainer,
  PaymentLinkCharges,
  PaymentLinkChargesContainer,
  PaymentSuccessContainer,
  ProcessingFee,
  SendEmail,
  SubmitButton,
  SuccessBoxBottomContainer,
  SuccessBoxTopContainer,
  WalletBalance,
  WalletBalanceLeft,
  WalletIndianContainer,
  WalletNote,
  Wrapper,
} from "./WalletDropDown.styles";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Zoom,
} from "@mui/material";
import { useAppSelector } from "src/hooks/useAppSelector";
import lockIcon from "src/assets/lockIcon.svg";
import razorpayIcon from "src/assets/razorpayIcon.svg";
import WalletIcon from "../../assets/WalletBalanceIcon.svg";
import {
  useCreateAdspendStripeLinkMutation,
  useEuidExistUpdateEnterpriseUserMutation,
  useGenerateAdspendPaymentLinkMutation,
  useGenerateBankDetailsMutation,
  useGetAdsSpendBalanceQuery,
  useGetProcessingFeeQuery,
} from "src/api/wallet-balance";
import { useSnackbar } from "notistack";
import { CustomToolTip } from "../adCard/AdCard.styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { createStyles } from "src/config/theme";
import {
  PaymentFormControl,
  PaymentFormControlLabel,
} from "../WalletDropDownGlobal/WalletDropDownGlobal.styles";

type bankDetailsData = {
  account_number: string;
  acct_id: string;
  ad_account: string;
  bank_name: string;
  created_at: string;
  customer_id: string;
  description: string;
  entity: string;
  euid: number;
  ifsc: string;
  name: string;
  status: string;
  address: string;
  username: string;
};

const WalletDropDown = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [amount, setAmount] = useState("");
  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [paymentMode, setPaymentMode] = useState("payment_link");

  const [bankDetails, setBankDetails] = useState<bankDetailsData>();

  const [detailCopied, setDetailCopied] = useState(false);

  const user = useAppSelector((state) => state.user.user);

  const {
    data: walletBalance,
    isLoading: isWalletBalanceLoading,
    isSuccess,
  } = useGetAdsSpendBalanceQuery({
    // ad_account: "307452422272679",
    // euid: 626,
    ad_account: user?.data?.fb_ad_account,
    euid: user?.data?.euid,
  });

  const {
    data,
    isLoading,
    isSuccess: isGetProcessingFeeSuccess,
    isError,
  } = useGetProcessingFeeQuery(
    {
      ad_account: user?.data?.fb_ad_account,
      email: user?.data?.email,
      euid: user?.data?.euid,
      exc_tax: Number(amount.replace(/\,/g, "")),
    },
    { skip: !Number(amount.replace(/\,/g, "")) }
  );
  const [generateBankDetails] = useGenerateBankDetailsMutation();
  const [generateAdspendPaymentLink] = useGenerateAdspendPaymentLinkMutation();

  let processingFee = isGetProcessingFeeSuccess
    ? parseFloat(String(Number(data.toString().replace(/\,/g, "")))).toFixed(2)
    : "0";
  let gstAmount = parseFloat(
    String((Number(amount.replace(/\,/g, "")) + Number(processingFee)) * 0.18)
  ).toFixed(2);
  let totalAmt =
    Number(amount.replace(/\,/g, "")) +
    Number(gstAmount.replace(/\,/g, "")) +
    Number(processingFee);

  const AddMoneyhandler = async () => {
    setIsBtnDisabled(true);
    if (amount.replace(/\,/g, "")) {
      if (paymentMode === "payment_link") {
        try {
          await generateAdspendPaymentLink({
            ad_account: user.data.fb_ad_account,
            email: user.data.email,
            euid: user.data.euid,
            name: user.data.ad_account_name,
            payment_mode: paymentMode,
            process_fee: processingFee,
            process_percent: "6",
            request_amount: String(Number(amount.replace(/\,/g, ""))),
            tax: "18",
            total_amount: String(totalAmt),
          })
            .unwrap()
            .then((response) => {
              if (response.short_url && response.short_url.length > 0) {
                window.open(response.short_url, "_self");
              }
              console.log(response);
              enqueueSnackbar("Generate Payment Link Successful", {
                variant: "success",
              });
              setIsBtnDisabled(false);
            });
        } catch (e: any) {
          if (e.data.message) {
            enqueueSnackbar(e.data.message, { variant: "error" });
          } else {
            enqueueSnackbar("Something went wrong", { variant: "error" });
          }
        }
      } else {
        try {
          await generateBankDetails({
            ad_account: user.data.fb_ad_account,
            email: user.data.email,
            euid: user.data.euid,
            name: user.data.ad_account_name,
            process_fee: processingFee,
            process_percent: "6",
            request_amount: String(Number(amount.replace(/\,/g, ""))),
            tax: "18",
            total_amount: String(totalAmt),
          })
            .unwrap()
            .then((response) => {
              setBankDetails(response);
              setShowPaymentDetailsModal(true);
              enqueueSnackbar("Generate Bank Details Successful", {
                variant: "success",
              });
              setIsBtnDisabled(false);
            });
        } catch (e: any) {
          if (e.data.message) {
            enqueueSnackbar(e.data.message, { variant: "error" });
          } else {
            enqueueSnackbar("Something went wrong", { variant: "error" });
          }
        }
      }
    }
  };

  useEffect(() => {
    if (isError) {
      enqueueSnackbar("Subscription has been expired", { variant: "error" });
    }
  }, [isError]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPaymentMode(event.target.value);
  };

  return (
    <WalletIndianContainer>
      <Wrapper>
        <WalletBalance>
          <WalletBalanceLeft>
            <img src={WalletIcon} alt="Wallet-icon" />
            <div>
              <h3>Your wallet balance</h3>
              <p>Use it to create and run campaigns</p>
            </div>
          </WalletBalanceLeft>
          <h2>
            {isWalletBalanceLoading ? " " : isSuccess ? "₹" : "₹"}
            {isWalletBalanceLoading
              ? "Loading..."
              : isSuccess
              ? Number(walletBalance?.balance).toFixed(2)
              : "0"}
          </h2>
        </WalletBalance>
        <PaymentFormControl>
          <FormLabel id="paymentmode" style={styles.formLabelSpacing}>
            Payment Mode
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            name="radio-buttons-group"
            value={paymentMode}
            onChange={handleChange}
          >
            <PaymentFormControlLabel
              value="bank_transfer"
              control={<Radio />}
              label="Wire Transfer (no transaction charges)"
            />
            <PaymentFormControlLabel
              value="payment_link"
              control={<Radio />}
              label="Pay via UPI, Credit Card, Debit Card"
            />
          </RadioGroup>
        </PaymentFormControl>
        <TextField
          placeholder="1,00,000"
          value={(Number(amount.replace(/\D/g, "")) || "").toLocaleString()}
          error={
            Number.isInteger(Number(amount.replace(/\,/g, ""))) ? false : true
          }
          helperText={
            Number.isInteger(Number(amount.replace(/\,/g, "")))
              ? null
              : "Enter a correct amount."
          }
          onChange={(e) => setAmount(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start">₹</InputAdornment>,
          }}
          label="Enter amount"
          fullWidth
        />
        {amount && (
          <>
            <ProcessingFee>
              <h4>
                Overage Charges for ₹
                {Number(amount.replace(/\,/g, "")).toLocaleString()}
              </h4>
              {isLoading ? (
                <CircularProgress
                  style={styles.circularProgressStyle}
                  size={20}
                />
              ) : isGetProcessingFeeSuccess && !isLoading ? (
                <p>₹ {Number(processingFee).toLocaleString()}</p>
              ) : (
                <p>₹ 0</p>
              )}
            </ProcessingFee>
            <AmountDetail>
              <div>
                <p>Credits to be bought</p>
                <h4>
                  ₹ {(Number(amount.replace(/\D/g, "")) || "").toLocaleString()}
                </h4>
              </div>
              <div>
                <p>GST (18%)</p>
                <h4>₹ {Number(gstAmount).toLocaleString()}</h4>
              </div>
            </AmountDetail>
            <AmountTotal>
              <p>Amount to be paid</p>
              <h2>₹ {totalAmt.toLocaleString()}</h2>
            </AmountTotal>
          </>
        )}

        {!amount && (
          <WalletNote>
            <img src={lockIcon} alt="lock-icon" />
            <p>Safe and secure payments. 100% authentic. Payment secured by </p>
            <img src={razorpayIcon} alt="razorpay-icon" />
          </WalletNote>
        )}
        <SubmitButton
          disabled={
            amount === "" ||
            !Number.isInteger(Number(amount.replace(/\,/g, ""))) ||
            isBtnDisabled ||
            isError ||
            isLoading
          }
          type="submit"
          onClick={AddMoneyhandler}
        >
          {isBtnDisabled ? (
            <CircularProgress style={styles.circularProgressStyle} size={20} />
          ) : (
            "Add money now"
          )}
        </SubmitButton>
      </Wrapper>

      {paymentMode === "payment_link" ? (
        <PaymentLinkChargesContainer>
          <PaymentLinkCharges>
            <h4>2%* Applicable on all transactions</h4>
            <p>+18% GST applicable</p>
            <span>
              *Instruments like Diners and Amex Cards, International Cards, EMI
              (Credit Card, Debit Card & Cardless) & Corporate (Business) Credit
              Cards will be charged at 3%.
            </span>
            <span>*International Amex Cards will be charged at 3.5%.</span>
          </PaymentLinkCharges>
        </PaymentLinkChargesContainer>
      ) : (
        ""
      )}

      {showPaymentDetailsModal && bankDetails && (
        <PaymentDetailsContainer
          open={showPaymentDetailsModal}
          onClose={() => setShowPaymentDetailsModal(false)}
        >
          <ModalBox>
            <CrossIconContainer>
              <img
                src={CloseCircleIcon}
                alt="cross-icon"
                onClick={() => setShowPaymentDetailsModal(false)}
              />
            </CrossIconContainer>
            <ModalBoxBody>
              <ModalBodyTop>
                <img src={CashImage} alt="cash-img" />
                <div>
                  <h3>Payment Details</h3>
                  <p>Transfer money to the below account details</p>
                </div>
              </ModalBodyTop>
              <hr />
              <ModalBodyDetails>
                <div>
                  <div>
                    <h4>Account Name: </h4>
                    <p>{bankDetails?.name}</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(bankDetails?.name);
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Bank Name: </h4>
                    <p>Axis Bank</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(bankDetails?.bank_name);
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Account Number: </h4>
                    <p>{bankDetails?.account_number}</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(
                            bankDetails?.account_number
                          );
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>IFSC Code: </h4>
                    <p>{bankDetails?.ifsc}</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(bankDetails?.ifsc);
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <div>
                    <h4>Username: </h4>
                    <p>{bankDetails?.username}</p>
                  </div>
                  <div>
                    <Tooltip
                      onClose={() => setDetailCopied(false)}
                      arrow
                      placement="right"
                      TransitionComponent={Zoom}
                      title={
                        <CustomToolTip>
                          {detailCopied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                      }
                    >
                      <Button
                        onClick={() => {
                          navigator.clipboard.writeText(bankDetails?.username);
                          setDetailCopied(true);
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Tooltip>
                  </div>
                </div>
              </ModalBodyDetails>
            </ModalBoxBody>
          </ModalBox>
        </PaymentDetailsContainer>
      )}
      {/* {showPaymentSuccessModal && (
        <PaymentSuccessContainer
          open={showPaymentSuccessModal}
          onClose={() => setShowPaymentSuccessModal(false)}
        >
          <ModalSuccessBox>
            <CrossIconContainer>
              <img
                src={CloseCircleIcon}
                alt="cross-icon"
                onClick={() => setShowPaymentSuccessModal(false)}
              />
            </CrossIconContainer>
            <SuccessBoxTopContainer>
              <img src={PaymentSuccessImg} alt="payment-success-img" />
              <img src={PaymentSuccessStar1} alt="star-img" />
              <img src={PaymentSuccessStar2} alt="star-img" />
              <img src={PaymentSuccessStar3} alt="star-img" />
              <img src={PaymentSuccessStar4} alt="star-img" />
            </SuccessBoxTopContainer>
            <SuccessBoxBottomContainer>
              <h2>Payment Successful!</h2>
            </SuccessBoxBottomContainer>
          </ModalSuccessBox>
        </PaymentSuccessContainer>
      )} */}
      {/* {showPaymentFailedModal && (
        <PaymentFailedContainer
          open={showPaymentFailedModal}
          onClose={() => setShowPaymentFailedModal(false)}
        >
          <ModalFailedBox>
            <CrossIconContainer>
              <img
                src={CloseCircleIcon}
                alt="cross-icon"
                onClick={() => setShowPaymentFailedModal(false)}
              />
            </CrossIconContainer>
            <FailedBoxTopContainer>
              <img src={PaymentFailedImg} alt="payment-success-img" />
            </FailedBoxTopContainer>
            <FailedBoxBottomContainer>
              <h2>Payment Failed</h2>
            </FailedBoxBottomContainer>
          </ModalFailedBox>
        </PaymentFailedContainer>
      )} */}
    </WalletIndianContainer>
  );
};

export default WalletDropDown;

const styles = createStyles({
  circularProgressStyle: {
    color: "white",
  },
  formLabelSpacing: {
    marginBottom: "0.5rem",
  },
});
