import React, { useState } from 'react'
import { Bottom, BottomFlex, Caption, CustomToolTip, FbBtn, ImgWrapper, Pay, Top, Wrapper } from './AdCard.styles';
import companyLogo from "src/assets/companyLogo.png";
import sponsored from "src/assets/sponsored.png";
import adProduct from "src/assets/adProduct.png";
import Button from '@mui/material/Button';
import fbIcon from "src/assets/linkFbIcon.svg";
import templateImage from "src/assets/templateImage.png";
import { AdCardProps } from './types';
import { IconButton, Tooltip, Zoom } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const AdCard = ({
    ad_image_url,
    caption,
    call_action_type,
    page_name,
    call_action_link,
    page_image,
    call_action_message,
    ad_account_name,
    ad_id
}: AdCardProps) => {
    const [copied, setCopied] = useState(false)

    const get_modified_text = (str: string): string => {
        let s = str.toLowerCase();
        s = s.replaceAll("_", " ");
        const firstLetter = s.charAt(0);
        const remaining = s.slice(1);

        return firstLetter.toUpperCase() + remaining
    }

    return (
        <Wrapper>
            <div>
                <Top>
                    <img src={page_image || fbIcon} />
                    <div>
                        <h4>{page_name || ad_account_name}</h4>
                        <p>Sponsored <img src={sponsored} /></p>
                    </div>
                </Top>
                <Caption>
                    {caption}
                </Caption>
            </div>

            <Bottom>
                <ImgWrapper>
                    <img src={ad_image_url || templateImage} />
                </ImgWrapper>

                <Pay>
                    <div>
                        <h4>Pre-Pay and Save</h4>
                        <p>{call_action_message}</p>
                    </div>
                    <Button sx={{ width: "10rem", marginLeft: "0.6rem", color: "#000000B0" }} variant='outlined' size="small">
                        {get_modified_text(call_action_type) || "Learn more"}
                    </Button>
                </Pay>
                <BottomFlex>
                    <a href={call_action_link} style={{ textDecoration: "none" }}  >
                        <FbBtn>
                            <img src={fbIcon} />
                            <p>View this ad on Facebook</p>
                        </FbBtn>
                    </a>
                    <Tooltip onClose={() => setCopied(false)} arrow TransitionComponent={Zoom} title={
                        <CustomToolTip>
                            {copied ? "Copied!" : "Copy to Clipboard"}
                        </CustomToolTip>
                    }>
                        <Button onClick={() => { navigator.clipboard.writeText(call_action_link); setCopied(true) }}>
                            <ContentCopyIcon sx={{ color: "#000" }} />
                        </Button>
                    </Tooltip>
                </BottomFlex>
            </Bottom>


        </Wrapper>
    )
}

export default AdCard