import styled from "styled-components";

export const AdAccountCardContainer = styled.div`
  background-color: rgba(26, 26, 26, 0.25);
  height: 123px;
  width: 709px;
  border-radius: 10px;
  border: 1.5px solid rgba(0, 45, 114, 1);
  padding: 0.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
`;

export const CombineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;

export const StartContainer = styled.div`
  position: relative;
  top: -18px;

  > img {
    width: 28px;
    height: 28px;
    border-radius: 50%;
  }
`;

export const MiddleContainer = styled.div`
  > h5 {
    font-size: 14px;
    line-height: 22px;
    color: white;
  }
  > hr {
    border: none;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    margin: 0.6rem 0 0.8rem 0;
  }
`;

export const WalletContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  > a > img {
    height: 22px;
    width: 22px;
  }

  > div > p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
    position: relative;
    top: 3px;
  }

  > div > h6 {
    font-weight: 700;
    font-size: 18px;
    position: relative;
    top: 2px;
    color: rgba(255, 255, 255, 1);
  }

  > div > h6 > span {
    font-size: 12px;
    margin-right: 0.2rem;
  }
`;

export const WalletBalanceAddText = styled.p`
  margin-top: 0.2rem;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(
    to right,
    rgba(115, 176, 255, 1),
    rgba(115, 176, 255, 0.4)
  );
  -webkit-background-clip: text;
  font-size: 10px;
  font-weight: 400;
`;

export const LastContainer = styled.button`
  cursor: pointer;
  height: 50px;
  width: 185px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  border: 1.5px solid rgba(15, 110, 255, 1);
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: white;
  border-radius: 10px;
  background: rgba(27, 125, 255, 0.1);
  z-index: 1;

  > img {
    height: 25px;
    width: 25px;
  }

  &:hover {
    background: linear-gradient(
      to right,
      rgba(0, 102, 255, 1),
      rgba(0, 148, 255, 1)
    );
  }
`;
