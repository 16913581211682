import styled from "styled-components";

export const Wrapper = styled.div`
  width: 35%;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
`;

export const TitleFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  color: #fff;
  border-bottom: 1px solid #efefef4d;
  /* padding-bottom: 1rem; */
  margin-bottom: 2rem;

  sub {
    color: #fff;
    font-weight: 700;
  }

  p {
    color: #fff;
    display: flex;
    align-items: center;
  }
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 2rem;
  padding: 1.5rem 0;
  border-top: 1px dashed #ffffff80;
  margin-top: 2.5rem;
  color: #fff;
`;

export const Label = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;

  p {
    font-size: 1rem;
  }
`;

type DotProps = {
  label: string;
};

export const Dot = styled.div<DotProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.label === "new" ? "#66C674" : "#FFC700"};
`;
