import React, { FormEvent, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import {
  ActualBtn,
  GoogleBtn,
  Left,
  Right,
  SocialLogin,
  Wrapper,
  OrDivider,
  Form,
  InputLabel,
  Input,
  FormBottom,
  FormBottomLeft,
  SubmitBtn,
  LeftContent,
  Logo,
  TermsCondition,
  SignupBtn,
  VerifyOtp,
  VerifyBtn,
  ResendOtp,
  EnteredEmailContainer,
  TimerContainer,
  Timer,
  RequestForOtp,
  LoginWithPasswordBtn,
  SignupWithPasswordBtn,
} from "./Login.styles";
import UNIVERSAL from "../../config/config";
import { useNavigate } from "react-router-dom";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import googleLogo from "../../assets/googleLogo.png";

import {
  Button,
  InputAdornment,
  Link,
  TextField,
  Checkbox,
  Alert,
} from "@mui/material";
import {
  emailLogin,
  loginWithGoogle,
  setAuthData,
  signupWithEmail,
  signupWithGoogle,
} from "../../slices/authentication/authenticationSlice";
import { fetchUserProfile, setProfile } from "../../slices/user/userSlice";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import EditEmailIcon from "../../assets/editEmail.svg";
import TimerIcon from "../../assets/timer.svg";
import { useCountdown } from "src/hooks/use-countdown";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { createStyles } from "src/config/theme";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useGetLoginWithEmailOTPMutation,
  useGetSignupWithEmailOTPMutation,
  useVerifyLoginWithEmailOTPMutation,
  useVerifySignupWithEmailOTPMutation,
} from "src/api/otp";
import { useSnackbar } from "notistack";

const Login = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [formType, setFormType] = useState("loginwithotp");
  const [formEditType, setFormEditType] = useState("loginwithotp");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [enteredPhnNumber, setEnteredPhnNumber] = useState("");
  const [countryDialCode, setCountryDialCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [showLoader, setShowLoader] = useState(false);

  const [countdown, { hasCountdownEnded, resetAndStartCountdown }] =
    useCountdown({ start: 30 });

  const [signupError, setSignupError] = useState<string | boolean>(false);
  const [loginError, setLoginError] = useState<string | boolean>(false);
  const [loginwithotpError, setLoginWithOtpError] = useState<string | boolean>(
    false
  );

  const authentication = useAppSelector((state) => state.authentication.data);
  const userLocation = useAppSelector(
    (state) => state.userLocation.userLocation
  );
  const dispatch = useAppDispatch();

  const [getLoginWithEmailOTP] = useGetLoginWithEmailOTPMutation();
  const [verifyLoginWithEmailOTP] = useVerifyLoginWithEmailOTPMutation();

  const [signupWithEmailOTP] = useGetSignupWithEmailOTPMutation();
  const [verifySignupWithEmailOTP] = useVerifySignupWithEmailOTPMutation();

  useEffect(() => {
    if (localStorage.getItem("ENTERPRISE_DASHBOARD_EMAIL")) {
      navigate("/dashboard");
    }
  }, []);

  const loginWithOtp = async (e: FormEvent, type: string) => {
    e.preventDefault();
    if (!email) {
      setLoginWithOtpError("Email is required");
      return;
    }
    setShowLoader(true);
    try {
      await getLoginWithEmailOTP({
        email: email,
      })
        .unwrap()
        .then((response) => {
          if (
            response.is_user_exists &&
            response.otp_sent &&
            response.email_verified
          ) {
            setFormType("verifyotp");
            resetAndStartCountdown();
          }
        });
      enqueueSnackbar(
        type !== "resendotp"
          ? "OTP sent to your entered email"
          : "OTP re-sent to your entered email",
        {
          variant: "success",
        }
      );
      setShowLoader(false);
    } catch (e: any) {
      if (e.data.message) {
        console.log(e.data.message);
        if (e.data.message === "Email is not verified") {
          setFormType("loginwithpassword");
          setShowLoader(false);
        } else {
          enqueueSnackbar(e.data.message, { variant: "error" });
          setShowLoader(false);
        }
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        console.log(e);
        setShowLoader(false);
      }
    }
  };

  const handleVerifyOtp = async (e: FormEvent) => {
    e.preventDefault();
    if (otp.length < 6) {
      enqueueSnackbar("Please enter the 6 digits otp", { variant: "error" });
      return;
    }
    setShowLoader(true);
    try {
      await verifyLoginWithEmailOTP({
        email: email,
        otp: otp,
      })
        .unwrap()
        .then((response) => {
          if (response.is_user_exists && response.access_token) {
            localStorage.setItem("ENTERPRISE_DASHBOARD_EMAIL", email);
            dispatch(setAuthData(response));
            navigate("/dashboard");
          }
        });
      enqueueSnackbar("Login Successful", {
        variant: "success",
      });
      setShowLoader(false);
    } catch (e: any) {
      if (e.data.message) {
        enqueueSnackbar(e.data.message, { variant: "error" });
        setShowLoader(false);
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        console.log(e);
        setShowLoader(false);
      }
    }
  };

  const emailPatternVerifyRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const singupWithOtp = async (e: FormEvent, type: string) => {
    e.preventDefault();
    let emailPatternVerify = emailPatternVerifyRegex.test(email);
    if (
      name === "" ||
      email === "" ||
      website === "" ||
      countryDialCode === "" ||
      mobile === "" ||
      !checked
    ) {
      setSignupError("Please fill all the fields");
      return;
    } else if (emailPatternVerify === false) {
      setSignupError("Please enter correct email address");
      return;
    } else {
      setSignupError(false);
      setShowLoader(true);
      try {
        await signupWithEmailOTP({
          name: name,
          email: email,
          mobile: mobile,
          website: website,
          countryDialCode: countryDialCode,
        })
          .unwrap()
          .then((response) => {
            if (!response.is_user_exists && response.otp_sent) {
              setFormType("verifyotp");
              resetAndStartCountdown();
            }
          });
        enqueueSnackbar(
          type !== "resendotp"
            ? "OTP sent to your entered email"
            : "OTP re-sent to your entered email",
          {
            variant: "success",
          }
        );
        setShowLoader(false);
      } catch (e: any) {
        if (e.data.message) {
          enqueueSnackbar(e.data.message, { variant: "error" });
          setShowLoader(false);
        } else {
          enqueueSnackbar("Something went wrong", { variant: "error" });
          console.log(e);
          setShowLoader(false);
        }
      }
    }
  };

  const handleResendOtp = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (formEditType === "loginwithotp") {
      loginWithOtp(e, "resendotp");
      setOtp("");
    }
    if (formEditType === "signup") {
      singupWithOtp(e, "resendotp");
      setOtp("");
    }
  };

  const handleVerifySignupOtp = async (e: FormEvent) => {
    e.preventDefault();
    if (otp.length < 6) {
      enqueueSnackbar("Please enter the 6 digits otp", { variant: "error" });
      return;
    }
    setShowLoader(true);
    try {
      await verifySignupWithEmailOTP({
        name: name,
        email: email,
        mobile: mobile,
        website: website,
        countryDialCode: countryDialCode,
        otp: otp,
      })
        .unwrap()
        .then((response) => {
          if (response.euid) {
            localStorage.setItem("ENTERPRISE_DASHBOARD_EMAIL", email);
            dispatch(setAuthData(response));
            navigate("/dashboard");
          }
        });
      enqueueSnackbar("Signup Successful", {
        variant: "success",
      });
      setShowLoader(false);
    } catch (e: any) {
      if (e.data.message) {
        enqueueSnackbar(e.data.message, { variant: "error" });
        setShowLoader(false);
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        console.log(e);
        setShowLoader(false);
      }
    }
  };

  useEffect(() => {
    if (
      authentication?.user?.onboarding === true &&
      authentication?.user?.fb_ad_account
    ) {
      dispatch(fetchUserProfile({ email: authentication?.user?.email }));
      navigate("/dashboard");
    } else if (
      authentication?.user &&
      authentication?.user?.onboarding === false
    ) {
      navigate("/onboarding");
    } else if (
      authentication?.user?.onboarding === true &&
      !authentication?.user?.fb_access_token
    ) {
      navigate("/onboarding");
    } else if (
      authentication?.user?.onboarding === true &&
      authentication?.user?.fb_access_token &&
      !authentication?.user?.fb_ad_account
    ) {
      navigate("/dashboard");
    }
  }, [authentication]);

  useEffect(() => {
    setLoginError(false);
    setSignupError(false);
  }, [formType]);

  useEffect(() => {
    /* global google */
    const _window = window as any;

    if (_window.google) {
      _window.google?.accounts?.id?.initialize({
        client_id: UNIVERSAL.GOOGLE_LOGIN_CLIENT_ID,
        callback: handleCallBackResponse,
      });

      _window.google?.accounts?.id?.renderButton(
        document.getElementById("googleLoginBtn"),
        {
          theme: "outline",
          type: "standard",
          size: "large",
          text: "Sign in with Google",
        }
      );
    }
  }, []);

  const handleCallBackResponse = (response: any) => {
    dispatch(loginWithGoogle(response.credential))
      .unwrap()
      .catch(() =>
        dispatch(signupWithGoogle(response.credential)).then(() =>
          dispatch(loginWithGoogle(response.credential))
        )
      );
  };

  const canSignUp = [
    name,
    email,
    password,
    rePassword,
    website,
    mobile,
    countryDialCode,
    checked,
  ].every(Boolean);

  useEffect(() => {
    if (canSignUp) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [canSignUp]);

  function handleEnteredPhoneNumber(phone: any, data: any) {
    setEnteredPhnNumber(phone);
    setCountryDialCode(data.dialCode);
    let dialCodeLength = data.dialCode.length;
    let number = phone.slice(dialCodeLength);
    setMobile(number);
  }

  const do_email_signup = () => {
    setShowLoader(true);
    setIsBtnDisabled(true);
    setSignupError(false);
    setLoginError(false);
    if (
      name === "" ||
      email === "" ||
      website === "" ||
      countryDialCode === "" ||
      mobile === ""
    ) {
      setSignupError("Please fill all the fields");
      setIsBtnDisabled(false);
      setShowLoader(false);
    } else {
      dispatch(
        signupWithEmail({
          name,
          email,
          password,
          mobile,
          website,
          countryDialCode,
        })
      )
        .unwrap()
        .then(() => {
          setIsBtnDisabled(false);
          dispatch(emailLogin({ email, password }));
          setShowLoader(false);
        })
        .catch((err: Error) => {
          setIsBtnDisabled(false);
          setSignupError(err.message);
          setShowLoader(false);
        });
    }
  };

  const handle_form_submit = (e: FormEvent) => {
    setShowLoader(true);
    e.preventDefault();
    if (formType === "loginwithpassword") {
      setSignupError(false);
      if (email === "" || password === "") {
        setShowLoader(false);
        setLoginError("Please fill all the fields");
      } else {
        dispatch(emailLogin({ email: email, password: password }))
          .unwrap()
          .catch((err: Error) => {
            if (err.message === "sql: no rows in result set") {
              setLoginError("User does not exist.");
              setShowLoader(false);
            } else {
              setLoginError(err.message);
              setShowLoader(false);
            }
          });
      }
    } else {
      do_email_signup();
    }
  };

  return (
    <Wrapper>
      <Left>
        <LeftContent>
          <Logo>
            <img
              src="https://drbgg29kvmub6.cloudfront.net/assets/animationFiles/companylogo.svg"
              alt="ad360-logo"
            />
          </Logo>
          <h2>
            Welcome to our&nbsp;<h3> AI </h3>&nbsp;Dashboard
          </h2>
          <p>
            See all the analytics and grow your data remotely, from anywhere!
          </p>
        </LeftContent>
      </Left>
      <Right>
        {formType === "signup" && (
          <>
            <SocialLogin>
              <h3>Sign up for an account</h3>
              <p>Enter these below details to create your account</p>
              <GoogleBtn>
                <div id="googleLoginBtn" />
                <ActualBtn>
                  <img src={googleLogo} />
                  <p>Sign in with Google</p>
                </ActualBtn>
              </GoogleBtn>
            </SocialLogin>
            <OrDivider>Or signup with email</OrDivider>
            <Form onSubmit={(e: FormEvent) => singupWithOtp(e, "resendotp")}>
              {signupError && formType === "signup" && (
                <Alert sx={{ marginBottom: "1rem" }} severity="error">
                  {signupError}
                </Alert>
              )}
              <Input>
                <InputLabel>Name</InputLabel>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Enter your full name"
                  style={{ borderRadius: "50%" }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Input>
              <Input>
                <InputLabel>Email address</InputLabel>
                <TextField
                  type="email"
                  size="small"
                  fullWidth
                  placeholder="Enter your email ID"
                  style={{
                    borderRadius: "50%",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Input>
              <Input>
                <InputLabel>Your Company Website</InputLabel>
                <TextField
                  type="text"
                  size="small"
                  fullWidth
                  placeholder="Enter your company website"
                  style={{
                    borderRadius: "50%",
                  }}
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Input>
              <Input>
                <InputLabel>Phone Number</InputLabel>
                <PhoneInput
                  country={
                    userLocation ? userLocation.toString().toLowerCase() : "in"
                  }
                  containerClass="signupPhoneContainer"
                  inputClass="signupSearch"
                  dropdownClass="signupDropdown"
                  value={enteredPhnNumber}
                  countryCodeEditable={false}
                  placeholder="Your Phone Number"
                  onChange={(phone, data) =>
                    handleEnteredPhoneNumber(phone, data)
                  }
                />
              </Input>

              <TermsCondition>
                <input
                  type="checkbox"
                  name="termscondition"
                  id="termscondition"
                  onChange={() => setChecked(!checked)}
                />
                <label htmlFor="termscondition">
                  By clicking, you agree to our
                </label>
                <a
                  href="https://zocket.com/terms.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Condition
                </a>
              </TermsCondition>
              <SignupBtn>
                <SignupWithPasswordBtn
                  sx={{
                    width: "20rem",
                    borderRadius: "1rem",
                    textTransform: "none",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                  variant="contained"
                  onClick={(e: FormEvent) => singupWithOtp(e, "resendotp")}
                  disabled={showLoader}
                >
                  <span></span>
                  Request for an OTP{" "}
                  {showLoader ? (
                    <CircularProgress
                      style={styles.circularProgressStyle}
                      size={20}
                    />
                  ) : (
                    <ArrowRightAltIcon />
                  )}{" "}
                </SignupWithPasswordBtn>
                <p>
                  Already have an account{" "}
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormType("loginwithotp");
                      setFormEditType("loginwithotp");
                    }}
                  >
                    Login
                  </Link>
                </p>
              </SignupBtn>
            </Form>
          </>
        )}

        {formType === "loginwithotp" && (
          <>
            <SocialLogin>
              <h3>Login to your account</h3>
              <p>Enter your credentials to access your account</p>
              <GoogleBtn>
                <div id="googleLoginBtn" />
                <ActualBtn>
                  <img src={googleLogo} />
                  <p>Sign in with Google</p>
                </ActualBtn>
              </GoogleBtn>
            </SocialLogin>
            <OrDivider>Or sign in with email</OrDivider>
            <Form onSubmit={(e: FormEvent) => loginWithOtp(e, "loginwithotp")}>
              {loginwithotpError && formType === "loginwithotp" && (
                <Alert sx={{ marginBottom: "1rem" }} severity="error">
                  {loginwithotpError}
                </Alert>
              )}
              <Input>
                <InputLabel>Email address</InputLabel>
                <TextField
                  type="email"
                  size="small"
                  fullWidth
                  placeholder="Enter your email ID"
                  style={{
                    borderRadius: "50%",
                  }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setLoginWithOtpError(false);
                  }}
                />
              </Input>
              <SubmitBtn>
                <RequestForOtp
                  type="submit"
                  sx={{
                    width: "20rem",
                    borderRadius: "1rem",
                    textTransform: "none",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                  variant="contained"
                  disabled={showLoader}
                >
                  <span></span>
                  Sign in{" "}
                  {showLoader ? (
                    <CircularProgress
                      style={styles.circularProgressStyle}
                      size={20}
                    />
                  ) : (
                    <ArrowRightAltIcon />
                  )}{" "}
                </RequestForOtp>
                <p>
                  Don&apos;t have an account{" "}
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormType("signup");
                      setEmail("");
                      setPassword("");
                      setFormEditType("signup");
                    }}
                  >
                    Sign up
                  </Link>
                </p>
              </SubmitBtn>
            </Form>
          </>
        )}

        {formType === "loginwithpassword" && (
          <>
            <SocialLogin>
              <h3>Login to your account</h3>
              <p>Enter your credentials to access your account</p>
              <GoogleBtn>
                <div id="googleLoginBtn" />
                <ActualBtn>
                  <img src={googleLogo} />
                  <p>Sign in with Google</p>
                </ActualBtn>
              </GoogleBtn>
            </SocialLogin>
            <OrDivider>Or sign in with email</OrDivider>
            <Form onSubmit={(e: FormEvent) => handle_form_submit(e)}>
              {loginError && formType === "loginwithpassword" && (
                <Alert sx={{ marginBottom: "1rem" }} severity="error">
                  {loginError}
                </Alert>
              )}
              <Input>
                <InputLabel>Email address</InputLabel>
                <TextField
                  type="email"
                  size="small"
                  fullWidth
                  placeholder="Enter your email ID"
                  style={{
                    borderRadius: "50%",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Input>
              <Input>
                <InputLabel>Password</InputLabel>
                <TextField
                  type={passwordVisible ? "text" : "password"}
                  size="small"
                  fullWidth
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: "pointer" }}
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        {passwordVisible ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Input>
              <FormBottom>
                <FormBottomLeft>
                  <Checkbox size="small" />
                  <p>Remember me</p>
                </FormBottomLeft>
                <div />
                <Link
                  onClick={() => navigate("/reset_password")}
                  sx={{ cursor: "pointer" }}
                >
                  Forget password
                </Link>
              </FormBottom>
              <SubmitBtn>
                <LoginWithPasswordBtn
                  type="submit"
                  sx={{
                    width: "20rem",
                    borderRadius: "1rem",
                    textTransform: "none",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                  variant="contained"
                  disabled={showLoader}
                >
                  <span></span>
                  Sign in{" "}
                  {showLoader ? (
                    <CircularProgress
                      style={styles.circularProgressStyle}
                      size={20}
                    />
                  ) : (
                    <ArrowRightAltIcon />
                  )}{" "}
                </LoginWithPasswordBtn>
                <p>
                  Don&apos;t have an account{" "}
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormType("signup");
                      setEmail("");
                      setPassword("");
                      setFormEditType("signup");
                    }}
                  >
                    Sign up
                  </Link>
                </p>
              </SubmitBtn>
            </Form>
          </>
        )}

        {formType === "verifyotp" && (
          <VerifyOtp>
            <h2>Please enter OTP</h2>
            <p>Please enter your OTP and signup to the application</p>
            <EnteredEmailContainer>
              <span>{email}</span>
              <img
                src={EditEmailIcon}
                alt="edit-email-icon"
                onClick={() => {
                  setChecked(false);
                  setFormType(formEditType);
                }}
              />
            </EnteredEmailContainer>
            <Form
              onSubmit={(e: FormEvent) =>
                formEditType === "loginwithotp"
                  ? handleVerifyOtp(e)
                  : handleVerifySignupOtp(e)
              }
            >
              <TimerContainer>
                <OtpInput
                  value={otp}
                  onChange={(e: string) => setOtp(e)}
                  numInputs={6}
                  inputStyle="otpInput"
                  containerStyle="otpInputContainer"
                  shouldAutoFocus
                  renderInput={(props) => <input {...props} />}
                />
                <Timer>
                  <img src={TimerIcon} alt="timer-icon" />
                  <span>00:{String(countdown).padStart(2, "0")}</span>
                </Timer>
              </TimerContainer>
              <VerifyBtn disabled={showLoader}>
                <span></span>
                Verify and proceed{" "}
                {showLoader ? (
                  <CircularProgress
                    style={styles.circularProgressStyle}
                    size={20}
                  />
                ) : (
                  <ArrowRightAltIcon />
                )}{" "}
              </VerifyBtn>
            </Form>
            <ResendOtp hasCountdownEnded={hasCountdownEnded}>
              <p>Didn’t receive OTP?</p>
              <button
                onClick={(e) => handleResendOtp(e)}
                disabled={!hasCountdownEnded}
              >
                Resend
              </button>
            </ResendOtp>
          </VerifyOtp>
        )}
      </Right>
    </Wrapper>
  );
};

export default Login;

const styles = createStyles({
  circularProgressStyle: {
    color: "white",
  },
});
