import { createTheme, SxProps, Theme } from "@mui/material/styles";

export const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  shape: {
    borderRadius: 12,
  },
  palette: {
    primary: {
      light: "#DAE6FF",
      main: "#1977F3",
      contrastText: "#FFFFFF",
    },
    secondary: {
      light: "#1B3F67",
      main: "#001738",
      contrastText: "#FFFFFF",
    },
    error: {
      light: "#FFD3D3",
      main: "#F42F2F",
    },
    background: {
      default: "#F6F9FF",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#666666",
      secondary: "#000000",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          overflowY: "scroll",
        },
        "::-webkit-scrollbar": {
          width: 8,
          height: 8,
          background: "#F8F8F8",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#D0D0D0",
        },
        "::-webkit-scrollbar-corner": {
          background: "#D0D0D0",
        },
        ".pac-container": {
          zIndex: "2000 !important",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: "#000000",
        },
        sizeSmall: {
          fontSize: 15,
          paddingTop: 5,
          paddingBottom: 4,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: "large",
      },
      styleOverrides: {
        root: {
          textTransform: "none",
        },
        sizeLarge: {
          paddingTop: 12,
          paddingBottom: 12,
          fontSize: 16,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: false,
      },
    },
  },
});

export function createStyles<T extends { [key: string]: SxProps<Theme> }>(
  styles: T
): T {
  return styles;
}

export function mergeStyles(...args: SxProps<Theme>[]) {
  return args.reduce((theme, result) => ({ ...theme, ...result }), {});
}
