import { Box, Modal, TextField } from "@mui/material";
import styled from "styled-components";

export const SettingsContainer = styled.div`
  width: 100%;
  border: 1px solid rgba(15, 110, 255, 1);
  background: linear-gradient(
    to right,
    rgba(0, 37, 143, 1),
    rgba(0, 25, 99, 1)
  );
  border-radius: 12px;
  padding: 4rem 2rem;
`;

export const AccountDetails = styled.div`
  > h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 1);
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(15, 110, 255, 1);
    border-radius: 12px;
    height: 103px;
    padding: 1rem 2rem;
    background-color: rgba(27, 125, 255, 0.1);
    margin-top: 1rem;

    > div:nth-child(1) {
      display: flex;
      align-items: center;
      gap: 1.5rem;
      > div:nth-child(1) {
        height: 61px;
        width: 61px;
        border-radius: 50%;
        border: 3px solid rgba(245, 250, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      > div:nth-child(2) {
        > h3 {
          font-size: 20px;
          font-weight: 400;
          line-height: 19.49px;
          color: rgba(255, 255, 255, 1);
          margin-bottom: 0.4rem;
        }
        > h5 {
          font-size: 16px;
          font-weight: 400;
          line-height: 19.49px;
          color: rgba(255, 255, 255, 0.4);
        }
      }
    }
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      > span {
        height: 38px;
        width: 38px;
        border-radius: 15px;
        background-color: rgba(5, 168, 107, 1);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 19.6px;
        font-weight: 500;
        line-height: 45.41px;
        color: rgba(255, 255, 255, 1);
      }
      > div {
        > h5 {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          color: rgba(255, 248, 248, 1);
        }
        > p {
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid rgba(153, 156, 160, 1);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: rgba(255, 255, 255, 1);
      border-radius: 8px;
      height: 48px;
      width: 61px;
      cursor: pointer;
    }
  }
`;

export const FbDetails = styled.div`
  margin-top: 2.5rem;
  > h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 1);
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(15, 110, 255, 1);
    border-radius: 12px;
    height: 103px;
    padding: 1rem 2rem;
    background-color: rgba(27, 125, 255, 0.1);
    margin-top: 1rem;
    > div:nth-child(1) {
      > p {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(255, 255, 255, 0.5);
      }
      > div {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        > img {
          height: 32px;
          width: 32px;
          border-radius: 50%;
          border: 0.5 px rgba(239, 202, 188, 1);
        }
        > h6 {
          font-size: 16px;
          font-weight: 400;
          line-height: 26.37px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid rgba(153, 156, 160, 1);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: rgba(255, 255, 255, 1);
      border-radius: 8px;
      height: 48px;
      width: 61px;
      cursor: pointer;
      text-decoration: none;
    }
  }
`;

export const BillingDetails = styled.div`
  margin-top: 2.5rem;

  > h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 1);
  }
  > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid rgba(15, 110, 255, 1);
    border-radius: 12px;
    min-height: 137px;
    height: 100%;
    max-height: 100%;
    padding: 2rem;
    background-color: rgba(27, 125, 255, 0.1);
    margin-top: 1rem;

    > div {
      > h6 {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        color: rgba(153, 156, 160, 1);
      }
      > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(204, 204, 204, 1);
        margin-top: 1rem;
      }
    }

    > div:nth-child(2) {
      max-width: 600px;
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid rgba(153, 156, 160, 1);
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: rgba(255, 255, 255, 1);
      border-radius: 8px;
      height: 48px;
      width: 61px;
      cursor: pointer;
    }
  }
`;

export const NoBillingDetails = styled.div`
  margin-top: 2.5rem;

  > h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 1);
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(15, 110, 255, 1);
    border-radius: 12px;
    min-height: 137px;
    height: 100%;
    max-height: 100%;
    padding: 2rem;
    background-color: rgba(27, 125, 255, 0.1);
    margin-top: 1rem;

    > div {
      display: flex;
      align-items: center;
      gap: 1rem;
      > img {
        height: 75px;
        width: 75px;
        max-height: 100%;
        max-width: 100%;
      }
      > h5 {
        font-size: 18px;
        font-weight: 400;
        line-height: 19.49px;
        color: rgba(255, 255, 255, 1);
      }
    }
    > button {
      height: 55px;
      width: 164px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      border: none;
      outline: none;
      background: linear-gradient(
        to left,
        rgba(0, 82, 241, 1),
        rgba(0, 117, 255, 1)
      );
      cursor: pointer;
    }
  }
`;

export const AccountSpendingLimit = styled.div`
  margin-top: 2.5rem;

  > h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 1);
  }
  > div {
    border: 1px solid rgba(15, 110, 255, 1);
    border-radius: 12px;
    height: 137px;
    padding: 1rem 2rem;
    background-color: rgba(27, 125, 255, 0.1);
    margin-top: 1rem;

    > h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: rgba(255, 248, 248, 1);
      margin-top: 1rem;
    }

    > span {
      width: 100%;
      margin: 1rem 0 0.5rem 0;
    }

    > div {
      display: flex;
      align-items: center;
      gap: 1rem;
      color: white;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.49px;
      color: rgba(255, 255, 255, 0.4);
      > span {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.49px;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
`;

export const NoAccountSpendingLimit = styled.span`
  border: 1px solid rgba(15, 110, 255, 1);
  border-radius: 12px;
  height: 137px;
  padding: 1rem 2rem;
  background-color: rgba(27, 125, 255, 0.1);
  margin-top: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > span,
  > p {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 0.4);
    text-align: center;
  }
`;

export const MyPlan = styled.div`
  margin-top: 2.5rem;

  > h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 1);
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(15, 110, 255, 1);
    border-radius: 12px;
    height: 137px;
    padding: 1rem 2rem;
    background-color: rgba(27, 125, 255, 0.1);
    margin-top: 1rem;

    > div {
      display: flex;
      align-items: center;
      gap: 1rem;
      > img {
      }
      > div {
        > h6 {
          font-size: 18px;
          font-weight: 400;
          line-height: 19.49px;
          color: rgba(255, 255, 255, 1);
        }
        > p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19.49px;
          color: rgba(255, 255, 255, 0.5);
          margin-top: 0.5rem;
        }
      }
    }
    > a {
      height: 55px;
      width: 164px;
      border-radius: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(255, 255, 255, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      background: linear-gradient(
        to left,
        rgba(0, 82, 241, 1),
        rgba(0, 117, 255, 1)
      );
    }
  }
`;

export const PlanPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem !important;
  align-items: flex-end !important;
  > span {
    font-size: 18px;
    font-weight: 400;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 1);
  }
  > span:nth-child(2) {
    font-size: 14px;
  }
`;

export const Notification = styled.div`
  margin-top: 2.5rem;

  > h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 19.49px;
    color: rgba(255, 255, 255, 1);
  }
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid rgba(15, 110, 255, 1);
    border-radius: 12px;
    height: 137px;
    padding: 1rem 2rem;
    background-color: rgba(27, 125, 255, 0.1);
    margin-top: 1rem;

    > div {
      > h6 {
        font-size: 18px;
        font-weight: 500;
        line-height: 19.49px;
        color: rgba(255, 255, 255, 1);
      }
      > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.49px;
        color: rgba(255, 255, 255, 0.5);
        margin-top: 0.5rem;
      }
    }
  }
`;

export const BillingModal = styled(Modal)``;
export const BillingModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  outline: none;
  width: 575px;
  height: 570px;
  border: 1px solid rgba(15, 110, 255, 1);
  background: linear-gradient(
    to right,
    rgba(0, 37, 143, 1),
    rgba(0, 25, 99, 1)
  );
  color: #fff;
  border-radius: 20px;
  padding: 1.5rem;

  > div:nth-child(1) {
    display: flex;
    justify-content: flex-end;
    > img {
      cursor: pointer;
    }
  }

  > div:nth-child(2) {
    > h2 {
      font-size: 26px;
      font-weight: 500;
      line-height: 19.49px;
      color: rgba(255, 255, 255, 1);
    }
    > hr {
      border: none;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      margin: 1.5rem 0;
    }
    > button {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: rgba(255, 255, 255, 1);
      background: linear-gradient(
        to left,
        rgba(0, 82, 241, 1),
        rgba(0, 117, 255, 1)
      );
      width: 100%;
      height: 55px;
      border-radius: 12px;
      outline: none;
      border: none;
      cursor: pointer;
      margin-top: 2rem;
    }
  }
`;

export const DisplayFlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const InputTextField = styled(TextField)<{ active: Boolean }>`
  position: relative;
  width: 100%;
  margin: 15px 0 !important;
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: rgba(15, 110, 255, 1) !important;
    }
  }
  & .css-9425fu-MuiOutlinedInput-notchedOutline {
    border: ${(props) =>
      !props.active ? "none" : "1px solid rgba(15, 110, 255, 1)"};
    background-color: ${(props) =>
      !props.active ? "rgba(30, 30, 30, 0.15)" : "rgba(27, 125, 255, 0.1)"};
  }

  &
    .css-19rxewv-MuiInputBase-root-MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border: ${(props) =>
      !props.active ? "none" : "1px solid rgba(15, 110, 255, 1)"};
    background-color: ${(props) =>
      !props.active ? "rgba(30, 30, 30, 0.15)" : "rgba(27, 125, 255, 0.1)"};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    border: ${(props) => (props.active ? "none" : "1px solid transparent")};
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0.15)
      )
      border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
`;

export const ChangeAccountDetailsModal = styled(Modal)``;
export const ChangeAccountDetailsModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  outline: none;
  width: 537px;
  height: 426px;
  border: 1px solid rgba(15, 110, 255, 1);
  background: linear-gradient(
    to right,
    rgba(0, 37, 143, 1),
    rgba(0, 25, 99, 1)
  );
  color: #fff;
  border-radius: 20px;
  padding: 1.5rem;

  > div:nth-child(1) {
    display: flex;
    justify-content: flex-end;
    > img {
      cursor: pointer;
    }
  }
  > div:nth-child(2) {
    padding: 0 1rem;
    > h2 {
      font-size: 26px;
      font-weight: 500;
      line-height: 19.49px;
      color: rgba(255, 255, 255, 1);
      text-align: center;
    }
    > hr {
      border: none;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      margin: 2.5rem 0 1.5rem 0;
    }
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  margin-top: 2rem;
  > div:nth-child(1) {
    border: 1px solid rgba(245, 250, 255, 1);
    height: 61px;
    width: 61px;
    border-radius: 50%;
    > img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }
  > div:nth-child(2) {
    > div {
      display: flex;
      > input {
        border: none;
        background-color: transparent !important;
        color: rgba(255, 255, 255, 1) !important;
        font-size: 20px;
        font-weight: 400;
        line-height: 19.49px;
        max-width: 167px;
        :focus,
        :active {
          outline: none;
        }
      }
      > button {
        border: none;
        background-color: transparent;
        color: rgba(1, 124, 255, 1);
        font-size: 12px;
        font-weight: 500;
        line-height: 15.12px;
        text-decoration: underline;
        cursor: pointer;
        margin-left: 0.5rem;
      }
    }
    > p {
      color: rgba(255, 255, 255, 0.4);
      font-size: 16px;
      margin-top: 0.2rem;
      font-weight: 400;
      line-height: 19.49px;
    }
  }
`;
