import { Button, MenuItem, Popover } from "@mui/material";
import { motion } from "framer-motion";
import styled from "styled-components";

export const Wrapper = styled(motion.section)`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  @media only screen and (max-width: 900px) {
    padding: 0.5rem;
  }
`;

export const TitleFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  color: #fff;
  border-bottom: 1px solid #efefef4d;
  /* padding-bottom: 1rem; */
  margin-bottom: 2rem;

  sub {
    color: #fff;
    font-weight: 700;
  }

  p {
    color: #fff;
    display: flex;
    align-items: center;
  }
`;

export const CreateAdAccountBtn = styled.button`
  background: linear-gradient(
    to left,
    rgba(0, 102, 255, 1),
    rgba(0, 148, 255, 1)
  );
  height: 50px;
  margin-bottom: 1.5rem;
  width: 190px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  outline: none;
  border: none;
  color: white;
  > div {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: rgba(15, 110, 255, 1);
    font-size: 20px;
    font-weight: 500;
  }
`;

export const Filter = styled.div`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  padding: 1rem 2rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;

  h3 {
    color: #fff;
  }

  @media only screen and (max-width: 900px) {
    padding: 0.5rem;
    display: flex;
  }
`;

export const FilterLeft = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  row-gap: 1rem;
  margin-bottom: 0.5rem;

  h3 {
    margin-right: 1rem;
    width: 100%;
  }
`;

export const StatFlex = styled.div`
  display: flex;
  margin-bottom: 1rem;

  & > *:not(:last-child) {
    margin-right: 0.7rem;

    @media only screen and (max-width: 900px) {
      margin-right: 0;
    }
  }

  @media only screen and (max-width: 900px) {
    flex-wrap: wrap;
    gap: 0.7rem;
    align-items: center;
    justify-content: center;
  }
`;

export const StatCard = styled.div`
  height: 7rem;
  border-radius: 1rem;
  flex: 1;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  img {
    margin-right: 0.7rem;
  }
`;

export const StatData = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #00000066;
    font-size: 0.8rem;
    margin-bottom: 0.2rem;
  }
`;

export const StatDataFlex = styled.div`
  display: flex;
  align-items: flex-end;

  h4 {
    font-size: 1.5rem;
    margin: 0;
    /* line-height: 0; */
    display: flex;
    align-items: flex-end;
  }

  p {
    margin-left: 0.4rem;
    display: flex;
    align-items: center;
    color: #fc3f3f;
  }

  sub {
    font-size: 1rem;
    font-weight: 500;
    margin-right: 0.2rem;
  }
`;

export const ChartFlex = styled.div`
  display: flex;
  margin-bottom: 1rem;

  & > *:not(:last-child) {
    margin-right: 0.7rem;
  }
`;

type ChartCardProps = {
  padding?: string;
  width?: string;
  bg?: boolean;
};

export const ChartCard = styled.div<ChartCardProps>`
  border-radius: 1rem;
  padding: ${(props) => props.padding};
  /* height: 22rem; */
  flex: 1;
  background-color: #fff;
  background: ${(props) =>
    props.bg && "linear-gradient(303.19deg, #20D992 -29.75%, #049669 98.23%)"};

  max-width: ${(props) => props.width && props.width};
`;

export const CompleteDetail = styled.div`
  color: #fff;
  margin-top: 1rem;

  h2 {
    margin-bottom: 1rem;
    font-weight: 600;
  }
  p {
    line-height: 1.5;
    margin-bottom: 1rem;
    color: #ffffffcc;
  }
`;

export const GraphWrapper = styled.div`
  padding: 0 1rem 0rem;
  width: 100%;

  @media only screen and (max-width: 900px) {
    padding: 0;
  }
`;

export const TopFlex = styled.div`
  display: flex;
  padding: 1.5rem 1.5rem 0rem;

  img {
    margin-right: 0.5rem;
  }

  p {
    font-size: 0.8rem;
    color: #00000099;
  }
`;

export const BottomFlex = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

type BottomStatProps = {
  current?: boolean;
};

export const BottomStat = styled.div<BottomStatProps>`
  display: flex;
  padding: 0.2rem;
  background-color: ${(props) => props.current && "#E3EEFF"};

  h5 {
    font-size: 0.8rem;
    margin-right: 0.2rem;
    font-weight: 600;
  }

  p {
    font-size: 0.8rem;
  }
`;

export const GraphFlex = styled.div`
  display: flex;
  gap: 1rem;
`;

export const CampaignPerformance = styled.div`
  background-color: #fff;
  width: 65%;
  border-radius: 1rem;
  margin-bottom: 1rem;
  padding: 1.5rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;

  @media only screen and (max-width: 900px) {
    padding: 0.5rem;
  }
`;

export const CircularWrapper = styled.div`
  width: 11rem;
  height: 11rem;
  border: 1px solid #07875b;
  border-radius: 50%;
  padding: 0.6rem;
`;

export const WhiteButton = styled(Button)`
  background-color: #fff !important;
  color: #0b9f70 !important;
  text-transform: none !important;
  font-family: "Montserrat", sans-serif !important;
  border-radius: 0.6rem !important;
`;

export const LinkModalWrapper = styled.div`
  width: 30rem;
  height: 25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;

  h2 {
    font-weight: 600;
    margin-top: 0.5rem;
  }
`;

export const FacebookBtn = styled.div`
  margin-top: 1.5rem;
  background-color: #4267b2;
  color: #fff;
  border: none;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  /* width: 100%; */
  display: flex;
  align-items: center;
  font-size: 1rem;

  p {
    color: #fff;
    margin-left: 0.5rem;
  }
`;

export const AdAccountList = styled.ul<{ fbadaccountLoading: boolean }>`
  list-style: none;
  width: 100%;
  /* margin-top: 0.5rem; */
  /* margin-bottom: 1rem; */
  max-height: ${(props) => (props.fbadaccountLoading ? "100%" : "20rem")};
  overflow-y: ${(props) => (props.fbadaccountLoading ? "none" : "auto")};
  display: ${(props) => (props.fbadaccountLoading ? "flex" : "block")};
  justify-content: ${(props) =>
    props.fbadaccountLoading ? "center" : "inherit"};
  margin: ${(props) => (props.fbadaccountLoading ? "1rem 0" : "0.5rem 0 0 0")};

  &::-webkit-scrollbar {
    border-radius: 0.4rem;
    width: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    background-color: darkgrey;
    /* outline: 1px solid slategrey; */
  }
`;

type ListItemProps = {
  selected?: boolean;
};

export const ListItem = styled.li<ListItemProps>`
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) => props.selected && "rgba(27,125,255,0.2)"};
  cursor: pointer;
  border-radius: 0.3rem;

  h4 {
    font-weight: 400;
    font-size: 1rem;
  }

  p {
    color: #fff9;
    font-size: 0.9rem;
  }
`;

export const SelectAdAccountBtns = styled.div`
  display: flex;
  border-top: 1px dashed #fff9;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
`;

export const SelectAccountWrapper = styled.div`
  width: 35rem;
  /* height: 25rem; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: #fff; */
  background: linear-gradient(97.29deg, #00258f 10.08%, #001963 99.77%), #052275;
  border-radius: 1rem;
  padding: 2rem 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  outline: none;
  color: #fff;

  p {
    margin-bottom: 0.6rem;
  }

  h2 {
    font-weight: 600;
    margin-top: 0.5rem;
  }
`;

export const GreyButton = styled(Button)`
  background-color: #e1edff !important;
  color: #0f6eff !important;
  text-transform: none !important;
  font-family: "Montserrat", sans-serif !important;
  border-radius: 0.6rem !important;
`;

export const NoDataWrapper = styled.div`
  width: 30rem;
  /* height: 25rem; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;

  h3 {
    font-weight: 600;
    margin-bottom: 1.5rem;
  }

  img {
    width: 15rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: 0.5rem;
  }

  p {
    font-weight: 600;
  }
`;

export const DateRangeWrapper = styled.div`
  /* position: relative; */
  flex: 1;
  & > * {
    width: 100%;
  }
`;

export const DateRangePicker = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* @media only screen and (max-width: 900px) {
    height: 100vh;
  } */

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    /* background-color: #1976d2; */
    color: #1976d2;
    font-weight: 600;
    font-size: 1.2rem;
  }
`;

export const ResponsiveFilters = styled.div`
  /* background-color: #fff; */
  border-radius: 1rem;
  margin-bottom: 1em;
  padding: 0.5rem;
`;

export const ResponsiveFlex = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

export const BottomDrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  h3 {
    margin-bottom: 2rem;
    font-size: 1.4rem;
  }
`;

export const SearchInput = styled.div`
  margin: 1rem 0;
  width: 100%;
`;

export const CustomMenuItem = styled(MenuItem)`
  width: 20rem;
  display: flex;
  justify-content: space-between !important;
  align-items: center;

  span {
    font-size: 0.8rem;
    color: #ffffff99;
    opacity: 0;
  }

  &:hover span {
    opacity: 1;
  }
`;

export const CustomPopover = styled(Popover)`
  height: 36rem;
  /* margin-top: 1rem ; */

  .MuiPopover-paper {
    border-radius: 1rem !important;
    overflow: hidden !important;
  }
`;

export const SelectAdAccountDropdown = styled.div`
  border-radius: 1rem;
  background-color: #012385;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  ul {
    max-height: 25rem;
    width: 28rem;
    list-style: none;
    overflow-y: auto;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;

    &::-webkit-scrollbar {
      width: 0.4rem;
      border-radius: 0.5rem;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0.5rem;
      background-color: darkgrey;
      /* outline: 1px solid slategrey; */
    }

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper {
      border-radius: 1rem !important;
    }

    li {
      min-width: 20rem;
      padding: 0.8rem 1rem;
      border-bottom: 1px solid #324d9b;
      cursor: pointer;
      border-radius: 0.4rem;
      transition: all 0.2s;

      &:hover {
        background-color: rgba(27, 125, 255, 0.2);
      }

      span {
        color: #ffffff80;
      }
    }
  }
`;

export const CustomToolTip = styled.div`
  font-size: 0.9rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Automation = styled.div`
  margin-top: 1rem;
  margin-bottom: 1em;
  padding: 1rem 2rem 1rem 17rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
  position: relative;
  min-height: 11rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  & > *:last-child {
    width: 18rem;
  }

  img {
    position: absolute;
    left: 2rem;
    bottom: 1rem;
  }
`;

export const AutomationStat = styled.div`
  h3 {
    font-family: "Fredoka", sans-serif;
    color: #f4ac24;
    font-weight: 600;
    font-size: 1.5rem;
  }

  h2 {
    color: #fff;
    font-weight: 400;
    margin: 0.8rem 0;

    strong {
      color: #fcd338;
      font-size: 1.5rem;
    }
  }

  p {
    color: #ffffff99;
    line-height: 1.3rem;
  }

  .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary {
    background-color: #f4ac24;
    border-radius: 1rem;
  }
`;

export const Analyze = styled.div`
  margin-top: 1rem;
  margin-bottom: 1em;
  padding: 1rem 2rem;
  background: rgba(12, 14, 38, 0.15);
  border: 1.5px solid rgba(64, 79, 129, 0.58);
  backdrop-filter: blur(16px);
  border-radius: 1rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  min-height: 8rem;

  div {
    h2 {
      color: #fff;
      margin-bottom: 0.5rem;
    }

    p {
      color: #ffffff99;
      font-size: 1rem;
    }
  }
`;
