import React, { useEffect, useState } from "react";
import {
  CustomTableCell,
  CustomTableHead,
  TableHeadLabel,
  TableWrapper,
  Title,
  Wrapper,
} from "./CampaignTable.styles";
import { Skeleton, Table, TableBody, TableRow } from "@mui/material";
import SouthIcon from "@mui/icons-material/South";
import NorthIcon from "@mui/icons-material/North";
import {
  BiCollapseAlt,
  BiCollapseHorizontal,
  BiExpandHorizontal,
} from "react-icons/bi";

import { AlignValue, CampaignTableProps } from "./types";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import moment from "moment";
import { fetchSymbolFromCurrency } from "src/libs/utils";

const D2C_COL = [
  {
    name: "Campaign Name",
    key: "campaignName",
    headLeftCornerCell: true,
    headRightCornerCell: false,
    comparisonBtn: false,
    align: "left",
  },
  {
    name: "Purchases",
    key: "purchases",
    headLeftCornerCell: false,
    headRightCornerCell: false,
    comparisonBtn: true,
    align: "center",
  },
  {
    name: "Ad Spends",
    key: "adSpends",
    headLeftCornerCell: false,
    headRightCornerCell: false,
    comparisonBtn: true,
    align: "center",
  },
  {
    name: "ROAS",
    key: "roas",
    headLeftCornerCell: false,
    headRightCornerCell: false,
    comparisonBtn: true,
    align: "center",
  },
  {
    name: "Cost / Purchase",
    key: "costPerPurchase",
    headLeftCornerCell: false,
    headRightCornerCell: false,
    comparisonBtn: true,
    align: "center",
  },
  {
    name: "Add to carts",
    key: "addToCarts",
    headLeftCornerCell: false,
    headRightCornerCell: false,
    comparisonBtn: true,
    align: "center",
  },
  {
    name: "Initiated Checkouts",
    key: "initiatedCheckouts",
    headLeftCornerCell: false,
    headRightCornerCell: true,
    comparisonBtn: true,
    align: "center",
  },
];

const LEAD_GEN_COL = [
  {
    name: "Campaign Name",
    key: "campaignName",
    headLeftCornerCell: true,
    headRightCornerCell: false,
    comparisonBtn: false,
    align: "left",
  },
  {
    name: "Results",
    key: "results",
    headLeftCornerCell: false,
    headRightCornerCell: false,
    comparisonBtn: true,
    align: "center",
  },
  {
    name: "Ad spends",
    key: "adSpends",
    headLeftCornerCell: false,
    headRightCornerCell: false,
    comparisonBtn: true,
    align: "center",
  },
  {
    name: "Cost / Result",
    key: "costPerResult",
    headLeftCornerCell: false,
    headRightCornerCell: false,
    comparisonBtn: true,
    align: "center",
  },
  {
    name: "Reach",
    key: "reach",
    headLeftCornerCell: false,
    headRightCornerCell: false,
    comparisonBtn: true,
    align: "center",
  },
  {
    name: "Impressions",
    key: "impressions",
    headLeftCornerCell: false,
    headRightCornerCell: true,
    comparisonBtn: true,
    align: "center",
  },
];

const CampaignTable = ({
  ad_account,
  euid,
  date_start,
  date_stop,
  campaign_list,
  preset,
  isComparison,
}: CampaignTableProps) => {
  const [sortCol, setSortCol] = useState("");
  const [sortType, setSortType] = useState("ascending");
  const [type, setType] = useState("d2c");
  const [tableRows, setTableRows] = useState<any[]>([]);
  const [noTableData, setNoTableData] = useState<boolean>(false);
  const [expandedCol, setExpandedCol] = useState<string>("");
  const { user, loading: userLoading } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();
  const {
    data,
    prevDate,
    afterDate,
    prevInsight,
    afterInsight,
    loading,
    comparison,
  } = useAppSelector((state) => state.allCampaignInsight);

  useEffect(() => {
    setSortCol("");
  }, [prevInsight, afterInsight, data]);

  // useEffect(() => {
  //   let start = new Date(`${date_start}`);
  //   start = new Date(start.setDate(start.getDate() + 1));
  //   let end = new Date(`${date_stop}`);
  //   end = new Date(end.setDate(end.getDate() + 1));

  //   if (euid && ad_account) {
  //     dispatch(
  //       getAllCampaignInsight({
  //         euid: euid,
  //         ad_account: ad_account,
  //         date_start: start.toISOString(),
  //         date_stop: end.toISOString(),
  //         campaign_list: campaign_list,
  //         preset: preset?.val,
  //       })
  //     )
  //   }
  // }, [campaign_list, euid, ad_account, date_start, date_stop, preset?.val]);

  useEffect(() => {
    if (data.allCampaings === null) {
      setNoTableData(true);
    } else {
      setNoTableData(false);
      setType(data?.type);
      setTableRows(data?.allCampaings);
    }
  }, [data]);

  const setSortValues = (sortColName: string) => {
    if (sortColName === sortCol) {
      setSortType(sortType === "ascending" ? "descending" : "ascending");
    } else {
      setSortCol(sortColName);
      setSortType("ascending");
    }
  };

  useEffect(() => {
    let tmp1 = [];
    if (isComparison) {
      tmp1 = afterInsight?.allCampaings ? [...afterInsight.allCampaings] : [];
    } else {
      tmp1 = data?.allCampaings ? [...data.allCampaings] : [];
    }

    if (sortCol === "purchases") {
      tmp1.sort((a, b) => b?.purchases - a?.purchases);
    } else if (sortCol === "costPerResult") {
      tmp1 = tmp1.map((item) => {
        return {
          ...item,
          costPerResult: isNaN(item?.cost) ? "0" : item?.cost,
        };
      });
      tmp1.sort((a, b) => b.costPerResult - a.costPerResult);
    } else if (sortCol === "reach") {
      tmp1.sort((a, b) => b.reach - a.reach);
    } else if (sortCol === "results") {
      tmp1.sort((a, b) => b.purchases - a.purchases);
    } else if (sortCol === "impressions") {
      tmp1.sort((a, b) => b.impressions - a.impressions);
    } else if (sortCol === "adSpends") {
      tmp1.sort((a, b) => b.spend - a.spend);
    } else if (sortCol === "roas") {
      tmp1 = tmp1.map((item) => {
        return {
          ...item,
          roas: isNaN(item?.roas) ? "0" : item?.roas,
        };
      });
      tmp1.sort((a, b) => b.roas - a.roas);
    } else if (sortCol === "costPerPurchase") {
      tmp1 = tmp1.map((item) => {
        return {
          ...item,
          costPerPurchase: isNaN(item?.cost) ? "0" : item?.cost,
        };
      });

      tmp1.sort((a, b) => b.costPerPurchase - a.costPerPurchase);
    } else if (sortCol === "addToCarts") {
      tmp1.sort((a, b) => b.carts - a.carts);
    } else if (sortCol === "initiatedCheckouts") {
      tmp1.sort((a, b) => b.checkouts - a.checkouts);
    } else if (sortCol === "campaignName") {
      tmp1.sort((a, b) =>
        b?.campaign_name
          ?.toLowerCase()
          .localeCompare(a?.campaign_name.toLowerCase())
      );
    }

    if (sortType === "descending") {
      tmp1.reverse();
    }

    setTableRows(tmp1);
  }, [sortCol, sortType]);

  const get_expanded_col_for_d2c = () => {
    if (expandedCol === "purchases") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    } else if (expandedCol === "adSpends") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    } else if (expandedCol === "roas") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    } else if (expandedCol === "costPerPurchase") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    } else if (expandedCol === "addToCarts") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    } else if (expandedCol === "initiatedCheckouts") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    }
  };

  const get_expanded_col_for_lead_gen = () => {
    if (expandedCol === "results") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    } else if (expandedCol === "adSpends") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    } else if (expandedCol === "costPerResult") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    } else if (expandedCol === "reach") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    } else if (expandedCol === "impressions") {
      return (
        <>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell></CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(prevDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(prevDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>
                {moment(afterDate?.startDate).format("DD/MM/YYYY")} -{" "}
                {moment(afterDate?.endDate).format("DD/MM/YYYY")}
              </p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change</p>
            </TableHeadLabel>
          </CustomTableCell>
          <CustomTableCell headCell>
            <TableHeadLabel>
              <p>Change (%)</p>
            </TableHeadLabel>
          </CustomTableCell>
        </>
      );
    }
  };

  const get_after_insight_val = (col: string, id: string) => {
    if (afterInsight?.allCampaings) {
      const campaign = afterInsight?.allCampaings?.filter(
        (item: any) => item.campaign_id === id
      )[0];
      if (!campaign) return "-";
      if (col === "purchases") return campaign?.purchases || "-";
      else if (col === "adSpends")
        return parseFloat(campaign?.spend).toFixed(0)
          ? `${
              user.data.currency
                ? user.data.currency === "INR"
                  ? "₹"
                  : user.data.currency === "USD"
                  ? "$"
                  : "₹"
                : "₹"
            } ${parseFloat(campaign?.spend).toFixed(0)}`
          : "-";
      else if (col === "roas")
        return isNaN(Number(parseFloat(campaign?.roas).toFixed(1)))
          ? "-"
          : campaign?.roas === 0
          ? "-"
          : parseFloat(campaign?.roas).toFixed(1);
      else if (col === "costPerPurchase")
        return isNaN(Number(parseFloat(campaign?.cost).toFixed(0)))
          ? "-"
          : `${
              user.data.currency
                ? user.data.currency === "INR"
                  ? "₹"
                  : user.data.currency === "USD"
                  ? "$"
                  : "₹"
                : "₹"
            } ${parseFloat(campaign?.cost).toFixed(0)}`;
      else if (col === "addToCarts") return campaign?.carts || "-";
      else if (col === "initiatedCheckouts") return campaign?.checkouts || "-";
      else if (col === "costPerResult")
        return isNaN(Number(parseFloat(campaign?.cost).toFixed(0)))
          ? "-"
          : `${
              user.data.currency
                ? user.data.currency === "INR"
                  ? "₹"
                  : user.data.currency === "USD"
                  ? "$"
                  : "₹"
                : "₹"
            } ${parseFloat(campaign?.cost).toFixed(0)}`;
      else if (col === "impressions") return campaign?.impressions || "-";
      else if (col === "results") return campaign?.purchases || "-";
      else if (col === "reach") return campaign?.reach || "-";
    } else {
      return "-";
    }
  };

  const get_prev_insight_val = (col: string, id: string) => {
    if (prevInsight?.allCampaings) {
      const campaign = prevInsight?.allCampaings?.filter(
        (item: any) => item.campaign_id === id
      )[0];
      console.log(campaign, id, "CAMPAIGN");
      if (!campaign) return "-";
      if (col === "purchases") return campaign?.purchases || "-";
      else if (col === "adSpends")
        return parseFloat(campaign.spend).toFixed(0)
          ? `${
              user.data.currency
                ? user.data.currency === "INR"
                  ? "₹"
                  : user.data.currency === "USD"
                  ? "$"
                  : "₹"
                : "₹"
            } ${parseFloat(campaign?.spend).toFixed(0)}`
          : "-";
      else if (col === "roas")
        return isNaN(Number(parseFloat(campaign?.roas).toFixed(1)))
          ? "-"
          : campaign?.roas === 0
          ? "-"
          : parseFloat(campaign?.roas).toFixed(1);
      else if (col === "costPerPurchase")
        return isNaN(Number(parseFloat(campaign?.cost).toFixed(0)))
          ? "-"
          : `${
              user.data.currency
                ? user.data.currency === "INR"
                  ? "₹"
                  : user.data.currency === "USD"
                  ? "$"
                  : "₹"
                : "₹"
            } ${parseFloat(campaign?.cost).toFixed(0)}`;
      else if (col === "addToCarts") return campaign?.carts || "-";
      else if (col === "initiatedCheckouts") return campaign?.checkouts || "-";
      else if (col === "costPerResult")
        return isNaN(Number(parseFloat(campaign?.cost).toFixed(0)))
          ? "-"
          : `${
              user.data.currency
                ? user.data.currency === "INR"
                  ? "₹"
                  : user.data.currency === "USD"
                  ? "$"
                  : "₹"
                : "₹"
            } ${parseFloat(campaign?.cost).toFixed(0)}`;
      else if (col === "impressions") return campaign?.impressions || "-";
      else if (col === "results") return campaign?.purchases || "-";
      else if (col === "reach") return campaign?.reach || "-";
    } else {
      return "-";
    }
  };

  const get_insight_diff = (col: string, id: string) => {
    const prevVal = get_prev_insight_val(col, id);
    const afterVal = get_after_insight_val(col, id);

    if (
      col === "adSpends" ||
      col === "costPerPurchase" ||
      col === "colPerResult"
    ) {
      if (prevVal === "-" || afterVal === "-") {
        return "-";
      } else {
        const prevSpend = prevVal.split(" ")[1];
        const afterSpend = afterVal.split(" ")[1];

        if (isNaN(prevSpend) || isNaN(afterSpend)) {
          return "-";
        } else {
          return `${
            user.data.currency
              ? user.data.currency === "INR"
                ? "₹"
                : user.data.currency === "USD"
                ? "$"
                : "₹"
              : "₹"
          } ${parseFloat(
            String(Number(afterSpend) - Number(prevSpend))
          ).toFixed(0)}`;
        }
      }
    }

    if (isNaN(prevVal) || isNaN(afterVal)) {
      return "-";
    } else {
      return Number.isInteger(afterVal - prevVal)
        ? afterVal - prevVal
        : parseFloat(String(afterVal - prevVal)).toFixed(2);
    }
  };

  const get_insight_percentage_diff = (col: string, id: string) => {
    const prevVal = get_prev_insight_val(col, id);
    const afterVal = get_after_insight_val(col, id);

    if (
      col === "adSpends" ||
      col === "costPerPurchase" ||
      col === "costPerResult"
    ) {
      if (prevVal === "-" || afterVal === "-") {
        return "-";
      } else {
        const prevSpend = prevVal.split(" ")[1];
        const afterSpend = afterVal.split(" ")[1];

        if (isNaN(prevSpend) || isNaN(afterSpend)) {
          return "-";
        } else {
          const percentage =
            ((Number(afterSpend) - Number(prevSpend)) / Number(prevSpend)) *
            100;
          return `${parseFloat(String(percentage)).toFixed(2)} %`;
        }
      }
    }

    if (isNaN(prevVal) || isNaN(afterVal)) {
      return "-";
    } else {
      const percentage =
        ((Number(afterVal) - Number(prevVal)) / Number(prevVal)) * 100;
      return `${parseFloat(String(percentage)).toFixed(2)} %`;
    }
  };

  if (loading) {
    return (
      <Wrapper>
        <Title>
          <h3>Your Campaigns</h3>
        </Title>
        <Skeleton variant="rounded" height={400} />
      </Wrapper>
    );
  } else if (type === "LEAD_GENERATION" && !loading && !noTableData) {
    return (
      <Wrapper>
        <Title>
          <h3>
            Your Campaigns{" "}
            <p>
              (
              {comparison
                ? afterInsight?.allCampaings?.length
                : data?.allCampaings?.length || 0}
              )
            </p>
          </h3>
        </Title>
        <TableWrapper>
          <Table
            sx={{
              width: isComparison ? "max-content" : "100%",
              margin: "auto",
            }}
          >
            <CustomTableHead>
              <TableRow>
                {LEAD_GEN_COL.map((item, i) => {
                  return (
                    <CustomTableCell
                      key={i}
                      align={item.align as AlignValue}
                      headCell
                      colSpan={expandedCol === item.key ? 4 : 1}
                    >
                      <TableHeadLabel
                        headCell
                        headLeftCornerCell={item.headLeftCornerCell}
                        headRightCornerCell={item.headRightCornerCell}
                        style={{
                          justifyContent:
                            item.key === "campaignName"
                              ? "flex-start"
                              : undefined,
                          flex: "1",
                        }}
                      >
                        <p onClick={() => setSortValues(item.key)}>
                          {item.name}
                        </p>
                        {sortCol === item.key && sortType === "ascending" && (
                          <NorthIcon />
                        )}
                        {sortCol === item.key && sortType === "descending" && (
                          <SouthIcon />
                        )}
                        {item.comparisonBtn && comparison ? (
                          expandedCol === item.key ? (
                            <BiCollapseHorizontal
                              onClick={() => setExpandedCol("")}
                              size="1.2rem"
                            />
                          ) : (
                            <BiExpandHorizontal
                              onClick={() => setExpandedCol(item.key)}
                              size="1.2rem"
                            />
                          )
                        ) : null}
                      </TableHeadLabel>
                    </CustomTableCell>
                  );
                })}
              </TableRow>
              {expandedCol && (
                <TableRow>{get_expanded_col_for_lead_gen()}</TableRow>
              )}
            </CustomTableHead>

            <TableBody
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {tableRows?.map((row: any, i) => {
                return (
                  <TableRow key={i}>
                    <CustomTableCell firstCell>
                      {row?.campaign_name}
                    </CustomTableCell>
                    {expandedCol === "results" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val("results", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val("results", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff("results", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "results",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell align="right">
                        <p>
                          {comparison
                            ? get_after_insight_val("results", row?.campaign_id)
                            : row?.results || "-"}
                        </p>
                        {comparison && (
                          <span>
                            {get_insight_diff("results", row?.campaign_id)}
                          </span>
                        )}
                      </CustomTableCell>
                    )}
                    {expandedCol === "adSpends" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val("adSpends", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val("adSpends", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff("adSpends", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "adSpends",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell align="right">
                        <p>
                          {comparison
                            ? get_after_insight_val(
                                "adSpends",
                                row?.campaign_id
                              )
                            : parseFloat(row?.spend).toFixed(0)
                            ? `${
                                user.data.currency
                                  ? fetchSymbolFromCurrency(user.data.currency)
                                  : "₹"
                              } ${parseFloat(row?.spend).toFixed(0)}`
                            : "-"}
                        </p>
                        {comparison && (
                          <span>
                            {get_insight_diff("adSpends", row?.campaign_id)}
                          </span>
                        )}
                      </CustomTableCell>
                    )}
                    {expandedCol === "costPerResult" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val(
                            "costPerResult",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val(
                            "costPerResult",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff("costPerResult", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "costPerResult",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell align="right">
                        <p>
                          {comparison
                            ? get_after_insight_val(
                                "costPerResult",
                                row?.campaign_id
                              )
                            : isNaN(Number(parseFloat(row?.cost).toFixed(0)))
                            ? "-"
                            : `${
                                user.data.currency
                                  ? fetchSymbolFromCurrency(user.data.currency)
                                  : "₹"
                              } ${parseFloat(row?.cost).toFixed(0)}`}
                        </p>
                        {comparison && (
                          <span>
                            {get_insight_diff(
                              "costPerResult",
                              row?.campaign_id
                            )}
                          </span>
                        )}
                      </CustomTableCell>
                    )}
                    {expandedCol === "reach" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val("reach", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val("reach", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff("reach", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "reach",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell align="right">
                        <p>
                          {comparison
                            ? get_after_insight_val("reach", row?.campaign_id)
                            : row?.reach || "-"}
                        </p>
                        <span>
                          {get_insight_diff("reach", row?.campaign_id)}
                        </span>
                      </CustomTableCell>
                    )}
                    {expandedCol === "impressions" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val(
                            "impressions",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val(
                            "impressions",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff("impressions", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "impressions",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell align="right">
                        <p>
                          {comparison
                            ? get_after_insight_val(
                                "impressions",
                                row?.campaign_id
                              )
                            : row?.impressions || "-"}
                        </p>
                        <span>
                          {get_insight_diff("impressions", row?.campaign_id)}
                        </span>
                      </CustomTableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableWrapper>
      </Wrapper>
    );
  } else if (type === "D2C" && !loading && !noTableData) {
    return (
      <Wrapper>
        <Title>
          <h3>
            Your Campaigns{" "}
            <p>
              ({" "}
              {comparison
                ? afterInsight?.allCampaings?.length
                : data?.allCampaings?.length || 0}
              )
            </p>
          </h3>
        </Title>
        <TableWrapper>
          <Table
            sx={{
              width: isComparison ? "max-content" : "100%",
              margin: "auto",
            }}
          >
            <CustomTableHead>
              <TableRow>
                {D2C_COL.map((item, i) => {
                  return (
                    <CustomTableCell
                      key={i}
                      align={item.align as AlignValue}
                      headCell
                      colSpan={expandedCol === item.key ? 4 : 1}
                    >
                      <TableHeadLabel
                        headCell
                        headLeftCornerCell={item.headLeftCornerCell}
                        headRightCornerCell={item.headRightCornerCell}
                        style={{
                          justifyContent:
                            item.key === "campaignName"
                              ? "flex-start"
                              : undefined,
                          flex: "1",
                        }}
                      >
                        <p onClick={() => setSortValues(item.key)}>
                          {item.name}
                        </p>
                        {sortCol === item.key && sortType === "ascending" && (
                          <NorthIcon />
                        )}
                        {sortCol === item.key && sortType === "descending" && (
                          <SouthIcon />
                        )}
                        {item.comparisonBtn && comparison ? (
                          expandedCol === item.key ? (
                            <BiCollapseHorizontal
                              onClick={() => setExpandedCol("")}
                              size="1.2rem"
                            />
                          ) : (
                            <BiExpandHorizontal
                              onClick={() => setExpandedCol(item.key)}
                              size="1.2rem"
                            />
                          )
                        ) : null}
                      </TableHeadLabel>
                    </CustomTableCell>
                  );
                })}
              </TableRow>
              {expandedCol && <TableRow>{get_expanded_col_for_d2c()}</TableRow>}
            </CustomTableHead>
            <TableBody>
              {tableRows?.map((row: any, i) => {
                return (
                  <TableRow key={i} sx={{ borderBottom: "1px solid #304685" }}>
                    <CustomTableCell firstCell>
                      {row?.campaign_name}
                    </CustomTableCell>
                    {expandedCol === "purchases" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val("purchases", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val("purchases", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff("purchases", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "purchases",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell>
                        <p>
                          {comparison
                            ? get_after_insight_val(
                                "purchases",
                                row?.campaign_id
                              )
                            : row?.purchases || "-"}
                        </p>
                        {comparison && (
                          <span>
                            {get_insight_diff("purchases", row?.campaign_id)}
                          </span>
                        )}
                      </CustomTableCell>
                    )}
                    {expandedCol === "adSpends" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val("adSpends", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val("adSpends", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff("adSpends", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "adSpends",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell>
                        <p>
                          {comparison
                            ? get_after_insight_val(
                                "adSpends",
                                row?.campaign_id
                              )
                            : parseFloat(row?.spend).toFixed(0)
                            ? `${fetchSymbolFromCurrency(
                                user.data.currency
                              )} ${parseFloat(row?.spend).toFixed(0)}`
                            : "-"}
                        </p>
                        {comparison && (
                          <span>
                            {get_insight_diff("adSpends", row?.campaign_id)}
                          </span>
                        )}
                      </CustomTableCell>
                    )}
                    {expandedCol === "roas" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val("roas", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val("roas", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff("roas", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "roas",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell>
                        <p>
                          {comparison
                            ? get_after_insight_val("roas", row?.campaign_id)
                            : isNaN(Number(parseFloat(row?.roas).toFixed(1)))
                            ? "-"
                            : row?.roas === 0
                            ? "-"
                            : parseFloat(row?.roas).toFixed(1)}
                        </p>
                        {comparison && (
                          <span>
                            {get_insight_diff("roas", row?.campaign_id)}
                          </span>
                        )}
                      </CustomTableCell>
                    )}
                    {expandedCol === "costPerPurchase" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val(
                            "costPerPurchase",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val(
                            "costPerPurchase",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff(
                            "costPerPurchase",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "costPerPurchase",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell>
                        <p>
                          {comparison
                            ? get_after_insight_val(
                                "costPerPurchase",
                                row?.campaign_id
                              )
                            : isNaN(Number(parseFloat(row?.cost).toFixed(0)))
                            ? "-"
                            : `${
                                user.data.currency === "INR" ? "₹" : "$"
                              } ${parseFloat(row?.cost).toFixed(0)}`}
                        </p>
                        {comparison && (
                          <span>
                            {get_insight_diff(
                              "costPerPurchase",
                              row?.campaign_id
                            )}
                          </span>
                        )}
                      </CustomTableCell>
                    )}
                    {expandedCol === "addToCarts" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val("addToCarts", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val(
                            "addToCarts",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff("addToCarts", row?.campaign_id)}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "addToCarts",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell>
                        <p>
                          {comparison
                            ? get_after_insight_val(
                                "addToCarts",
                                row?.campaign_id
                              )
                            : row?.carts || "-"}
                        </p>
                        {comparison && (
                          <span>
                            {get_insight_diff("addToCarts", row?.campaign_id)}
                          </span>
                        )}
                      </CustomTableCell>
                    )}
                    {expandedCol === "initiatedCheckouts" ? (
                      <>
                        <CustomTableCell>
                          {get_prev_insight_val(
                            "initiatedCheckouts",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_after_insight_val(
                            "initiatedCheckouts",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_diff(
                            "initiatedCheckouts",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                        <CustomTableCell>
                          {get_insight_percentage_diff(
                            "initiatedCheckouts",
                            row?.campaign_id
                          )}
                        </CustomTableCell>
                      </>
                    ) : (
                      <CustomTableCell>
                        <p>
                          {comparison
                            ? get_after_insight_val(
                                "initiatedCheckouts",
                                row?.campaign_id
                              )
                            : row?.checkouts || "-"}
                        </p>
                        {comparison && (
                          <span>
                            {get_insight_diff(
                              "initiatedCheckouts",
                              row?.campaign_id
                            )}
                          </span>
                        )}
                      </CustomTableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableWrapper>
      </Wrapper>
    );
  } else if (!loading && noTableData) {
    return (
      <Wrapper>
        <Title>
          <h3>Your Campaigns</h3>
        </Title>
        <p>No campaign data</p>
      </Wrapper>
    );
  } else {
    return null;
  }
};

export default CampaignTable;
