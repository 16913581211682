import styled from 'styled-components';
import { MenuItem } from '@mui/material';

export const DateRangeWrapper = styled.div`
  /* position: relative; */
  width: max-content ;
  display: flex;

  background-color: #113294 ;
  flex: 1;


  & > * {
    width: 100%;
  }
`;

export const CustomMenuItem = styled(MenuItem)`
  width: 20rem;
  display: flex;
  justify-content: space-between !important;
  align-items: center;

  span {
    font-size: 0.8rem;
    color: #ffffff99;
    opacity: 0;
  }

  &:hover span {
    opacity: 1;
  }
`;

export const HorizontalCalendarWrapper = styled.div`
  background-color: #fff;
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5rem;

  p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    /* background-color: #1976d2; */
    color: #1976d2;
    font-weight: 600;
    font-size: 1.2rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  background-color: #fff;
  width: 100%;
  /* height: 4rem; */
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
`;

export const TopbarFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.5rem;
`
export const Left = styled.div`
  width: 12rem;
  border-right: 1px solid #FFFFFF42;
  margin: 1.6rem 0 ;

  ul {
    list-style: none;
    position: relative;
  }

  li {
    font-size: 1rem;
    padding: 0 1.5rem ;
    height: 2.5rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`

type ListIndicatorProps = {
  top: string
}

export const ListIndicator = styled.div<ListIndicatorProps>`
  position: absolute;
  left: 0;
  top: ${props => props.top};
  width: 100%;
  height: 2.5rem;
  background-color: #0F6EFF2B;
  border-left: 4px solid #0F6EFF;
  transition: all .3s ;
`

export const Middle = styled.div`
  width: 25rem;
  margin: 1.6rem 0 ;
  padding: 0 1.5rem;
`

export const Right = styled.div`
  width: 25rem;
  height: 28rem;
  background-color: #001C6C;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

export const DateInputFlex = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
  margin-top: 0.8rem;
`

export const BtnFlex = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem ;
  margin-bottom: 1rem ;
`