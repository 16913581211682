import styled from "styled-components";
import loginMainBg from "../../assets/loginMainBg.webp";
import { Button } from "@mui/material";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff;
  padding: 1rem;
  display: flex;
  background-image: url(${loginMainBg});
  background-size: cover;

  color: #fff;
  position: relative;

  @media only screen and (max-width: 800px) {
    min-height: 100vh;
    height: max-content;
  }
`;

export const LoginTopRightImg = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Left = styled.div`
  width: 50%;
  height: 100%;
  position: relative;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;

export const BgImg = styled.img`
  height: 100%;
  width: 105%;
  position: absolute;
  z-index: 1;
`;

export const LeftContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 100%;
  z-index: 2;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;

  h3 {
    color: #ffffff99;
  }

  h2 {
    display: flex;
    align-items: flex-end;
    font-size: 2rem;

    h3 {
      color: rgba(0, 209, 255, 1);
      text-decoration: underline;
    }
  }

  p {
    color: #ffffff99;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 75vh;

  h2 {
    font-weight: 600;
    font-size: 2rem;
  }

  img {
    margin-right: 0.8rem;
    width: 150px;
  }
`;

export const FeatureImg = styled.img`
  width: 20rem;
  margin: 3rem 0;
`;

export const RightDetail = styled.div`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    display: flex;
    align-items: flex-end;
    font-size: 32px;
    font-weight: 700;
    line-height: 19.49px;

    h3 {
      color: rgba(0, 209, 255, 1);
      text-decoration: underline;
      font-weight: 800;
    }
  }

  p {
    margin-top: 3rem;
    margin-bottom: 10vh;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.5px;
    color: rgba(255, 255, 255, 0.6);
  }

  @media only screen and (max-width: 1000px) {
    display: flex;
  }
`;

export const Right = styled.div`
  display: flex;
  /* height: 100%; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0rem 8rem;
  flex: 1;

  @media only screen and (max-width: 800px) {
    justify-content: flex-start;
  }

  @media only screen and (max-width: 600px) {
    padding: 1rem;
  }
`;

export const SocialLogin = styled.div`
  text-align: center;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 1.4rem;
  }
  p {
    color: #0b1a3366;
  }
`;

export const GoogleBtn = styled.div`
  margin: 1rem 0 0.6rem;
  position: relative;
  /* width: 10rem; */
  cursor: pointer;
  /* border: 1px solid black; */
  padding: 1rem;

  #googleLoginBtn {
    position: absolute;
    top: 0;
    left: 50%;
    opacity: 0;
    z-index: 2;
    transform: translateX(-50%);
    /* width: max-content; */
    background-color: green;
  }
`;

export const ActualBtn = styled.div`
  position: absolute;
  top: 0;

  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  z-index: 1;
  transform: translateX(-50%);
  border: 1px solid #eee;
  padding: 0.6rem;
  border-radius: 0.5rem;

  p {
    flex-shrink: 0;
    color: #fff;
    font-size: 1rem;
  }

  img {
    width: 2rem;
    height: 2rem;
    object-fit: contain;
    margin-right: 0.6rem;
  }
`;

export const OrDivider = styled.p`
  margin: 2rem 0;
  position: relative;
  color: #ffffff99;

  &::before {
    content: "";
    width: 8rem;
    height: 0.6px;
    background-color: #ffffff80;
    position: absolute;
    left: 0%;
    top: 50%;
    transform: translate(-110%, -50%);

    @media only screen and (max-width: 500px) {
      width: 50%;
    }
  }

  &::after {
    content: "";
    width: 8rem;
    height: 0.6px;
    background-color: #ffffff80;
    position: absolute;
    right: 0%;
    top: 50%;
    transform: translate(110%, -50%);

    @media only screen and (max-width: 500px) {
      width: 50%;
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputLabel = styled.label``;

export const Input = styled.div`
  margin-bottom: 1.5rem;
`;

export const FormBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: -1rem;
`;

export const FormBottomLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const SubmitBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 0.5rem;
  }
`;

export const SignupBtn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 0.5rem;
  }
  > button {
    color: white !important;
  }
`;

export const WrongPassword = styled.div`
  width: 30rem;
  /* height: 25rem; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;

  h3 {
    font-weight: 600;
    margin-bottom: 1rem;
  }

  img {
    width: 15rem;
  }
`;

export const TermsCondition = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  > input[type="checkbox"] {
    height: 20px;
    width: 20px;
  }

  > label {
    cursor: pointer;
    text-decoration: none;
    margin-left: 0.4rem;
  }

  > a {
    color: white;
    margin-left: 0.2rem;
  }
`;

export const VerifyOtp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 472px;
  max-width: 472px;
  width: 100%;

  > h2 {
    font-size: 32px;
    line-height: 40px;
    color: white;
    text-align: center;
  }

  > p {
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
  }
`;

export const EnteredEmailContainer = styled.div`
  margin: 2rem 0;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  > span {
    color: white;
    font-size: 16px;
    line-height: 26px;
  }
  > img {
    cursor: pointer;
  }
`;

export const TimerContainer = styled.div``;

export const Timer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.5rem;
  gap: 0.2rem;
`;

export const VerifyBtn = styled.button<{ disabled: boolean }>`
  background: linear-gradient(
    to left,
    rgba(0, 102, 255, 1),
    rgba(0, 148, 255, 1)
  );
  height: 50px;
  width: 472px;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  margin: 2.5rem auto 0 auto;
  opacity: ${(props) =>
    props.disabled ? "80% !important" : "100% !important"};

  font-size: 16px;
  line-height: 20px;
  color: white;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;

  &:hover {
    opacity: 90% !important;
  }
`;

export const ResendOtp = styled.div<{ hasCountdownEnded: boolean }>`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 0.4rem;

  > p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    line-height: 24px;
  }

  > button {
    color: rgba(25, 119, 243, 1);
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: ${(props) => (props.hasCountdownEnded ? "pointer" : "not-allowed")};
  }
`;

export const RequestForOtp = styled(Button)<{ disabled: boolean }>`
  justify-content: space-between !important;
  opacity: ${(props) =>
    props.disabled ? "80% !important" : "100% !important"};
  &:hover {
    opacity: 90% !important;
  }
`;

export const LoginWithPasswordBtn = styled(Button)<{ disabled: boolean }>`
  justify-content: space-between !important;
  opacity: ${(props) =>
    props.disabled ? "80% !important" : "100% !important"};
  &:hover {
    opacity: 90% !important;
  }
`;

export const SignupWithPasswordBtn = styled(Button)<{ disabled: boolean }>`
  justify-content: space-between !important;
  opacity: ${(props) =>
    props.disabled ? "80% !important" : "100% !important"};
  &:hover {
    opacity: 90% !important;
  }
`;
