import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import UNIVERSAL from "src/config/config";
import { ResponseSuccess } from "src/interfaces/api";

export type createNewAdAccountProps = {
  account_name: string;
  euid: number;
};

export const adAccountApi = createApi({
  reducerPath: "adAccountApi",
  baseQuery: fetchBaseQuery({
    baseUrl: UNIVERSAL.BASEURL,
  }),
  tagTypes: ["adAccount"],
  endpoints: (builder) => ({
    // eslint-disable-next-line
    createNewAdAccount: builder.mutation<any, createNewAdAccountProps>({
      query: (body) => ({
        url: `/api/v1/wallet/get_subscription_details`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
  }),
});

export const { useCreateNewAdAccountMutation } = adAccountApi;
