import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import UNIVERSAL from "src/config/config";
import { ResponseSuccess } from "src/interfaces/api";

export type getSubscriptionDetailsProps = {
  env: string;
};

export type getUserSubsDetailsProps = {
  ad_account: string;
  euid: number;
};

export type getZohoCustomerProps = {
  euid: number;
};

export type freeTrailActivationProps = {
  ad_account_id: string;
  amount: number;
  euid: number;
  plan_id: string;
  tax: number;
  total_amount: number;
  email: string;
};

export type getSubscriptionLinkProps = {
  euid: number;
  ad_account_id: string;
  plan_id: string;
};

export type createSubscriptionLinkProps = {
  ad_account_id: string;
  amount: number;
  email: string;
  euid: number;
  plan_id: string;
  subscription_id: string;
  tax: number;
  total_amount: number;
};

export type activeUserSubscriptionProps = {
  euid: number;
  ad_account_id: string;
};

export type updateSubscriptionProps = {
  subscription_id: string;
  euid: number;
  ad_account_id: string;
  plan_id: string;
};

export type updateSubscriptionStripeProps = {
  ad_account_id: string;
  amount: number;
  email: string;
  euid: number;
  plan_id: string;
  subscription_id: string;
  tax: number;
  total_amount: number;
};

export type subscriptionRenewProps = {
  euid: number;
  ad_account_id: string;
  plan_id: string;
};

export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: UNIVERSAL.BASEURL,
  }),
  tagTypes: ["subscription"],
  endpoints: (builder) => ({
    // eslint-disable-next-line
    getSubscriptionDetails: builder.query<any, getSubscriptionDetailsProps>({
      query: (body) => ({
        url: `/api/v1/wallet/get_subscription_details`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    createSubscriptionStripe: builder.mutation<
      any,
      createSubscriptionLinkProps
    >({
      query: (body) => ({
        url: `/api/v1/wallet/create_subscription_stripe`,
        method: "POST",
        body,
      }),
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    activeUserSubscription: builder.mutation<any, activeUserSubscriptionProps>({
      query: (body) => ({
        url: `/api/v1/wallet/get_active_user_subscriptions `,
        method: "POST",
        body,
      }),
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    updateSubscription: builder.mutation<any, updateSubscriptionProps>({
      query: (body) => ({
        url: `/api/v1/wallet/update_subscription`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    updateSubscriptionStripe: builder.mutation<
      any,
      updateSubscriptionStripeProps
    >({
      query: (body) => ({
        url: `/api/v1/wallet/update_subscription_stripe`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    getZohoCustomer: builder.mutation<any, getZohoCustomerProps>({
      query: (params) => ({
        url: `/api/v1/wallet/get_zoho_customer/${params.euid}`,
        method: "GET",
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    getSubscriptionLink: builder.mutation<any, getSubscriptionLinkProps>({
      query: (body) => ({
        url: `/api/v1/wallet/create_subscription`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    getUserSubscriptionDetails: builder.query<any, getUserSubsDetailsProps>({
      query: (body) => ({
        url: `/api/v1/wallet/get_user_subscription_details`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    freeTrailActivation: builder.mutation<any, freeTrailActivationProps>({
      query: (body) => ({
        url: `/api/v1/wallet/free_trail_activation`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    subscriptionRenew: builder.mutation<any, subscriptionRenewProps>({
      query: (body) => ({
        url: `/api/v1/wallet/subscription_renew`,
        method: "POST",
        body,
      }),
      // eslint-disable-next-line
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useGetSubscriptionDetailsQuery,
  useGetZohoCustomerMutation,
  useGetUserSubscriptionDetailsQuery,
  useGetSubscriptionLinkMutation,
  useFreeTrailActivationMutation,
  useActiveUserSubscriptionMutation,
  useUpdateSubscriptionMutation,
  useSubscriptionRenewMutation,
  useCreateSubscriptionStripeMutation,
  useUpdateSubscriptionStripeMutation,
} = subscriptionApi;
