import React, { useEffect, useState } from "react";
import { TextField, InputAdornment, Button, Alert, Modal } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Left,
  LeftContent,
  Logo,
  Right,
  RightDetail,
  Wrapper,
  ResetPasswordWrapper,
  Input,
  InputLabel,
  LinkSentModal,
  LinkSentModalBottom,
} from "./ResetPassword.styles";
import ForgetPassword from "src/components/forgetPassword/ForgetPassword";
import zocketLogo from "../../assets/zocketLogo.png";
import resetLinkSent from "src/assets/resetLinkSent.svg";
import permssionError from "src/assets/permissionError.png";
import resetSuccess from "src/assets/linkSuccessful.png";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import {
  getForgetPasswordCode,
  resetPassword,
  validateResetCode,
} from "src/slices/user/userSlice";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [step, setStep] = useState(3);
  const [errorToast, setErrorToast] = useState("");
  const [linkSentModal, setLinkSentModal] = useState(false);
  const [wrongCodeModal, setWrongCodeModal] = useState(false);
  const [resetSuccessModal, setResetSuccessModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const forgotPasswordCode = useAppSelector(
    (state) => state.user.forgotPassword.code
  );

  // const token = window.location.pathname
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const validateCode = urlParams.get("code");
    console.log(validateCode);
    if (validateCode) {
      dispatch(validateResetCode(validateCode))
        .unwrap()
        .then(() => setStep(2))
        .catch((err) => {
          setStep(1);
          setWrongCodeModal(true);
        });
    } else {
      setStep(1);
    }
  }, []);

  const get_reset_link = () => {
    dispatch(getForgetPasswordCode(email))
      .unwrap()
      .then(() => setLinkSentModal(true))
      .catch(() => setErrorToast("Email doesn't exists"));
  };

  useEffect(() => {
    if (errorToast) setErrorToast("");
  }, [password, rePassword]);

  const do_reset_password = () => {
    if (password !== rePassword) {
      setErrorToast("Confirm password should be same as password.");
      return;
    }
    const urlParams = new URLSearchParams(window.location.search);
    const validateCode = urlParams.get("code");

    dispatch(
      resetPassword({
        code: validateCode!,
        password: password,
      })
    )
      .unwrap()
      .then(() => {
        setResetSuccessModal(true);
      });
  };

  return (
    <Wrapper>
      <Left>
        <LeftContent>
          <Logo>
            {/* <img src={zocketLogo} />
            <h2>Zocket</h2> */}
            <img
              src="https://drbgg29kvmub6.cloudfront.net/assets/animationFiles/companylogo.svg"
              alt=""
            />
          </Logo>
          <h2>
            Welcome to our&nbsp;<h3> AI </h3>&nbsp;Dashboard
          </h2>
          <p>
            See all the analytics and grow your data remotely, from anywhere!
          </p>
        </LeftContent>
      </Left>
      <Right>
        <RightDetail>
          <Logo>
            {/* <img src={zocketLogo} /> */}
            <h2>Zocket</h2>
          </Logo>
          <h2>
            Welcome to our&nbsp;<h3> AI </h3>&nbsp;Dashboard
          </h2>
          <p>
            See all the analytics and grow your data remotely, from anywhere!
          </p>
        </RightDetail>
        <ResetPasswordWrapper>
          {step === 1 && (
            <>
              <h3>Reset your password</h3>
              <p>Provide your email for reset link.</p>
              {errorToast && (
                <Alert sx={{ marginBottom: "1rem" }} severity="warning">
                  {errorToast}
                </Alert>
              )}
              <Input>
                <InputLabel>Email</InputLabel>
                <TextField
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  size="small"
                />
              </Input>

              <Button
                onClick={() => get_reset_link()}
                variant="contained"
                fullWidth
              >
                Continue
              </Button>
            </>
          )}

          {step === 2 && (
            <>
              <h3>Reset your password</h3>
              <p>
                Set the new password for your account, so you can login and
                access all the features
              </p>
              {errorToast && (
                <Alert sx={{ marginBottom: "1rem" }} severity="warning">
                  {errorToast}
                </Alert>
              )}
              <Input>
                <InputLabel>Password</InputLabel>
                <TextField
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size="small"
                  type="password"
                />
              </Input>
              <Input>
                <InputLabel>Confirm Password</InputLabel>
                <TextField
                  value={rePassword}
                  onChange={(e) => setRePassword(e.target.value)}
                  type={passwordVisible ? "text" : "password"}
                  size="small"
                  fullWidth
                  // value={password}
                  // onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={{ cursor: "pointer" }}
                        onClick={() => setPasswordVisible(!passwordVisible)}
                      >
                        {passwordVisible ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Input>
              <Button
                onClick={() => do_reset_password()}
                variant="contained"
                fullWidth
              >
                Reset password
              </Button>
            </>
          )}
        </ResetPasswordWrapper>
      </Right>
      <Modal open={linkSentModal} onClose={() => setLinkSentModal(false)}>
        <LinkSentModal>
          <img src={resetLinkSent} />
          <LinkSentModalBottom>
            <h3>Reset link has been sent</h3>
            <p>Check your mail for the reset link and change the password</p>
          </LinkSentModalBottom>
        </LinkSentModal>
      </Modal>
      <Modal open={wrongCodeModal} onClose={() => setWrongCodeModal(false)}>
        <LinkSentModal>
          <img src={permssionError} />
          <LinkSentModalBottom>
            <h3>Not a valid URL</h3>
            <p>The reset link is not valid please try again.</p>
          </LinkSentModalBottom>
        </LinkSentModal>
      </Modal>
      <Modal open={resetSuccessModal} onClose={() => navigate("/")}>
        <LinkSentModal>
          <img src={resetSuccess} />
          <LinkSentModalBottom>
            <h3>Password reset successful</h3>
            <p>
              You have successfully reset you password. Login with your new
              password.
            </p>
          </LinkSentModalBottom>
        </LinkSentModal>
      </Modal>
    </Wrapper>
  );
};

export default ResetPassword;
