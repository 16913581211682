import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { createStyles } from "../../config/theme";
import { SearchNormal1 as SearchIcon } from "iconsax-react";
import { forwardRef } from "react";

type SearchInputProps = TextFieldProps & { maxWidth?: number };

const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  ({ sx, maxWidth, InputProps, ...props }, ref) => {
    return (
      <TextField
        ref={ref}
        sx={{ ...sx, maxWidth: maxWidth ?? "unset" }}
        fullWidth
        InputProps={{ ...inputProps, ...InputProps }}
        {...props}
      />
    );
  }
);

const styles = createStyles({
  icon: {
    pr: 0.5,
  },
});

const inputProps = {
  startAdornment: (
    <InputAdornment position="start" sx={styles.icon}>
      <SearchIcon size={20} color="#FFF" />
    </InputAdornment>
  ),
};

export default SearchInput;
