import UNIVERSAL from "../config/config";
import { ResponseSuccess } from "../interfaces/api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

type FetchEnterpriseUserProps = {
  ad_account: string;
  email: string;
  euid: 0;
  name: string;
  process_fee: string;
  process_percent: string;
  request_amount: string;
  tax: string;
  total_amount: string;
};

type GenerateAdspendPaymentLinkProps = {
  ad_account: string;
  email: string;
  euid: 0;
  name: string;
  payment_mode: string;
  process_fee: string;
  process_percent: string;
  request_amount: string;
  tax: string;
  total_amount: string;
};

export type EuidExistUpdateEnterpriseUser = {
  euid: string;
  requested_amount: number;
  platform_use: number;
  platform_use_amount: number;
  gst_amount: number;
  total_amount: number;
  requested_by: string;
  requested_date: string;
  tax: number;
};

export type getAdsSpendBalancePayload = {
  ad_account: string;
  euid: number;
};

export type getProcessingFeePayload = {
  ad_account: string;
  email: string;
  euid: number;
  exc_tax: number;
};

export type createAdspendStripeLinkPayload = {
  ad_account: string;
  email: string;
  euid: number;
  name: string;
  process_fee: string;
  process_percent: string;
  request_amount: string;
  tax: string;
  total_amount: string;
  payment_mode: string;
};

export const walletBalanceApi = createApi({
  reducerPath: "walletBalanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: UNIVERSAL.BASEURL,
  }),
  tagTypes: ["walletBalance"],
  endpoints: (builder) => ({
    generateBankDetails: builder.mutation<any, FetchEnterpriseUserProps>({
      query: (body) => ({
        url: "/api/v1/wallet/generate_bank_details",
        method: "POST",
        body: {
          ad_account: body.ad_account,
          email: body.email,
          euid: body.euid,
          name: body.name,
          process_fee: body.process_fee,
          process_percent: body.process_percent,
          request_amount: body.request_amount,
          tax: body.tax,
          total_amount: body.total_amount,
        },
      }),
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    generateAdspendPaymentLink: builder.mutation<
      any,
      GenerateAdspendPaymentLinkProps
    >({
      query: (body) => ({
        url: "/api/v1/wallet/generate_adspend_payment_link",
        method: "POST",
        body: {
          ad_account: body.ad_account,
          email: body.email,
          euid: body.euid,
          name: body.name,
          payment_mode: body.payment_mode,
          process_fee: body.process_fee,
          process_percent: body.process_percent,
          request_amount: body.request_amount,
          tax: body.tax,
          total_amount: body.total_amount,
        },
      }),
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    euidExistUpdateEnterpriseUser: builder.mutation<
      any,
      EuidExistUpdateEnterpriseUser
    >({
      query: (body) => ({
        url: `${UNIVERSAL.BASEURL2}/update_enterprise_user_details`,
        method: "POST",
        body,
      }),
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    getAdsSpendBalance: builder.query<any, getAdsSpendBalancePayload>({
      query: (body) => ({
        url: `/api/v1/wallet/get_adspend_balance`,
        method: "POST",
        body,
      }),
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    getProcessingFee: builder.query<any, getProcessingFeePayload>({
      query: (body) => ({
        url: `/api/v1/wallet/get_processing_fee`,
        method: "POST",
        body,
      }),
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
    createAdspendStripeLink: builder.mutation<
      any,
      createAdspendStripeLinkPayload
    >({
      query: (body) => ({
        url: `/api/v1/wallet/create_adspend_stripe_link`,
        method: "POST",
        body,
      }),
      transformResponse: (response: ResponseSuccess<any>) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useGenerateBankDetailsMutation,
  useEuidExistUpdateEnterpriseUserMutation,
  useGetAdsSpendBalanceQuery,
  useGetProcessingFeeQuery,
  useCreateAdspendStripeLinkMutation,
  useGenerateAdspendPaymentLinkMutation,
} = walletBalanceApi;
