import React, { RefObject, useEffect, useRef, useState } from 'react'
import { Modal, IconButton, Button } from "@mui/material"
import { AdFatigueTitle, Bottom, ChartWrapper, CloseBtn, Dot, DotFlex, Track, TrackWrapper, Wrapper } from './AdFatigueModal.styles'
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs"
import Chart from "react-apexcharts";
import { MdOutlineAutoFixHigh } from "react-icons/md"
import { IoCloseCircleOutline } from 'react-icons/io5';
import { useAppSelector } from 'src/hooks/useAppSelector';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { getAdDippings } from 'src/slices/rules/rules';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


const AdFatigueModal = ({ fatigueModal, setFatigueModal }: any) => {
    const TrackRef = useRef<HTMLDivElement>(null)
    const [chartIndex, setChartIndex] = useState(0);
    const [dates, setDates] = useState([
        "2023-05-21",
        "2023-05-20",
        "2023-05-19",
        "2023-05-18",
        "2023-05-17",
        "2023-05-16"]);

    const [cpmSeries, setCpmSeries] = useState([{ name: "CPM", data: [] }])
    const [cpcSeries, setCpcSeries] = useState([{ name: "CTR", data: [] }])
    const [clickSeries, setClickSeries] = useState([{ name: "Clicks", data: [] }])
    const [spendSeries, setSpendSeries] = useState([{ name: "Spend", data: [] }])
    const [impressionSeries, setImpressionSeries] = useState([{ name: "Impressions", data: [] }])
    const [reachSeries, setReachSeries] = useState([{ name: "Reach", data: [] }])

    const user = useAppSelector(state => state.user.user);
    const { adDipping, loading } = useAppSelector(state => state.rules)

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    useEffect(() => {
        if (user?.data?.fb_ad_account) {
            dispatch(getAdDippings({ ad_account_id: user?.data?.fb_ad_account }))
            // dispatch(getAdDippings({ ad_account_id: "491943363106074" }))
        }
    }, [user?.data?.fb_ad_account])

    useEffect(() => {
        console.log(adDipping?.date_start)
        if (adDipping?.length) {
            setDates([...adDipping.map((item: any) => item.date_start)])
        }
        if (adDipping?.length) {
            const tmp = adDipping?.map((item: any) => item.cpm === null ? 0 : Number(parseFloat(item.cpm).toFixed(1)))

            setCpmSeries([{
                name: "CPM",
                data: tmp
            }])
        }

        if (adDipping?.length) {
            const tmp = adDipping?.map((item: any) => item.cpc === null ? 0 : Number(parseFloat(item.cpc).toFixed(1)))

            setCpcSeries([{
                name: "CPC",
                data: tmp
            }])
        }

        if (adDipping?.length) {
            const tmp = adDipping?.map((item: any) => item.clicks === null ? 0 : Number(parseFloat(item.clicks).toFixed(1)))

            setClickSeries([{
                name: "Clicks",
                data: tmp
            }])
        }

        if (adDipping?.length) {
            const tmp = adDipping?.map((item: any) => item.spend === null ? 0 : Number(parseFloat(item.spend).toFixed(1)))

            setSpendSeries([{
                name: "Spend",
                data: tmp
            }])
        }

        if (adDipping?.length) {
            const tmp = adDipping?.map((item: any) => item.impressions === null ? 0 : Number(parseFloat(item.impressions).toFixed(1)))

            setImpressionSeries([{
                name: "Impressions",
                data: tmp
            }])
        }

        if (adDipping?.length) {
            const tmp = adDipping?.map((item: any) => item.reach === null ? 0 : Number(parseFloat(item.reach).toFixed(1)))

            setReachSeries([{
                name: "Reach",
                data: tmp
            }])
        }

    }, [adDipping])

    useEffect(() => {
        if (fatigueModal) {
            setTimeout(() => {
                const track = document.getElementById("chartTrack")
                console.log(track, "ladasd")
                track?.addEventListener("scroll", (e: any) => {
                    console.log(e.target.scrollLeft)

                    if (e?.target?.scrollLeft === 0) setChartIndex(0)
                    else if (e?.target?.scrollLeft === 461) setChartIndex(1)
                    else if (e?.target?.scrollLeft === 922) setChartIndex(2)
                    else if (e?.target?.scrollLeft === 1382) setChartIndex(3)
                    else if (e?.target?.scrollLeft === 1843) setChartIndex(4)
                    else if (e?.target?.scrollLeft === 2304) setChartIndex(5)
                })
            }, 100)

        }
    }, [TrackRef, fatigueModal])

    useEffect(() => {
        console.log(chartIndex, "CHART IINDEX")
    }, [chartIndex])



    const series = [{
        name: 'Net Profit',
        data: [0,
            54.5,
            45.9,
            48.0,
            40.6,
            49.8]
    }]

    const options = {
        chart: {
            type: 'area',
            fontFamily: 'Montserrat, sans-serif',
            toolbar: {
                show: false,
            },
        },
        grid: { show: true, strokeDashArray: 4, borderColor: "#FFFFFF1A" },
        markers: {
            size: 4,
            colors: undefined,
            strokeColors: '#fff',
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: 'circle',
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: 4,
                sizeOffset: 3,
            },
        },
        colors: ['#150DD1', '#ef8f72'],
        dataLabels: {
            enabled: false,
            style: {
                opacity: 0,
                fontSize: 0,
                color: '#fff',
            },
            background: {
                borderRadius: 100,
            },
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            colors: undefined,
            width: 2,
            dashArray: 0,
        },
        xaxis: {
            type: 'datetime',
            categories: dates.sort((a, b) => +new Date(b) - +new Date(a)),
            labels: {
                style: {
                    colors: '#FFFFFF99',
                },
            },
        },
        yaxis: [
            {
                title: {
                    enabled: false,
                    show: false,
                    text: '',
                    style: {
                        color: '#FFF',
                    },
                },
                labels: {
                    show: false,
                    formatter: (val: any) => parseFloat(val).toFixed(1),
                    style: {
                        colors: '#FFFFFF99',
                    },
                },
            },
        ],
        legend: {
            labels: {
                colors: '#fff',
            },
        },
        tooltip: {
            x: {
                format: 'dd/MM/yy',
            },
            y: {
                formatter: (val: any) => parseFloat(val).toFixed(1),
            },
        },
    }

    const cpmToolTip = {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            console.log(series, seriesIndex, dataPointIndex)
            return '<div class="chart-tooltip">' + '<p>' + moment(dates[dataPointIndex]).format("LL") + '</p>' +
                '<span>' + 'CPM: ' + series[seriesIndex][dataPointIndex] + '</span>' +
                '</div>'
        }
    }

    const cpcToolTip = {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            return '<div class="chart-tooltip">' + '<p>' + moment(dates[dataPointIndex]).format("LL") + '</p>' +
                '<span>' + 'CPC: ' + series[seriesIndex][dataPointIndex] + '</span>' +
                '</div>'
        }
    }

    const reachToolTip = {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            return '<div class="chart-tooltip">' + '<p>' + moment(dates[dataPointIndex]).format("LL") + '</p>' +
                '<span>' + 'Reach: ' + series[seriesIndex][dataPointIndex] + '</span>' +
                '</div>'
        }
    }

    const clicksToolTip = {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            return '<div class="chart-tooltip">' + '<p>' + moment(dates[dataPointIndex]).format("LL") + '</p>' +
                '<span>' + 'Clicks: ' + series[seriesIndex][dataPointIndex] + '</span>' +
                '</div>'
        }
    }

    const spendsToolTip = {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            return '<div class="chart-tooltip">' + '<p>' + moment(dates[dataPointIndex]).format("LL") + '</p>' +
                '<span>' + 'Spends: ' + series[seriesIndex][dataPointIndex] + '</span>' +
                '</div>'
        }
    }

    const impressionsToolTip = {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }: any) {
            return '<div class="chart-tooltip">' + '<p>' + moment(dates[dataPointIndex]).format("LL") + '</p>' +
                '<span>' + 'Impressrion: ' + series[seriesIndex][dataPointIndex] + '</span>' +
                '</div>'
        }
    }

    useEffect(() => {
        console.log(cpmSeries, series)
    }, [cpmSeries])

    return (
        <Modal open={fatigueModal} onClose={() => setFatigueModal(false)}>
            <Wrapper>
                <CloseBtn>
                    <IconButton onClick={() => setFatigueModal(false)}>
                        <IoCloseCircleOutline />
                    </IconButton>
                </CloseBtn>
                <AdFatigueTitle>
                    <IconButton>
                        <BsArrowLeftCircle style={{ fontSize: "1.4rem" }} />
                    </IconButton>
                    <p>ad sets dipping</p>
                    <IconButton>
                        <BsArrowRightCircle style={{ fontSize: "1.4rem" }} />
                    </IconButton>
                </AdFatigueTitle>
                <TrackWrapper>
                    <Track id='chartTrack' >
                        <ChartWrapper>
                            {cpmSeries[0]?.data &&
                                <Chart
                                    options={{ ...options, tooltip: cpmToolTip } as any}
                                    series={cpmSeries}
                                    type="line"

                                />}
                        </ChartWrapper>
                        <ChartWrapper>
                            {cpcSeries[0]?.data &&
                                <Chart
                                    options={{ ...options, tooltip: cpcToolTip } as any}
                                    series={cpcSeries}
                                    type="line"

                                />}
                        </ChartWrapper>
                        <ChartWrapper>
                            {clickSeries[0]?.data &&
                                <Chart
                                    options={{ ...options, tooltip: clicksToolTip } as any}
                                    series={clickSeries}
                                    type="line"

                                />}
                        </ChartWrapper>
                        <ChartWrapper>
                            {spendSeries[0]?.data &&
                                <Chart
                                    options={{ ...options, tooltip: spendsToolTip } as any}
                                    series={spendSeries}
                                    type="line"

                                />}
                        </ChartWrapper>
                        <ChartWrapper>
                            {impressionSeries[0]?.data &&
                                <Chart
                                    options={{ ...options, tooltip: impressionsToolTip } as any}
                                    series={impressionSeries}
                                    type="line"

                                />}
                        </ChartWrapper>
                        <ChartWrapper>
                            {reachSeries[0]?.data &&
                                <Chart
                                    options={{ ...options, tooltip: reachToolTip } as any}
                                    series={reachSeries}
                                    type="line"

                                />}
                        </ChartWrapper>
                    </Track>
                    <DotFlex>
                        <Dot active={chartIndex === 0} />
                        <Dot active={chartIndex === 1} />
                        <Dot active={chartIndex === 2} />
                        <Dot active={chartIndex === 3} />
                        <Dot active={chartIndex === 4} />
                        <Dot active={chartIndex === 5} />
                    </DotFlex>
                </TrackWrapper>
                <Bottom>
                    <h4>Your campaigns are dipping, So <span>zocket</span> comes in action to set rules to get an alert on time  in case of <strong>ad fatigue</strong> </h4>
                    <p>Get an alert on time and act accordingly to stop your ad from fatigue</p>
                    <Button onClick={() => navigate("/set_rules")} startIcon={<MdOutlineAutoFixHigh />} variant="contained" >Try Automation</Button>
                </Bottom>
            </Wrapper>
        </Modal>
    )
}

export default AdFatigueModal